import { getTranslation } from '../translations/translations';
import { mealPlans } from 'utils/constants';

export const getVariantDescription = (variant, language, t) => {
	let string = '';
	string = variant.adults + ' ' + getTranslation('variants', variant.adults > 1 ? 'adults' : 'adultsSingle', language) + (variant.children > 0 ? ' | ' + variant.children + ' ' + getTranslation('variants', variant.children > 1 ? 'children' : 'childrenSingle', language) : '');
	if (variant.mealPlan) {
		string += ' | ' + mealPlans(t, language).filter(mealPlan => mealPlan.value === variant.mealPlan)[0].label;
	}
	return string;
};

export const getVariantTotalPrice = (prices) => {
	let totalPrice = 0;
	if (prices.length > 0) {
		prices.forEach(price => {
			let tempPrice = (parseFloat(price.singlePrice) * price.quantity);
			if (price.discount > 0) {
				tempPrice = tempPrice * (1 - (price.discount / 100));
			}
			totalPrice += tempPrice;
		});
	}
	return totalPrice;
};