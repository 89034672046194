import Title from 'antd/es/typography/Title';
import LanguageInput from 'components/forms/language-input';
import LanguageSwitcher from 'components/core/language-switcher';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import TextEditor from 'components/forms/text-editor';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { apiDomain, isValidUploadFormat, RoutePaths, findTranslationOrFallback, languages } from 'utils/constants';
import { Button, Col, Divider, Form, Input, Row, notification, Affix, Upload, Space } from 'antd';
import './index.scss';

function ContentUspView () {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { uspId } = useParams();
	const [data, setData] = useState();
	const [language, setLanguage] = useState((location.state && location.state.lang) ? location.state.lang : languages.find(lang => lang.default).key);
	const [api, contextHolder] = notification.useNotification();
	const { accessToken, user } = useAuth();
	const [form] = Form.useForm();
	const [image, setImage] = useState([]);
	const [removeList, setRemoveList] = useState([]);
	
	const fetchData = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'contentUsp/' + user.hotelId + '/' + uspId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setData(response);
				
				if (response.imageName) {
					setImage([{
						index: 1,
						name: 'Bild' + 1,
						fileName: response.imageName,
						thumbUrl: apiDomain() + response.imagePath,
						url: apiDomain() + response.imagePath,
						existingImage: true,
					}]);
				}
				
				languages.forEach(lang => {
					form.setFieldValue(('headline' + lang.key), findTranslationOrFallback(response.contentUspTranslations, 'headline', lang.key));
				});
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken, user.hotelId, uspId, form]);
	
	const onSubmit = async (values) => {
		try {
			const data = new FormData();
			image.forEach((file, i) => {
				if (!file.existingImage) {
					data.append('image', file, file.name);
				}
			});
			
			let fileToDeleteArray = [];
			removeList.forEach((file, i) => {
				fileToDeleteArray.push(file.fileName);
			});
			
			if (fileToDeleteArray.length > 0) {
				data.append('filesToDelete', fileToDeleteArray.join('|'));
			}
			
			data.append('hotelId', user.hotelId);
			
			Object.keys(values).forEach(function(key,index) {
				if (values[key] !== 'undefined') {
					data.append(key, values[key]);
				} else {
					data.append(key, '');
				}
			});
			
			await axios.put(apiDomain() + 'contentUsp/' + uspId, data, {
				headers: {
					'x-access-token': accessToken,
				},
			}).then(done => {
				if (uspId > 0) {
					api['success']({
						message: t('general.saveSuccess'),
						description: t('general.saveSuccessText'),
						placement: 'bottomRight',
						duration: 3,
					});
					setImage([]);
					setRemoveList([]);
					fetchData();
				} else {
					navigate(RoutePaths.content.index);
				}
			});
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	const beforeUploadImage = () => {
		return false;
	};
	
	const onChangeHandleImage = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t, true)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		setImage(tempFiles);
	};
	
	const onRemoveImage = (file) => {
		const index = image.indexOf(file);
		const newFileList = image.slice();
		newFileList.splice(index, 1);
		setImage(newFileList);
		setRemoveList([...removeList, file]);
	};
	
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				{t('content.lp.general.upload')}
			</div>
		</div>
	);
	
	return (
		<div className="settings-content-usp">
			{contextHolder}
			{ data &&
				<Form
					layout={'vertical'}
					onFinish={onSubmit}
					form={form}
				>
					<div className="headline-container">
						<Title level={3}>
							{t('menu.contentUsp')}
						</Title>
						<LanguageSwitcher
							currentLanguage={language}
							onSwitch={(lang) => setLanguage(lang)}
						/>
					</div>
					<Divider/>
					<br />
					<Input.Group>
						<Row gutter={8}>
							<Col span={16}>
								<LanguageInput
									label={t('content.lp.general.headline')}
									name="headline"
									currentLanguage={language}
								/>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={16}>
								<div className="field">
									<div className="label">{t('content.lp.general.text')}</div>
									<TextEditor
										formItemName={'text'}
										form={form}
										language={language}
										translations={data.contentUspTranslations}
									/>
								</div>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={16}>
								<div className="field">
									<div className="label">{t('content.lp.general.image')}</div>
									<Upload
										listType="picture-card"
										fileList={image}
										beforeUpload={beforeUploadImage}
										onRemove={onRemoveImage}
										onChange={onChangeHandleImage}
									>
										{image.length >= 1 ? null : uploadButton}
									</Upload>
								</div>
							</Col>
						</Row>
					</Input.Group>
					<Divider />
					<br />
					<Affix offsetBottom={0} className="sticky-save-container">
						<div className="content">
							<Form.Item>
								<Space wrap size="middle">
									<Button onClick={() => navigate(RoutePaths.content.index, { state: { lang: language, activeTab: '2' }})}>{t('clientData.back')}</Button>
									<Button type="primary" htmlType="submit">{t('clientData.save')}</Button>
								</Space>
							</Form.Item>
							<LanguageSwitcher
								currentLanguage={language}
								onSwitch={(lang) => setLanguage(lang)}
							/>
						</div>
					</Affix>
				</Form>
			}
		</div>
	);
}

export default ContentUspView;