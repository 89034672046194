import { Col, Divider, Row, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { isValidUploadFormat } from 'utils/constants';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Title from 'antd/es/typography/Title';

function HeaderT1 ({ images, removeList, onAddImages, onRemoveImages, onChangeImages }) {
	const { t } = useTranslation();
	
	const beforeUpload = () => {
		return false;
	};
	
	const onChangeHandle = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		onAddImages(tempFiles);
	};
	
	const onRemove = (file) => {
		const index = images.indexOf(file);
		const newFileList = images.slice();
		newFileList.splice(index, 1);
		onAddImages(newFileList);
		onRemoveImages([...removeList, file]);
	};
	
	const sensor = useSensor(PointerSensor, {
		activationConstraint: {
			distance: 10,
		},
	});
	
	const onDragEnd = ({ active, over }) => {
		if (active.id !== over?.id) {
			onChangeImages((prev) => {
				const activeIndex = prev.findIndex((i) => i.uid === active.id);
				const overIndex = prev.findIndex((i) => i.uid === over?.id);
				return arrayMove(prev, activeIndex, overIndex);
			});
		}
	};
	
	const DraggableUploadListItem = ({ originNode, file }) => {
		const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
			id: file.uid,
		});
		
		const style = {
			transform: CSS.Transform.toString(transform),
			transition,
			cursor: 'move',
			height: '100%',
		};
		
		return (
			<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
				{originNode}
			</div>
		);
	};
	
	return (
		<div className="header-t1 block">
			<Divider orientation="left" orientationMargin="0">
				<Title level={4}>
					{t('content.lp.headert1.headert1')}
				</Title>
			</Divider>
			<div className="fields">
				<Row gutter={24}>
					<Col span={12}>
						<div className="label">{t('content.lp.general.images')}</div>
						<DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
							<SortableContext items={images?.map((i) => i.uid)}>
								<Upload
									listType="picture-card"
									fileList={images}
									beforeUpload={beforeUpload}
									onRemove={onRemove}
									onChange={onChangeHandle}
									itemRender={(originNode, file) => (
										<DraggableUploadListItem originNode={originNode} file={file} />
									)}
									multiple
								>
									<div>
										<PlusOutlined />
										<div
											style={{
												marginTop: 8,
											}}
										>
											{t('content.lp.general.upload')}
										</div>
									</div>
								</Upload>
							</SortableContext>
						</DndContext>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default HeaderT1;