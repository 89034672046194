import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Divider, Form, Input, Row, notification, Affix } from 'antd';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Title from 'antd/es/typography/Title';
import { apiDomain } from 'utils/constants';
import axios from 'axios';

function SettingsImport () {
	const { accessToken, user } = useAuth();
	const [loading, setLoading] = useState(true);
	const [settingsImport, setSettingsImport] = useState();
	const { t } = useTranslation();
	const [api, contextHolder] = notification.useNotification();
	
	const fetchData = useCallback(async() => {
		setLoading(true);
		try {
			const { data: response } = await axios.get(apiDomain() + 'settings/import', {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setSettingsImport(response);
			}
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	}, [accessToken]);
	
	const onSubmit = async (values) => {
		try {
			await axios.put(apiDomain() + 'settings/import', {
				hotelId: user.hotelId,
				url: values.url,
				listLastModified: values.listLastModified,
				itemTitle: values.itemTitle,
				itemUrl: values.itemUrl,
				itemBookingLink: values.itemBookingLink,
				itemAsaCode: values.itemAsaCode,
				itemImages: values.itemImages,
				itemSubTitle: values.itemSubTitle,
				itemListText: values.itemListText,
				itemPrice: values.itemPrice,
				itemArea: values.itemArea,
				itemDescription: values.itemDescription,
			}, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			api['success']({
				message: t('general.saveSuccess'),
				description: t('general.saveSuccessText'),
				placement: 'bottomRight',
				duration: 3,
			});
			fetchData();
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	return (
		<div className="settings-import">
			{contextHolder}
			{ (!loading && settingsImport) &&
				<>
					<Form
						layout={'vertical'}
						onFinish={onSubmit}
						initialValues={{
							url: settingsImport.url,
							listLastModified: settingsImport.listLastModified,
							itemTitle: settingsImport.itemTitle,
							itemUrl: settingsImport.itemUrl,
							itemBookingLink: settingsImport.itemBookingLink,
							itemAsaCode: settingsImport.itemAsaCode,
							itemImages: settingsImport.itemImages,
							itemSubTitle: settingsImport.itemSubTitle,
							itemListText: settingsImport.itemListText,
							itemPrice: settingsImport.itemPrice,
							itemArea: settingsImport.itemArea,
							itemDescription: settingsImport.itemDescription,
						}}
					>
						<Title level={3}>
							{t('menu.import')}
						</Title>
						<Divider/>
						<Input.Group>
							<Row gutter={8}>
								<Col span={16}>
									<Form.Item
										label={t('import.url')}
										name="url"
									>
										<Input type="url" />
									</Form.Item>
								</Col>
							</Row>
							<Divider orientation="left" orientationMargin="0">
								{t('import.serviceKeys')}
							</Divider>
							<Row gutter={8}>
								<Col span={8}>
									<Form.Item
										label={t('import.listLastModified')}
										name="listLastModified"
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={8}>
								<Col span={8}>
									<Form.Item
										label={t('import.itemTitle')}
										name="itemTitle"
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										label={t('import.itemUrl')}
										name="itemUrl"
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={8}>
								<Col span={8}>
									<Form.Item
										label={t('import.itemBookingLink')}
										name="itemBookingLink"
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										label={t('import.itemAsaCode')}
										name="itemAsaCode"
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={8}>
								<Col span={8}>
									<Form.Item
										label={t('import.itemImages')}
										name="itemImages"
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										label={t('import.itemSubTitle')}
										name="itemSubTitle"
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={8}>
								<Col span={8}>
									<Form.Item
										label={t('import.itemListText')}
										name="itemListText"
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										label={t('import.itemPrice')}
										name="itemPrice"
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={8}>
								<Col span={8}>
									<Form.Item
										label={t('import.itemArea')}
										name="itemArea"
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										label={t('import.itemDescription')}
										name="itemDescription"
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
						</Input.Group>
						<Affix offsetBottom={0} className="sticky-save-container">
							<div className="content">
								<Form.Item>
									<Button type="primary" htmlType="submit">
										{t('import.submit')}
									</Button>
								</Form.Item>
							</div>
						</Affix>
					</Form>
				</>
			}
		</div>
	);
};

export default SettingsImport;