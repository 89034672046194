import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card, Col, Divider, Image, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { apiDomain, findTranslationOrFallback, RoutePaths, languages } from 'utils/constants';
import { useAuth } from 'hooks/useAuth';
import RoomCreate from './create';
import fallBackImage from 'images/fallback.jpg';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LanguageSwitcher from 'components/core/language-switcher';
import './index.scss';

function RoomList() {
	const { accessToken, user } = useAuth();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [importUrl, setImportUrl] = useState(null);
	const { Meta } = Card;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [language, setLanguage] = useState(languages.find(lang => lang.default).key);
	
	const onClose = () => {
		fetchData();
		setOpen(false);
	};
	
	const fetchData = useCallback(async() => {
		setLoading(true);
		try {
			const { data: response } = await axios.get(apiDomain() + 'rooms', {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setData(response);
			}
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	}, [accessToken]);
	
	const fetchImportUrl = useCallback(async() => {
		setLoading(true);
		try {
			const { data: response } = await axios.get(apiDomain() + 'clients/' + user.hotelId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setImportUrl(response.roomImport);
			}
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	}, [accessToken,user.hotelId]);
	
	useEffect(() => {
		fetchData();
		fetchImportUrl();
	}, [fetchData,fetchImportUrl]);
	
	return (
		<div className="room-list">
			<div className="headline-container">
				<Title level={3}>{t('rooms.room')}</Title>
				{ !importUrl &&
					<div className="right-content">
						<Button type="primary" onClick={() => setOpen(true)} icon={<PlusOutlined />}>
							{t('rooms.add')}
						</Button>
					</div>
				}
			</div>
			<Divider/>
			<LanguageSwitcher
				currentLanguage={language}
				onSwitch={(lang) => setLanguage(lang)}
			/>
			{ (data.length > 0 && !loading) &&
				<Row gutter={24}>
					{ data.map((room) => (
						<Col span={6} key={room.id}>
							<Card
								onClick={() => navigate(RoutePaths.rooms.index + '/' + room.id)}
								hoverable
								cover={<Image
									width="100%"
									height="100%"
									src={room.images.length > 0 ? (room.images[0].path ? (apiDomain() + room.images[0].path) : room.images[0].externalPath) : ''}
									preview={false}
									fallback={fallBackImage}
								/>}
							>
								<Meta
									title={findTranslationOrFallback(room.roomTranslations, 'name', language) + (room.size ? (' - ' + room.size + 'm²') : '')}
									description={findTranslationOrFallback(room.roomTranslations, 'description', language).replace(/<[^>]*>?/gm, '').substr(0, 100) + '\u2026'}
								/>
							</Card>
						</Col>
					))}
				</Row>
			}
			<RoomCreate
				open={open}
				onClose={onClose}
			/>
		</div>
	);
}

export default RoomList;