import Button from '../button';

function Link(props) {
	return (
		<div className={ props.outerClass }>
			<Button
				class={ props.class }
				dataId={ props.dataId }
				href={ props.href }
				onClick={ props.onClick }
				text={ props.text }
			/>
		</div>
	);
}

Link.defaultProps = {
	outerClass: 'link-container',
	class: 'button1',
	dataId: '',
	text: '',
	href: null,
	onClick: null,
};

export default Link;
