import { getTranslation } from 'pages/offer/translations/translations';
import { getVariantTotalPrice } from 'pages/offer/helpers/helpers';
import { formatToPrice } from 'utils/formats';
import './index.scss';

function PriceTable ({ prices, lang }) {
	const getDiscount = (discount) => {
		if (discount > 0) {
			return (
				<span className="discount">-{discount}%</span>
			);
		} else {
			return '';
		}
	};
	
	return (
		<div className="price-table">
			<div className="price-table-inner">
				<div className="headlines t2">
					<div className="title">{getTranslation('prices', 'title', lang)}</div>
					<div className="right">
						<div className="quantity">{getTranslation('prices', 'quantity', lang)}</div>
						<div className="singlePrice">{getTranslation('prices', 'singlePrice', lang)}</div>
						<div className="totalPrice">{getTranslation('prices', 'total', lang)}</div>
					</div>
				</div>
				<div className="prices">
					{ prices.map((price, index) => {
						let totalPrice = (parseFloat(price.singlePrice) * price.quantity);
						if (price.discount > 0) {
							totalPrice = totalPrice * (1 - (price.discount / 100));
						}
						return (
							<div className="price" key={index}>
								<div className="title">{price.description}</div>
								<div className="right">
									<div className="quantity df ac jb"><div className="label t2">{getTranslation('prices', 'quantity', lang)}: </div>{price.quantity}</div>
									<div className={'singlePrice df ac jb' + (price.discount > 0 ? ' with-discount' : '')}><div className="label t2">{getTranslation('prices', 'singlePrice', lang)}: </div><div><span>{formatToPrice(price.singlePrice)}</span>{getDiscount(price.discount)}</div></div>
									<div className="totalPrice df ac"><div className="label t2">{getTranslation('prices', 'total', lang)}: </div>{formatToPrice(totalPrice)}</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className="total-price t2">
					<div className="headline">{getTranslation('prices', 'totalPrice', lang)}</div>
					<div className="value">{formatToPrice(getVariantTotalPrice(prices))}</div>
				</div>
			</div>
		</div>
	);
}

export default PriceTable;
