
import { formatToPrice } from 'utils/formats';

function Price (props) {
	return (
		<div className={ props.class }>
			{ formatToPrice(props.text) }
		</div>
	);
}

Price.defaultProps = {
	class: 'price t1',
	text: '',
};

export default Price;
