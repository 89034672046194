import { useCallback } from 'react';
import { useImmerReducer } from 'use-immer';
import { useStore } from 'store/useStore';
import { useApiClient } from 'hooks/useApiClient';
import { useTranslation } from 'react-i18next';
import { languages } from 'utils/constants';

export const useAuth = () => {
	const store = useStore();
	return store.auth;
};

export const AuthStatus = {
	Unknown: 0,
	Authenticating: 1,
	Authenticated: 2,
	Anonymous: 3,
};

const initialState = {
	user: null,
	accessToken: null,
	status: AuthStatus.Unknown,
	loginError: false,
};

const ActionType = {
	RESET: 'reset',
	LOGIN: 'login',
	LOGOUT: 'logout',
	AUTHENTICATE: 'authenticate',
};

function reducer(draft, action) {
	switch (action.type) {
		case ActionType.LOGIN:
			draft.user = action.payload.user;
			if (action.payload.accessToken) {
				draft.accessToken = action.payload.accessToken;
			}
			draft.status = AuthStatus.Authenticated;
			draft.loginError = false;
			break;
		case ActionType.AUTHENTICATE:
			draft.status = AuthStatus.Authenticating;
			break;
		case ActionType.LOGOUT:
			draft.user = null;
			draft.accessToken = null;
			draft.status = AuthStatus.Anonymous;
			break;
		case ActionType.ERROR:
			draft.user = null;
			draft.loginError = true;
			draft.status = AuthStatus.Unknown;
			break;
		case ActionType.RESET:
			return initialState;
		default:
			throw new Error(`Action '${action.type}' unknown!`);
	}
}

export const useAuthState = () => {
	const [state, dispatch] = useImmerReducer(reducer, initialState);
	const apiClient = useApiClient(state);
	const { i18n } = useTranslation('translations');
	
	const login = useCallback(async (email, password) => {
		dispatch({ type: ActionType.AUTHENTICATE });
		
		const data = {
			email,
			password,
		};
		
		try {
			const response = await apiClient.post('/auth/login', data);
			const hotelInfos = await apiClient.get('/clients/' + response.data.hotelId);
			let isAdmin = false;
			if (response.data && response.data.roles && Array.isArray(response.data.roles)) {
				if (response.data.roles.includes('admin')) {
					isAdmin = true;
				}
			}
			i18n.changeLanguage(languages.find(lang => lang.id === response.data.languageId).key.toLowerCase());
			dispatch({ type: ActionType.LOGIN, payload: { accessToken: response.data.accessToken, user: { ...response.data, isAdmin, hotelName: hotelInfos?.data?.name, asaFreeRooms: hotelInfos?.data?.asaFreeRooms, asaPrices: hotelInfos?.data?.asaPrices }}});
			return { ...response.data, isAdmin };
		} catch (error) {
			if (error.response && error.response.status === 401) {
				dispatch({ type: ActionType.ERROR });
				return false;
			} else {
				console.error(error);
			}
		}
	}, [apiClient, dispatch,i18n]);

	const logout = useCallback(async () => {
		if (!state.user) {
			return;
		}
		try {
			const response = await apiClient.post('/auth/logout', {});
			dispatch({ type: ActionType.LOGOUT, payload: { accessToken: response.accessToken }});
		} catch (error) {
			console.error(error);
		}
	}, [apiClient, dispatch, state.user]);
	
	const updateHotelId = useCallback(async (hotelId) => {
		if (!state.user) {
			return;
		}
		try {
			const hotelInfos = await apiClient.get('/clients/' + hotelId);
			dispatch({ type: ActionType.LOGIN, payload: { ...state, user: { ...state.user, hotelId, hotelName: hotelInfos?.data?.name, asaFreeRooms: hotelInfos?.data?.asaFreeRooms, asaPrices: hotelInfos?.data?.asaPrices }}});
		} catch (error) {
			console.error(error);
		}
	}, [dispatch, state, apiClient]);

	const tryAutoLogin = useCallback(async () => {
		if (state.status !== AuthStatus.Unknown && state.status !== AuthStatus.Anonymous) {
			return;
		}
		
		const doLogout = async () => {
			try {
				await logout();
			} catch (error) {
				console.error(error);
			}
			return false;
		};
		
		dispatch({ type: ActionType.AUTHENTICATE });
		
		let accessToken = null;
		try {
			const response = await apiClient.post('/auth/token');
			const hotelInfos = await apiClient.get('/clients/' + response.data.hotelId);
			let isAdmin = false;
			if (response.data && response.data.roles && Array.isArray(response.data.roles)) {
				if (response.data.roles.includes('admin')) {
					isAdmin = true;
				}
			}
			i18n.changeLanguage(languages.find(lang => lang.id === response.data.languageId).key.toLowerCase());
			dispatch({ type: ActionType.LOGIN, payload: { accessToken: response.data.accessToken, user: { ...response.data, isAdmin, hotelName: hotelInfos?.data?.name, asaFreeRooms: hotelInfos?.data?.asaFreeRooms, asaPrices: hotelInfos?.data?.asaPrices }}});
			return true;
		} catch (error) {
			dispatch({ type: ActionType.LOGOUT, payload: { accessToken }});
			if (error.response && error.response.status === 401) {
				dispatch({ type: ActionType.ERROR });
				return false;
			} else {
				console.error(error);
			}
			return await doLogout();
		}
	}, [state.status, dispatch, logout, apiClient, i18n]);

	const isStatusUnknown = useCallback(() => state.status === AuthStatus.Unknown || state.status === AuthStatus.Authenticating, [state.status]);

	return {
		user: state.user,
		accessToken: state.accessToken,
		status: state.status,
		loginError: state.loginError,
		isStatusUnknown,
		login,
		logout,
		tryAutoLogin,
		updateHotelId,
	};
};
