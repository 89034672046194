import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { apiDomain } from 'utils/constants';
import ModuleHeaderT1 from './modules/headert1';
import ModuleIntroT1 from './modules/introt1';
import ModuleVariantsT1 from './modules/variantst1';
import ModuleVariantsT2 from './modules/variantst2';
import ModuleUspT1 from './modules/uspt1';
import ModuleTeaserT1 from './modules/teasert1';
import ModuleTeaserT2 from './modules/teasert2';
import ModuleTextT1 from './modules/textt1';
import ModuleRoomT1 from './modules/roomt1';
import ModuleContactPersonT1 from './modules/contactpersont1';
import ModuleFooterGalleryT1 from './modules/footergalleryt1';
import ModuleFooterT1 from './modules/footert1';
import BookingT1 from './modules/bookingt1';
import OfferStyles from './styles';
import axios from 'axios';
import './index.scss';

function Offer () {
	const { offerUid } = useParams();
	const { accessToken } = useAuth();
	const [offer, setOffer] = useState(null);
	const [content, setContent] = useState(null);
	const [contentUsp, setContentUsp] = useState(null);
	const [selectedVariant, setSelectedVariant] = useState('');
	const [bookingOpened, setBookingOpened] = useState(false);
	const { i18n } = useTranslation('translations');
	const [searchParams, setSearchParams] = useSearchParams();
	
	const fetchData = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'offer/' + offerUid, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setOffer(response);
				
				i18n.changeLanguage(response.language);
				
				const { data: content } = await axios.get(apiDomain() + 'content/' + response.hotelId, {
					headers: {
						'x-access-token': accessToken,
					},
				});
				if (content) {
					setContent(content);
				}
				
				const { data: contentUsp } = await axios.get(apiDomain() + 'contentUsp/' + response.hotelId, {
					headers: {
						'x-access-token': accessToken,
					},
				});
				if (contentUsp) {
					setContentUsp(contentUsp);
				}
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [offerUid, accessToken, i18n]);
	
	const setViewed = useCallback(async() => {
		try {
			await axios.post(apiDomain() + 'offers/view', { offerUid }, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (searchParams.has('status')) {
				searchParams.delete('status');
				setSearchParams(searchParams);
			  }
		} catch (error) {
			console.error(error.message);
		}
	}, [offerUid, accessToken, searchParams, setSearchParams]);
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	useEffect(() => {
		if (bookingOpened) {
			document.querySelector('body').classList.add('hidden');
		} else {
			document.querySelector('body').classList.remove('hidden');
		}
	}, [bookingOpened]);
	
	useEffect(() => {
		if (searchParams.get('status') && searchParams.get('status') === 'view') {
			setViewed();
		}
	}, [searchParams, setViewed]);
	
	return (
		<div className="offer-landingpage bgweb">
			{ content && offer &&
				<>
					<OfferStyles
						hotelId={offer.hotelId}
					/>
					<ModuleHeaderT1
						content={content}
						offer={offer}
					/>
					<ModuleIntroT1
						content={content}
						offer={offer}
					/>
					{ !offer.booked &&
						<>
							<ModuleVariantsT1
								content={content}
								offer={offer}
							/>
							<ModuleVariantsT2
								offer={offer}
								onSelectVariant={(variant) => { setSelectedVariant(variant); setBookingOpened(true); }}
							/>
							<BookingT1
								offer={offer}
								open={bookingOpened}
								onCloseBooking={() => setBookingOpened(false)}
								selectedVariant={selectedVariant}
								content={content}
							/>
							<ModuleUspT1
								contentUsp={contentUsp}
								offer={offer}
							/>
							<ModuleTeaserT1
								content={content}
								offer={offer}
							/>
						</>
					}
					{ offer.booked &&
						<>
							<ModuleRoomT1
								content={content}
								offer={offer}
							/>
							<ModuleTextT1
								content={content}
								offer={offer}
							/>
						</>
					}
					<ModuleTeaserT2
						content={content}
						offer={offer}
					/>
					<ModuleContactPersonT1
						content={content}
						offer={offer}
					/>
					<ModuleFooterGalleryT1
						content={content}
						offer={offer}
					/>
					<ModuleFooterT1
						content={content}
						offer={offer}
					/>
				</>
			}
		</div>
	);
};

export default Offer;