import moment from 'moment';

export const formatToPrice = (value) => {
	const formatter = new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR',
	});
	return formatter.format(value);
};

export const getOptionsWithState = (options, activeOptionArray, t) => {
	return options.map((room) => {
		return {
			...room,
			className: activeOptionArray.indexOf(room.code) < 0 ? 'notAvailable' : 'available',
			label: (room.label + (activeOptionArray.indexOf(room.code) < 0 ? (' (' + t('request.roomNotAvailable') + ')') : '')),
		};
	});
};

export const getFromToDate = (arrival, departure) => {
	let dateDiff = moment(departure, 'YYYY-MM-DD').diff(moment(arrival, 'YYYY-MM-DD'), 'days');
	let days = [moment(arrival, 'YYYY-MM-DD').format('DD/MM/YYYY')];
	for (let i = 0; i < dateDiff; i++) {
		days.push(moment(arrival, 'YYYY-MM-DD').add(i + 1, 'days').format('DD/MM/YYYY'));
	}
	return days;
};

export const getTotalPriceOfRateplan = (rateplan) => {
	let totalPrice = 0;
	if (rateplan.dates) {
		rateplan.dates.forEach(rate => {
			if (!isNaN(rate.price)) {
				totalPrice += parseFloat(rate.price);
			}
		});
	}
	return formatToPrice(totalPrice);
};