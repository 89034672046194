import React, { createContext, useContext } from 'react';
import { useAuthState } from 'hooks/useAuth';

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
	const value = useStoreProviderValue();
	return (
	  <StoreContext.Provider value={value}>
			{children}
	  </StoreContext.Provider>
	);
};

export const useStore = () => {
	const ctx = useContext(StoreContext);
	if (ctx === undefined) {
	  throw new Error('useStore must be used within a StoreProvider');
	}
	return ctx;
};

const useStoreProviderValue = () => {
	const auth = useAuthState();
	return {
		auth,
	};
};
