import PreHeadline from '../../core/preheadline';
import Headline from '../../core/headline';
import Text from '../../core/text';
import './index.scss';

function Intro(props) {
	return (
		<div className={ props.outerClass }>
			<div className={ props.innerClass }>
				<div className={ props.containerClass }>
					<PreHeadline
						class={ props.preHeadlineClass }
						text={ props.preheadline }
					/>
					<Headline
						class={ props.headlineClass }
						text={ props.headline }
					/>
					<Text
						class={ props.textCass }
						text={ props.text }
					/>
				</div>
			</div>
		</div>
	);
}

Intro.defaultProps = {
	outerClass: 'module-intro-t1',
	innerClass: 'centercontent1047',
	containerClass: 'container',
	preHeadlineClass: 'preheadline t2',
	headlineClass: 'headline t1',
	textClass: 'text',
	preheadline: '',
	headline: '',
	text: '',
};

export default Intro;