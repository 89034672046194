export const IconPrevNext = () => {
	return (
		<svg
			width="17.613001"
			height="37.220001"
			viewBox="0 0 17.613002 37.22"
			version="1.1"
		>
			<path
				d="M 1.5000473,35.72 16.113047,18.61 1.5000473,1.5"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
			/>
		</svg>
	);
};

export const IconFullscreen = () => {
	return (
		<svg
			width="31.786"
			height="31.786"
			viewBox="0 0 31.786 31.786"
			version="1.1"
		>
			<defs>
				<clipPath id="clip-path-icon-fullscreen">
					<rect
						width="31.786"
						height="31.786"
						fill="none"
						stroke="currentColor"
						stroke-linecap="round"
						stroke-width="3"
					/>
				</clipPath>
			</defs>
			<g
				transform="translate(0 0)"
				clipPath="url(#clip-path-icon-fullscreen)"
			>
				<path
					d="M55.16,11.481V.5H44.179"
					transform="translate(-23.607 -0.267)"
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				/>
				<path
					d="M11.481.5H.5V11.481"
					transform="translate(-0.267 -0.267)"
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				/>
				<path
					d="M44.179,55.16H55.16V44.179"
					transform="translate(-23.607 -23.607)"
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				/>
				<path
					d="M.5,44.179V55.16H11.481"
					transform="translate(-0.267 -23.607)"
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				/>
			</g>
		</svg>
	);
};