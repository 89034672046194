import AdminRoute from 'components/system/admin-route';
import PrivateRoute from 'components/system/private-route';
import RequestList from 'pages/requests/list';
import RequestDetail from 'pages/requests/detail';
import RequestCreate from 'pages/requests/create';
import ContentView from 'pages/content';
import ContentUspView from 'pages/content/usp';
import SettingsPrivacy from 'pages/settings/privacy';
import SettingsFallbackPrivacy from 'pages/settings/fallback-privacy';
import SettingsClientData from 'pages/settings/client-data';
import SettingsImport from 'pages/settings/import';
import SettingsStyles from 'pages/settings/styles';
import SettingsChangeClient from 'pages/settings/change-client';
import SettingsAGB from 'pages/settings/agb';
import SettingsFallbackAGB from 'pages/settings/fallback-agb';
import RoomList from 'pages/rooms/list';
import RoomDetail from 'pages/rooms/detail';
import BookingsDetail from 'pages/bookings/detail';
import BookingsList from 'pages/bookings/list';
import Login from 'pages/login';
import Offer from 'pages/offer';
import OfferPrivacy from 'pages/offer/privacy.js';
import OfferCredits from 'pages/offer/credits';
import OfferAGBs from 'pages/offer/agbs';
import { RoutePaths } from 'utils/constants';
import React, { useState, useEffect } from 'react';
import { useAuth } from 'hooks/useAuth';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { SettingOutlined, MessageOutlined, HomeOutlined, ExportOutlined, FileTextOutlined, ShoppingOutlined, UserSwitchOutlined, BgColorsOutlined, SolutionOutlined, FontSizeOutlined, CloudUploadOutlined, UserOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout as LayoutView, Menu, Spin, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import 'styles/layout.scss';

function Layout () {
	const { logout, user } = useAuth();
	const auth = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [collapsed, setCollapsed] = useState(false);
	const [isLocalLoading, setLocalLoading] = useState(true);
	const { Header, Content, Sider } = LayoutView;
	const {
		token: { colorBgContainer },
	} = theme.useToken();
	
	const getItem = (label, key, icon, children, adminRequired = false) => {
		if (adminRequired && !user?.isAdmin) {
			return '';
		}
		return {
			key,
			icon,
			children,
			label,
		};
	};
	
	const onLogout = async () => {
		await logout();
		navigate(RoutePaths.login.index);
	};
	
	const items = [{
		label: <Button size="small" onClick={() => onLogout()} type="link">{t('menu.logout')}</Button>,
		key: '1',
		icon: <ExportOutlined />,
	}];
	
	useEffect(() => {
		if (auth.isStatusUnknown()) {
			setLocalLoading(true);
			auth.tryAutoLogin();
		} else {
			setLocalLoading(false);
		}
		return () => {
			setLocalLoading(false);
		};
	}, [auth]);
	
	if (isLocalLoading) {
		return (
			<Spin spinning={true} size="large"></Spin>
		);
	}
	
	if (user?.isAdmin) {
		items.unshift({
			label: <Button size="small" onClick={() => navigate(RoutePaths.settings.changeClient)} type="link">{t('menu.changeClient')}</Button>,
			key: '0',
			icon: <UserSwitchOutlined />,
		});
	}
	
	return (
		<>
			<div className="page-content">
				<Routes>
					<Route
						path={RoutePaths.login.index}
						element={<Login />}
					></Route>
					<Route
						exact
						path={RoutePaths.offer.index}
						element={<Offer />}
					></Route>
					<Route
						exact
						path={RoutePaths.offer.credits}
						element={<OfferCredits />}
					></Route>
					<Route
						exact
						path={RoutePaths.offer.privacy}
						element={<OfferPrivacy />}
					></Route>
					<Route
						exact
						path={RoutePaths.offer.agbs}
						element={<OfferAGBs />}
					></Route>
					<Route
						exact
						path={RoutePaths.settings.changeClient}
						element={
							<AdminRoute>
								<SettingsChangeClient />
							</AdminRoute>
						}
					></Route>
					<Route
						path="*"
						element={
							<PrivateRoute>
								<div className="mobile-alert">
									<div className="inner">
										{t('general.mobileAlert')}
									</div>
								</div>
								<LayoutView style={{ minHeight: '100vh', flexDirection: 'initial' }}>
									<Sider
										style={{
											overflow: 'auto',
											height: '100vh',
											position: 'fixed',
											left: 0,
											top: 0,
											bottom: 0,
										}}
										width="250"
										collapsible
										collapsed={collapsed}
										onCollapse={(value) => setCollapsed(value)}
									>
										<div style={{ height: 32, margin: 16 }} />
										<Menu
											defaultSelectedKeys={[location.pathname.startsWith(RoutePaths.rooms.index) ? RoutePaths.rooms.index : (location.pathname.startsWith(RoutePaths.requests.index) ? RoutePaths.requests.index : (location.pathname.startsWith(RoutePaths.bookings.index) ? RoutePaths.bookings.index : location.pathname))]}
											defaultOpenKeys={(location.pathname === RoutePaths.settings.index || location.pathname === RoutePaths.settings.styles || location.pathname === RoutePaths.settings.clientData || location.pathname === RoutePaths.settings.content || location.pathname === RoutePaths.settings.export || location.pathname === RoutePaths.settings.import) ? ['sub1'] : []}
											theme="dark"
											mode="inline"
											items={[
												getItem(t('menu.requests'), RoutePaths.requests.index, <MessageOutlined />),
												getItem(t('menu.bookings'), RoutePaths.bookings.index, <ShoppingOutlined />),
												getItem(t('menu.rooms'), RoutePaths.rooms.index, <HomeOutlined />),
												getItem(t('menu.content'), RoutePaths.content.index, <FontSizeOutlined />),
												getItem(t('menu.settings'), 'sub1', <SettingOutlined />,[
													getItem(t('menu.privacy'), RoutePaths.settings.privacy, <UnlockOutlined />),
													getItem(t('menu.agb'), RoutePaths.settings.agb, <FileTextOutlined />),
													getItem(t('menu.clientData'), RoutePaths.settings.clientData, <SolutionOutlined />),
													getItem(t('menu.styles'), RoutePaths.settings.styles, <BgColorsOutlined />, '', true),
													getItem(t('menu.import'), RoutePaths.settings.import, <CloudUploadOutlined />, '', true),
													getItem(t('menu.fallbackPrivacy'), RoutePaths.settings.fallbackPrivacy, <LockOutlined />, '', true),
													getItem(t('menu.fallbackAgb'), RoutePaths.settings.fallbackAgb, <FileTextOutlined />, '', true),
												]),
											]}
											onClick={(props) => navigate(props.key)}
										/>
									</Sider>
									<LayoutView
										className="site-layout"
									>
										<Header style={{ background: colorBgContainer }}>
											<div className="hotel-name">
												{ user?.hotelName &&
													<h2><HomeOutlined /> {user?.hotelName}</h2>
												}
											</div>
											<Dropdown
												menu={{
													items,
												}}
												placement="bottomRight"
												arrow
												trigger={['click']}
											>
												<button onClick={(e) => e.preventDefault()}>
													<Avatar style={{ color: '#FFFFFF', backgroundColor: '#205295', margin: '0px 0px 0px auto' }} size="large" icon={<UserOutlined />}></Avatar>
												</button>
											</Dropdown>
										</Header>
										<Content style={{ margin: '16px' }}>
											<div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
												<Routes>
													<Route
														exact
														path={RoutePaths.requests.detail}
														element={<RequestDetail />}
													></Route>
													<Route
														exact
														path={RoutePaths.requests.create}
														element={<RequestCreate />}
													></Route>
													<Route
														path={RoutePaths.bookings.index}
														element={<BookingsList />}
													></Route>
													<Route
														path={RoutePaths.bookings.detail}
														element={<BookingsDetail />}
													></Route>
													<Route
														exact
														path={RoutePaths.rooms.detail}
														element={<RoomDetail />}
													></Route>
													<Route
														path={RoutePaths.rooms.index}
														element={<RoomList/>}
													></Route>
													<Route
														path={RoutePaths.settings.clientData}
														element={<SettingsClientData/>}
													></Route>
													<Route
														path={RoutePaths.settings.styles}
														element={
															<AdminRoute>
																<SettingsStyles/>
															</AdminRoute>
														}
													></Route>
													<Route
														path={RoutePaths.settings.import}
														element={
															<AdminRoute>
																<SettingsImport/>
															</AdminRoute>
														}
													></Route>
													<Route
														path={RoutePaths.settings.privacy}
														element={<SettingsPrivacy/>}
													></Route>
													<Route
														path={RoutePaths.settings.fallbackPrivacy}
														element={
															<AdminRoute>
																<SettingsFallbackPrivacy/>
															</AdminRoute>
														}
													></Route>
													<Route
														path={RoutePaths.settings.agb}
														element={<SettingsAGB/>}
													></Route>
													<Route
														path={RoutePaths.settings.fallbackAgb}
														element={
															<AdminRoute>
																<SettingsFallbackAGB/>
															</AdminRoute>
														}
													></Route>
													<Route
														path={RoutePaths.content.index}
														element={<ContentView/>}
													></Route>
													<Route
														path={RoutePaths.content.usp.index}
														element={<ContentUspView />}
													></Route>
													<Route
														path={RoutePaths.content.usp.detail}
														element={<ContentUspView />}
													></Route>
													<Route
														exact
														path={'*'}
														element={<RequestList />}
													></Route>
												</Routes>
											</div>
										</Content>
									</LayoutView>
								</LayoutView>
							</PrivateRoute>
						}
					></Route>
				</Routes>
			</div>
		</>
	);
};

export default Layout;