import React, { useEffect, useState } from 'react';
import { apiDomain,findTranslationOrFallback } from 'utils/constants';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import { getTranslation } from 'pages/offer/translations/translations';
import Headline from 'pages/offer/components/core/headline';
import Text from 'pages/offer/components/core/text';
import Icon from 'pages/offer/components/core/icon';
import '@splidejs/react-splide/css';
import './index.scss';

function ModuleTeaserT2 ({ content, offer }) {
	const [items, setItems] = useState([]);
	
	const openTeaserT2Text = (e) => {
		e.target.classList.toggle('open');
		e.target.parentNode.nextElementSibling.classList.toggle('open');
	};
	
	useEffect(() => {
		let lang = offer.language.toUpperCase();
		
		let variants = [];
		
		let headline1 = findTranslationOrFallback(content.translations, 'teaserT2_headline1', lang);
		let content1 = findTranslationOrFallback(content.translations, 'teaserT2_text1', lang);
		let image1 = content.contentImages?.filter(image => image.moduleId === 'teasert2_1');
		
		variants.push({
			headline: headline1,
			content: content1,
			image: image1 ? image1[0] : '',
		});
		
		let headline2 = findTranslationOrFallback(content.translations, 'teaserT2_headline2', lang);
		let content2 = findTranslationOrFallback(content.translations, 'teaserT2_text2', lang);
		let image2 = content.contentImages?.filter(image => image.moduleId === 'teasert2_2');
		
		variants.push({
			headline: headline2,
			content: content2,
			image: image2 ? image2[0] : '',
		});
		
		let headline3 = findTranslationOrFallback(content.translations, 'teaserT2_headline3', lang);
		let content3 = findTranslationOrFallback(content.translations, 'teaserT2_text3', lang);
		let image3 = content.contentImages?.filter(image => image.moduleId === 'teasert2_3');
		
		variants.push({
			headline: headline3,
			content: content3,
			image: image3 ? image3[0] : '',
		});
		
		setItems(variants);
	}, [content, offer]);
	
	return (
		<>
			{items &&
				<div className="teaser-t2">
					<div className="centercontent1470">
						<div className="gallery">
							<Splide
								hasTrack={ false }
								options={{
									perPage: 3,
									drag: 'free',
									snap: true,
									pagination: false,
									arrows: false,
									gap: '40px',
									breakpoints: {
										650: {
											perPage: 1,
											gap: '14px',
										},
									},
								}}
							>
								<SplideTrack>
									{ items && 
										items.map((elem, i) => {
											return (
												<SplideSlide key={ i }>
													<div className="element">
														{ elem.image &&
															<div className="image">
																<img src={apiDomain() + elem.image.path} key={elem.image.name} alt={elem.image.name} />
															</div>
														}
														{ ( elem.headline || elem.content ) &&
															<div className="content">
																{ elem.headline &&
																	<Headline
																		class={ 'headline t3' }
																		text={ elem.headline }
																	/>
																}
																{ elem.content &&
																	<>
																		<div className="toggle t3">
																			<button onClick={ openTeaserT2Text }>
																				<div className="toggle-text">
																					{ getTranslation('teasert2', 'details', offer.language) }
																				</div>
																				<div className="toggle-icon">
																					<Icon
																						icon="arrow-down"
																					/>
																				</div>
																			</button>
																		</div>
																		<Text text={ elem.content } />
																	</>
																}
															</div>
														}
													</div>
												</SplideSlide>
											);
										})
									}
								</SplideTrack>
							</Splide>
						</div>
					</div>
				</div>
			}
		</>
	);
};

export default ModuleTeaserT2;