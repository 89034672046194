import Headline from 'pages/offer/components/core/headline';
import { getTranslation } from 'pages/offer/translations/translations';
import { findTranslationOrFallback } from 'utils/constants';
import './index.scss';

function ModuleCreditsT1 ({ offer }) {
	return (
		<div className="credits-t1">
			<div className="centercontentlargest">
				<div className="container bgteaser">
					<div className="centercontent1470">
						<div className="text-container">
							<Headline
								class={ 'headline t1' }
								text={ getTranslation('creditst1', 'headline', offer.language) }
							/>
							<div className="content">
								<div className="name">{findTranslationOrFallback(offer.hotelInfos?.translations, 'name', offer.language.toUpperCase()) + ' ' + findTranslationOrFallback(offer.hotelInfos?.translations, 'nameAdditional', offer.language.toUpperCase())}</div>
								<div className="address">{findTranslationOrFallback(offer.hotelInfos?.translations, 'address', offer.language.toUpperCase()) + ' , ' + offer.hotelInfos?.cap + ' ' + findTranslationOrFallback(offer.hotelInfos?.translations, 'place', offer.language.toUpperCase())}</div>
								<div className="country">{findTranslationOrFallback(offer.hotelInfos?.translations, 'province', offer.language.toUpperCase()) + ' ' + findTranslationOrFallback(offer.hotelInfos?.translations, 'country', offer.language.toUpperCase())}</div>
								{ offer.hotelInfos?.phone &&
									<div className="tel"><a href={'tel:' + offer.hotelInfos.phone}>{offer.hotelInfos.phone}</a></div>
								}
								{ offer.hotelInfos?.email &&
									<div className="email"><a href={'mailto:' + offer.hotelInfos.email}>{offer.hotelInfos.email}</a></div>
								}
								{ offer.hotelInfos?.website &&
									<div className="website"><a href={offer.hotelInfos.website} target="_blank" rel="noreferrer">{offer.hotelInfos.website}</a></div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModuleCreditsT1;