import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Divider, Form, Input, Row, Upload, notification, Affix } from 'antd';
import Title from 'antd/es/typography/Title';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { RoutePaths, apiDomain, isValidUploadFormat, findTranslationOrFallback, languages } from 'utils/constants';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';
import TextEditor from 'components/forms/text-editor';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/core/language-switcher';
import LanguageInput from 'components/forms/language-input';
import './index.scss';

function RoomDetail() {
	const navigate = useNavigate();
	const { accessToken, user } = useAuth();
	const { roomId } = useParams();
	const [loading, setLoading] = useState(true);
	const [importUrl, setImportUrl] = useState(null);
	const [room, setRoom] = useState([]);
	const [fileList, setFileList] = useState([]);
	const [sketchesList, setSketchesList] = useState([]);
	const [removeList, setRemoveList] = useState([]);
	const [removeListSketches, setRemoveListSketches] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	const { t } = useTranslation();
	const [language, setLanguage] = useState(languages.find(lang => lang.default).key);
	const [form] = Form.useForm();
	
	const fetchImportUrl = useCallback(async() => {
		setLoading(true);
		try {
			const { data: response } = await axios.get(apiDomain() + 'clients/' + user.hotelId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setImportUrl(response.roomImport);
			}
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	}, [accessToken,user.hotelId]);
	
	const fetchData = useCallback(async() => {
		setLoading(true);
		try {
			const { data: response } = await axios.get(apiDomain() + 'rooms/' + roomId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setRoom(response);
				if (response.images) {
					setFileList(response.images.map((file, i) => {
						return {
							index: i,
							name: 'Bild ' + i,
							fileName: file.name,
							thumbUrl: file.path ? (apiDomain() + file.path) : file.externalPath,
							url: file.path ? (apiDomain() + file.path) : file.externalPath,
							existingImage: true,
						};
					}));
				}
				if (response.sketches) {
					setSketchesList(response.sketches.map((file, i) => {
						return {
							index: i,
							name: 'Bild ' + i,
							fileName: file.name,
							thumbUrl: file.path ? (apiDomain() + file.path) : file.externalPath,
							url: file.path ? (apiDomain() + file.path) : file.externalPath,
							existingImage: true,
						};
					}));
				}
			}
		} catch (error) {
			console.error(error.message);
			navigate(RoutePaths.rooms.index);
		}
		setLoading(false);
	}, [roomId, accessToken, navigate]);
	
	const onSubmit = async (values) => {
		try {
			const data = new FormData();
			fileList.forEach((file, i) => {
				if (!file.existingImage) {
					data.append('images', file, file.name);
				}
			});
			sketchesList.forEach((file, i) => {
				if (!file.existingImage) {
					data.append('sketches', file, file.name);
				}
			});
			
			let fileToDeleteArray = [];
			removeList.forEach((file, i) => {
				fileToDeleteArray.push(file.fileName);
			});
			removeListSketches.forEach((file, i) => {
				fileToDeleteArray.push(file.fileName);
			});
			
			if (fileToDeleteArray.length > 0) {
				data.append('filesToDelete', fileToDeleteArray.join('|'));
			}
			
			Object.keys(values).forEach(function(key,index) {
				data.append(key, values[key]);
			});
			
			data.append('hotelId', user.hotelId);
			
			await axios.put(apiDomain() + 'rooms/' + room.id, data, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			api['success']({
				message: t('general.saveSuccess'),
				description: t('general.saveSuccessText'),
				placement: 'bottomRight',
				duration: 3,
			});
			fetchData();
			setRemoveList([]);
			setRemoveListSketches([]);
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	const beforeUpload = (file) => {
		return false;
	};
	
	const onChangeHandle = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (tempFiles.length < 6) {
				if (file.hasOwnProperty('existingImage')) {
					tempFiles.push(file);
				} else {
					if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t)) {
						tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
					}
				}
			}
		});
		setFileList(tempFiles);
	};
	
	const onRemove = (file) => {
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
		setRemoveList([...removeList, file]);
	};
	
	const beforeUploadSketch = (file) => {
		return false;
	};
	
	const onChangeHandleSketch = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (tempFiles.length < 1) {
				if (file.hasOwnProperty('existingImage')) {
					tempFiles.push(file);
				} else {
					if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t)) {
						tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
					}
				}
			}
		});
		setSketchesList(tempFiles);
	};
	
	const onRemoveSketch = (file) => {
		const index = sketchesList.indexOf(file);
		const newFileList = sketchesList.slice();
		newFileList.splice(index, 1);
		setSketchesList(newFileList);
		setRemoveListSketches([...removeListSketches, file]);
	};
	
	const deleteRoom = () => {
		axios.delete(apiDomain() + 'rooms/' + room.id, {
			headers: {
				'x-access-token': accessToken,
			},
			data: { hotelId: user.hotelId },
		}).then(response => {
			if (response.data.status === 'ok') {
				navigate(RoutePaths.rooms.index);
			}
		});
	};
	
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				{t('rooms.upload')}
			</div>
		</div>
	);
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	useEffect(() => {
		if (form && room) {
			form.setFieldValue('size', room.size ? room.size : '');
			form.setFieldValue('code', room.code ? room.code : '');
			languages.forEach(lang => {
				form.setFieldValue(('name' + lang.key), findTranslationOrFallback(room.roomTranslations, 'name', lang.key));
			});
		}
	}, [room,form]);
	
	useEffect(() => {
		fetchImportUrl();
	}, [fetchImportUrl]);
	
	return (
		<div className="room-detail">
			{contextHolder}
			{ (!loading && room) &&
				<>
					<Form
						layout="vertical"
						onFinish={onSubmit}
						requiredMark={false}
						form={form}
					>
						<div className="headline-container">
							<Title level={3}>{t('rooms.room')}</Title>
							{ !importUrl &&
								<div className="right-content">
									<Button type="primary" onClick={() => deleteRoom()} danger icon={<DeleteOutlined />}>
										{t('rooms.delete')}
									</Button>
								</div>
							}
						</div>
						<Divider/>
						<LanguageSwitcher
							currentLanguage={language}
							onSwitch={(lang) => setLanguage(lang)}
						/>
						<LanguageInput
							name="name"
							currentLanguage={language}
							size="large"
							disabled={importUrl}
							rules={[
								{
									required: true,
									message: t('rooms.nameError'),
								},
							]}
						/>
						<Divider/>
						<Row gutter={24}>
							<Col span={12}>
								<div className="label">{t('rooms.images')}</div>
								{ !importUrl ? (
									<Upload
										listType="picture-card"
										fileList={fileList}
										beforeUpload={beforeUpload}
										onRemove={onRemove}
										onChange={onChangeHandle}
										multiple
									>
										{fileList.length >= 6 ? null : uploadButton}
									</Upload>
								) : (
									fileList.length > 0 &&
										<div className="not-editable-file-list">
											<Row gutter={24}>
												{ fileList.map((file) => {return (
													<Col span={5}>
														<img src={file.url} alt="" />
													</Col>
												);
												})}
											</Row>
										</div>
								)}
							</Col>
							<Col span={12}>
								<div className="label">{t('rooms.sketch')}</div>
								{ !importUrl ? (
									<Upload
										listType="picture-card"
										fileList={sketchesList}
										beforeUpload={beforeUploadSketch}
										onRemove={onRemoveSketch}
										onChange={onChangeHandleSketch}
										multiple
									>
										{sketchesList.length >= 1 ? null : uploadButton}
									</Upload>
								) : (
									sketchesList.length > 0 &&
										<div className="not-editable-file-list">
											<Row gutter={24}>
												{ sketchesList.map((file) => {return (
													<Col span={5}>
														<img src={file.url} alt="" />
													</Col>
												);
												})}
											</Row>
										</div>
								)}
							</Col>
							<Divider/>
							<Col span={24}>
								<div className="label">{t('rooms.headline')}</div>
								<TextEditor
									formItemName="headline"
									form={form}
									language={language}
									translations={room.roomTranslations}
									disabled={importUrl}
								/>
							</Col>
							<Col span={24}>
								<div className="label">{t('rooms.subheadline')}</div>
								<TextEditor
									formItemName="subheadline"
									form={form}
									language={language}
									translations={room.roomTranslations}
									disabled={importUrl}
								/>
							</Col>
							<Col span={24}>
								<div className="label">{t('rooms.description')}</div>
								<TextEditor
									formItemName="description"
									form={form}
									language={language}
									translations={room.roomTranslations}
									disabled={importUrl}
								/>
							</Col>
							<Divider/>
							<Col span={12}>
								<Form.Item label={t('rooms.size')} name="size">
									<Input disabled={importUrl}/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={t('rooms.code')} name="code">
									<Input disabled={importUrl}/>
								</Form.Item>
							</Col>
						</Row>
						{ !importUrl &&
							<Affix offsetBottom={0} className="sticky-save-container">
								<div className="content">
									<Button type="primary" htmlType="submit">
										{t('rooms.save')}
									</Button>
								</div>
							</Affix>
						}
					</Form>
				</>
			}
		</div>
	);
}

export default RoomDetail;