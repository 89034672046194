import { Button, Col, Divider, Form, Input, Row, notification, Affix } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useState, useCallback, useEffect } from 'react';
import { apiDomain, languages } from 'utils/constants';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/core/language-switcher';
import TextEditor from 'components/forms/text-editor';
import axios from 'axios';
import './index.scss';

function SettingsFallbackAGB () {
	const [data, setData] = useState(null);
	const { accessToken, user } = useAuth();
	const [api, contextHolder] = notification.useNotification();
	const [language, setLanguage] = useState(languages.find(lang => lang.default).key);
	const { t } = useTranslation();
	const [form] = Form.useForm();
	
	const fetchData = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'settings/fallback-agb', {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setData(response);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken]);
	
	const onSubmit = async (values) => {
		try {
			await axios.put(apiDomain() + 'settings/fallback-agb', {
				hotelId: user.hotelId,
				...values,
			}, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			api['success']({
				message: t('general.saveSuccess'),
				description: t('general.saveSuccessText'),
				placement: 'bottomRight',
				duration: 3,
			});
			fetchData();
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	return (
		<div className="settings-fallback-privacy">
			{contextHolder}
			{ data &&
				<Form
					layout={'vertical'}
					onFinish={onSubmit}
					form={form}
				>
					<div className="headline-container">
						<Title level={3}>{t('fallbackAgb.agb')}</Title>
						<LanguageSwitcher
							currentLanguage={language}
							onSwitch={(lang) => setLanguage(lang)}
						/>
					</div>
					<Divider/>
					<br />
					<Input.Group>
						<Row gutter={8}>
							<Col span={16}>
								<div className="field">
									<div className="label">{t('fallbackPrivacy.text')}</div>
									<TextEditor
										formItemName={'text'}
										form={form}
										language={language}
										translations={data.translations}
									/>
								</div>
							</Col>
						</Row>
					</Input.Group>
					<Divider />
					<br />
					<Affix offsetBottom={0} className="sticky-save-container">
						<div className="content">
							<Form.Item>
								<Button type="primary" htmlType="submit">{t('clientData.save')}</Button>
							</Form.Item>
							<LanguageSwitcher
								currentLanguage={language}
								onSwitch={(lang) => setLanguage(lang)}
							/>
						</div>
					</Affix>
				</Form>
			}
		</div>
	);
};

export default SettingsFallbackAGB;