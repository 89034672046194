import Tag from 'components/core/tag';
import LanguageFlag from 'components/core/language-flag';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import { Affix, Button, Card, Col, Divider, Row, Space, Table } from 'antd';
import { useAuth } from 'hooks/useAuth';
import { apiDomain, backendDomain, mealPlans, RoutePaths } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import './index.scss';
import { formatToPrice } from 'utils/formats';
import { getVariantTotalPrice } from 'pages/offer/helpers/helpers';

function BookingsDetail() {
	const { accessToken } = useAuth();
	const { requestId } = useParams();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const navigate = useNavigate();
	const { t } = useTranslation();
	
	const fetchData = useCallback(async() => {
		setLoading(true);
		try {
			const { data: response } = await axios.get(apiDomain() + 'bookings/' + requestId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				let request = {
					...response,
					key: response.id,
					date: moment(response.date).format('DD.MM.YYYY - HH:mm') + ' ' + t('request.time'),
					arrivalDate: moment(response.booking.bookedVariant.arrivalDate).format('DD.MM.YYYY'),
					departureDate: moment(response.booking.bookedVariant.departureDate).format('DD.MM.YYYY'),
				};
				setData(request);
			}
		} catch (error) {
			console.error(error.message);
			navigate(RoutePaths.bookings.index);
		}
		setLoading(false);
	}, [requestId, accessToken, navigate, t]);
	
	const acceptBooking = async () => {
		try {
			await axios.post(apiDomain() + 'bookings/confirmBooking/' + data.uid, {}, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			fetchData();
		} catch (error) {
			console.error(error.message);
		}
	};
	
	const getDiscount = (discount) => {
		if (discount > 0) {
			return (
				<span className="discount">-{discount}%</span>
			);
		} else {
			return '';
		}
	};
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	return (
		<div className="bookings-detail">
			{ (!loading && data) &&
				<>
					<div className="headline-container">
						<Title level={3}>{data.booking.firstName + ' ' + data.booking.lastName}</Title>
						<div className="right-content">
							{data.date} - <LanguageFlag language={data.language} />
							<Tag color={data.bookingConfirmed ? 'green' : 'geekblue'} text={data.bookingConfirmed ? t('request.bookedConfirmation') : t('request.booked')} />
						</div>
					</div>
					<Divider/>
					<br />
					<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
						<Row gutter={16}>
							<Col span={24}>
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('request.dateInfo')}
									</Title>
								</Divider>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.arrival')}>{data.arrivalDate}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.arrival')}>{data.departureDate}</Card>
							</Col>
							{ data.booking.bookedVariant.roomCode &&
								<Col span={8}>
									<Card size="small" title={t('request.room')}>{data.booking.bookedVariant.roomCode}</Card>
								</Col>
							}
							<Col span={8}>
								<Card size="small" title={t('request.adults')}>{data.booking.bookedVariant.adults}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.children')}>{data.booking.bookedVariant.children}</Card>
							</Col>
							{ data.booking.bookedVariant.mealPlan &&
								<Col span={8}>
									<Card size="small" title={t('request.mealPlan')}>{mealPlans(t).find(plan => plan.value === data.booking.bookedVariant.mealPlan)?.label ? mealPlans(t).find(plan => plan.value === data.booking.bookedVariant.mealPlan)?.label : data.booking.bookedVariant.mealPlan}</Card>
								</Col>
							}
							<Col span={24}>
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('request.prices.prices')}
									</Title>
								</Divider>
							</Col>
							<Col span={24} className="price-table">
								<Table
									pagination={false}
									columns={[
										{
											title: t('request.prices.description'),
											dataIndex: 'description',
											key: 'description',
										},
										{
											title: t('request.prices.quantity'),
											dataIndex: 'quantity',
											key: 'quantity',
										},
										{
											title: t('request.prices.singlePrice'),
											key: 'singlePrice',
											render: (price) => <div className={'singlePrice' + (price.discount > 0 ? ' with-discount' : '')}><span>{formatToPrice(price.singlePrice)}</span>{getDiscount(price.discount)}</div>,
										},
										{
											title: t('request.prices.total'),
											key: 'total',
											render: (price) => {
												let totalPrice = (parseFloat(price.singlePrice) * price.quantity);
												if (price.discount > 0) {
													totalPrice = totalPrice * (1 - (price.discount / 100));
												}
												return formatToPrice(totalPrice);
											},
										},
									]}
									dataSource={data.booking.bookedVariant.offer_variants_prices.map((price, index) => ({
										...price,
										key: index,
									}))}
								/>
								<div className="total-price">
									<h3 className="title">{t('request.prices.totalPrice')}: {formatToPrice(getVariantTotalPrice(data.booking.bookedVariant.offer_variants_prices))}</h3>
								</div>
							</Col>
							<Col span={24}>
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('request.personalData')}
									</Title>
								</Divider>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.salutation')}>{data.booking.salutation ? (data.booking.salutation === 'M' ? t('request.salutationMale') : t('request.salutationFemale')) : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.firstName')}>{data.booking.firstName ? data.booking.firstName : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.lastName')}>{data.booking.lastName ? data.booking.lastName : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.email')}>
									{data.booking.email ? (
										<a href={'mailto:' + data.booking.email}>{data.booking.email}</a>
									) : (
										'-'
									)}
								</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.phone')}>{data.booking.phone ? data.booking.phone : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.street')}>{data.booking.street ? data.booking.street : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.cap')}>{data.booking.cap ? data.booking.cap : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.place')}>{data.booking.place ? data.booking.place : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.country')}>{data.booking.country ? data.booking.country : '-'}</Card>
							</Col>
							{ data.booking.companyName &&
								<>
									<Col span={24}>
										<Divider orientation="left" orientationMargin="0">
											<Title level={4}>
												{t('request.companyData')}
											</Title>
										</Divider>
									</Col>
									<Col span={8}>
										<Card size="small" title={t('request.companyName')}>{data.booking.companyName ? data.booking.companyName : '-'}</Card>
									</Col>
									<Col span={8}>
										<Card size="small" title={t('request.vat')}>{data.booking.companyVat ? data.booking.companyVat : '-'}</Card>
									</Col>
									<Col span={8}>
										<Card size="small" title={t('request.recipientCode')}>{data.booking.companyRecipientCode ? data.booking.companyRecipientCode : '-'}</Card>
									</Col>
									<Col span={8}>
										<Card size="small" title={t('request.street')}>{data.booking.companyStreet ? data.booking.companyStreet : '-'}</Card>
									</Col>
									<Col span={8}>
										<Card size="small" title={t('request.place')}>{data.booking.companyPlace ? data.booking.companyPlace : '-'}</Card>
									</Col>
									<Col span={8}>
										<Card size="small" title={t('request.cap')}>{data.booking.companyCap ? data.booking.companyCap : '-'}</Card>
									</Col>
									<Col span={8}>
										<Card size="small" title={t('request.country')}>{data.booking.companyCountry ? data.booking.companyCountry : '-'}</Card>
									</Col>
								</>
							}
							<Col span={24}>
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('request.source')}
									</Title>
								</Divider>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.utm_source')}>{data.utm_source ? data.utm_source : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.utm_medium')}>{data.utm_medium ? data.utm_medium : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.utm_campaign')}>{data.utm_campaign ? data.utm_campaign : '-'}</Card>
							</Col>
						</Row>
					</Space>
					<br />
					<Divider />
					<br />
					<Affix offsetBottom={0} className="sticky-save-container">
						<div className="content">
							<div className="buttons">
								{ !data.bookingConfirmed &&
									<Button type="primary" onClick={() => acceptBooking()}>
										{t('booking.acceptBooking')}
									</Button>
								}
								<Button href={backendDomain() + 'offer/' + data.uid} target="_blank">
									{t('booking.bookingLink')}
								</Button>
							</div>
						</div>
					</Affix>
				</>
			}
		</div>
	);
}

export default BookingsDetail;