import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Affix, Button, Col, Divider, Form, Input, Row, notification, DatePicker, Select, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAuth } from 'hooks/useAuth';
import Title from 'antd/es/typography/Title';
import { RoutePaths, apiDomain, findTranslationOrFallback, languageSelect, mealPlans, numberSelect, salutations } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { countriesDE } from 'utils/countries/de';
import { countriesIT } from 'utils/countries/it';
import { countriesEN } from 'utils/countries/en';
import './index.scss';

function RequestCreate() {
	const { accessToken, user } = useAuth();
	const [form] = Form.useForm();
	const [rooms, setRooms] = useState([]);
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [api, contextHolder] = notification.useNotification();
	const { RangePicker } = DatePicker;
	
	const fetchRooms = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'rooms', {
				headers: {
					'x-access-token': accessToken,
				},
				
			});
			if (response) {
				let rooms = response.map(function(obj) {
					return {
						value: obj.code,
						label: findTranslationOrFallback(obj.roomTranslations, 'name', i18n.resolvedLanguage.toUpperCase()),
						labelDE: findTranslationOrFallback(obj.roomTranslations, 'name', 'DE'),
						labelIT: findTranslationOrFallback(obj.roomTranslations, 'name', 'IT'),
						labelEN: findTranslationOrFallback(obj.roomTranslations, 'name', 'EN'),
						code: obj.code,
					};
				});
				setRooms(rooms);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken, i18n.resolvedLanguage]);
	
	const onSubmit = async (values) => {
		try {
			const response = await axios.post(apiDomain() + 'requests/create', { ...values, hotelId: user.hotelId, arrival: dayjs(values['date'][0]).format('YYYY-MM-DD'), departure: dayjs(values['date'][1]).format('YYYY-MM-DD') }, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			api['success']({
				message: t('general.saveSuccess'),
				description: t('general.saveSuccessText'),
				placement: 'bottomRight',
				duration: 3,
			});
			navigate(RoutePaths.requests.index + '/' + response.data.id);
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	const disabledDate = (current) => {
		return current && current < dayjs().startOf('day');
	};
	
	useEffect(() => {
		fetchRooms();
	}, [fetchRooms]);
	
	return (
		<div className="request-create">
			{contextHolder}
			<Form
				form={form}
				layout="vertical"
				onFinish={onSubmit}
				initialValues={{
					arrival: '',
					departure: '',
					room: '',
					mealPlan: '',
					adults: '',
					children: 0,
					salutation: '',
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					street: '',
					cap: '',
					place: '',
					country: '',
					comment: '',
				}}
			>
				<Row gutter={24}>
					<Col span={24}>
						<Divider orientation="left" orientationMargin="0">
							<Title level={4}>
								{t('request.dateInfo')}
							</Title>
						</Divider>
					</Col>
					<Col span={12}>
						<Form.Item
							name={'date'}
							label={t('request.arrival') + '/' + t('request.departure')}
							rules={[
								{
									required: true,
									message: t('general.form.requiredField'),
								},
							]}
						>
							<RangePicker
								size="large"
								format="DD/MM/YYYY"
								disabledDate={disabledDate}
							/>
						</Form.Item>
					</Col>
					<Col span={12}></Col>
					<Col span={12}>
						<Form.Item
							name="room"
							label={t('request.room')}
							rules={[
								{
									required: true,
									message: t('general.form.requiredField'),
								},
							]}
						>
							<Select
								size="large"
								showSearch
								optionFilterProp="label"
								options={rooms}
								notFoundContent={<Empty description={t('request.roomEmpty')}/>}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="mealPlan"
							label={t('request.mealPlan')}
							rules={[
								{
									required: true,
									message: t('general.form.requiredField'),
								},
							]}
						>
							<Select
								size="large"
								showSearch
								optionFilterProp="label"
								options={mealPlans(t, i18n.resolvedLanguage)}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="adults"
							label={t('request.adults')}
							rules={[
								{
									required: true,
									message: t('general.form.requiredField'),
								},
							]}
						>
							<Select
								size="large"
								options={numberSelect(10)}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="children"
							label={t('request.children')}
						>
							<Select
								size="large"
								options={numberSelect(10)}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item label={t('request.commentAdditional')} name="comment">
							<TextArea size="large"/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Divider orientation="left" orientationMargin="0">
							<Title level={4}>
								{t('request.personalData')}
							</Title>
						</Divider>
					</Col>
					<Col span={8}>
						<Form.Item
							label={t('request.salutation')}
							name="salutation"
							rules={[
								{
									required: true,
									message: t('general.form.requiredField'),
								},
							]}
						>
							<Select
								showSearch
								optionFilterProp="label"
								size="large"
								options={salutations(t, i18n.resolvedLanguage)}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={t('request.firstName')}
							name="firstName"
							rules={[
								{
									required: true,
									message: t('general.form.requiredField'),
								},
							]}
						>
							<Input size="large"/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t('request.lastName')} name="lastName">
							<Input size="large"/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={t('request.email')}
							name="email"
							rules={[
								{
									required: true,
									message: t('general.form.requiredField'),
								},
							]}
						>
							<Input size="large"/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t('request.phone')} name="phone">
							<Input size="large"/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t('request.street')} name="street">
							<Input size="large"/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t('request.cap')} name="cap">
							<Input size="large"/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t('request.place')} name="place">
							<Input size="large"/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={t('request.country')}
							name="country"
						>
							<Select
								showSearch
								optionFilterProp="label"
								size="large"
								options={i18n.resolvedLanguage === 'de' ? countriesDE : i18n.resolvedLanguage === 'it' ? countriesIT : countriesEN}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={t('request.language')}
							name="language"
							rules={[
								{
									required: true,
									message: t('general.form.requiredField'),
								},
							]}
						>
							<Select
								showSearch
								optionFilterProp="label"
								size="large"
								options={languageSelect()}
							/>
						</Form.Item>
					</Col>
				</Row>
				<br />
				<Divider />
				<br />
				<Affix offsetBottom={0} className="sticky-save-container">
					<div className="content">
						<Button type="primary" icon={<PlusOutlined />} onClick={() => form.submit()}>
							{t('request.create')}
						</Button>
					</div>
				</Affix>
			</Form>
		</div>
	);
}

export default RequestCreate;