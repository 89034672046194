import { Col, Divider, Row, Upload } from 'antd';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { findTranslationOrFallback, isValidUploadFormat, languages } from 'utils/constants';
import LanguageInput from 'components/forms/language-input';
import TextEditor from 'components/forms/text-editor';

function TeaserT1 ({ content, form, language, images, removeList, onAddImages, onRemoveImages }) {
	const { t } = useTranslation();
	
	const beforeUpload = () => {
		return false;
	};
	
	const onChangeHandle = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		onAddImages(tempFiles);
	};
	
	const onRemove = (file) => {
		const index = images.indexOf(file);
		const newFileList = images.slice();
		newFileList.splice(index, 1);
		onAddImages(newFileList);
		onRemoveImages([...removeList, file]);
	};
	
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				{t('content.lp.general.upload')}
			</div>
		</div>
	);
	
	useEffect(() => {
		languages.forEach(lang => {
			form.setFieldValue(('teaserT1_headline' + lang.key), findTranslationOrFallback(content.translations, 'teaserT1_headline', lang.key));
		});
	}, [content, form]);
	
	return (
		<div className="teaser-t1-container block">
			<Divider orientation="left" orientationMargin="0">
				<Title level={4}>
					{t('content.lp.teasert1.teasert1')}
				</Title>
			</Divider>
			<div className="fields">
				<Row gutter={24}>
					<Col span={12}>
						<div className="label">{t('content.lp.general.image')}</div>
						<Upload
							listType="picture-card"
							fileList={images}
							beforeUpload={beforeUpload}
							onChange={onChangeHandle}
							onRemove={onRemove}
							multiple
						>
							{images.length >= 1 ? null : uploadButton}
						</Upload>
					</Col>
				</Row>
				<LanguageInput
					label={t('content.lp.general.headline')}
					name="teaserT1_headline"
					currentLanguage={language}
				/>
				<div className="field">
					<div className="label">{t('content.lp.general.text')}</div>
					<TextEditor
						formItemName="teaserT1_text"
						form={form}
						language={language}
						translations={content.translations}
					/>
				</div>
			</div>
		</div>
	);
};

export default TeaserT1;