import LanguageFlag from 'components/core/language-flag';
import TextEditor from 'components/forms/text-editor';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, DatePicker, Drawer, Empty, Form, InputNumber, Row, Select, Space, Tabs, notification, Table, Typography, Input, Card, Divider, Checkbox } from 'antd';
import { useAuth } from 'hooks/useAuth';
import { apiDomain, mealPlans, numberSelect } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { formatToPrice, getFromToDate, getOptionsWithState, getTotalPriceOfRateplan } from 'utils/formats';
import axios from 'axios';
import dayjs from 'dayjs';
import moment from 'moment';
import './index.scss';

function RequestOffer({ open, onClose, requestId, arrival, departure, room, roomCode, offer, mealPlan, rooms, adults, children, childrenAges, comment, language, introTextDefault, introTextDefault2, introTextDefault3, introTextDefaultNotAvailable }) {
	const { accessToken, user } = useAuth();
	const [form] = Form.useForm();
	const [api, contextHolder] = notification.useNotification();
	const [tabCount, setTabCount] = useState(1);
	const [available, setAvailable] = useState(offer ? (offer.available ? true : false) : true);
	const [introText, setIntroText] = useState(available ? (offer?.introText ? offer.introText : introTextDefault): introTextDefaultNotAvailable);
	const { t, i18n } = useTranslation();
	
	const initialItems = () => {
		if (offer) {
			let offerItems = [];
			offer.offer_variants?.forEach((variant, index) => {
				offerItems.push({
					label: t('request.variant'),
					children: <TabContent index={index + 1} arrival={moment(variant.arrivalDate).format('YYYY-MM-DD')} departure={moment(variant.departureDate).format('YYYY-MM-DD')} rooms={rooms} room={rooms.find(roomObject => roomObject.code === variant.roomCode) ? rooms.find(roomObject => roomObject.code === variant.roomCode)?.value : rooms[0]?.value} form={form} adults={variant.adults} children={variant.children} mealPlan={variant.mealPlan} currentPrices={variant.offer_variants_prices} language={language}/>,
					key: (index + 1),
					forceRender: true,
				});
			});
			return offerItems;
		} else {
			return [
				{
					label: t('request.variant'),
					children: <TabContent index="1" arrival={dayjs(arrival, 'DD/MM/YYYY').format('YYYY-MM-DD')} departure={dayjs(departure, 'DD/MM/YYYY').format('YYYY-MM-DD')} rooms={rooms} room={room} form={form} adults={adults} children={children} mealPlan={mealPlan} language={language}/>,
					key: 1,
					forceRender: true,
				},
			];
		}
	};
	
	const initialValues = () => {
		if (offer) {
			let offerItems = {};
			offer.offer_variants?.forEach((variant, index) => {
				offerItems['date' + (index + 1)] = [dayjs(moment(variant.arrivalDate).format('DD/MM/YYYY'), 'DD/MM/YYYY'),dayjs(moment(variant.departureDate).format('DD/MM/YYYY'), 'DD/MM/YYYY')];
				offerItems['room' + (index + 1)] = rooms.find(roomObject => roomObject.code === variant.roomCode) ? rooms.find(roomObject => roomObject.code === variant.roomCode)?.value : rooms[0]?.value;
				offerItems['mealPlan' + (index + 1)] = variant.mealPlan;
				offerItems['adults' + (index + 1)] = variant.adults;
				offerItems['children' + (index + 1)] = variant.children;
			});
			return offerItems;
		} else {
			return {
				'date1': [dayjs(arrival, 'DD/MM/YYYY'), dayjs(departure, 'DD/MM/YYYY')],
				'room1': (user && user.asaFreeRooms === 0) ? rooms.find(roomObject => roomObject.value === room) ? rooms.find(roomObject => roomObject.value === room)?.value : rooms[0]?.value : '',
				'adults1': adults,
				'children1': children,
				'mealPlan1': isNaN(mealPlan) ? '' : mealPlan,
			};
		}
	};
	
	const [items, setItems] = useState(initialItems);
	const [activeKey, setActiveKey] = useState(1);
	const onChange = (newActiveKey) => {
		setActiveKey(newActiveKey);
	};
	
	const add = () => {
		const newTabs = [...items];
		newTabs.push({
			label: t('request.variant'),
			children: <TabContent index={tabCount + 1} arrival={dayjs(arrival, 'DD/MM/YYYY').format('YYYY-MM-DD')} departure={dayjs(departure, 'DD/MM/YYYY').format('YYYY-MM-DD')} rooms={rooms} room={room} form={form} adults={adults} children={children} mealPlan={mealPlan} language={language}/>,
			key: tabCount + 1,
			forceRender: true,
		});
		
		form.setFieldValue(('date' + (tabCount + 1)), [dayjs(arrival, 'DD/MM/YYYY'), dayjs(departure, 'DD/MM/YYYY')]);
		form.setFieldValue(('room' + (tabCount + 1)), (user && user.asaFreeRooms === 0) ? rooms.find(roomObject => roomObject.value === room) ? rooms.find(roomObject => roomObject.value === room)?.value : rooms[0]?.value : '');
		form.setFieldValue(('adults' + (tabCount + 1)), adults);
		form.setFieldValue(('children' + (tabCount + 1)), children);
		form.setFieldValue(('mealPlan' + (tabCount + 1)), isNaN(mealPlan) ? '' : mealPlan);
		
		setItems(newTabs);
		setActiveKey(tabCount + 1);
		setTabCount(tabCount + 1);
	};
	
	const remove = (targetKey) => {
		let newTabs = items.filter((item) => item.key !== targetKey);
		setItems(newTabs);
		if (newTabs.length) {
			setActiveKey(newTabs[0].key);
		} else {
			setActiveKey(1);
		}
	};
	
	const onEdit = (targetKey, action) => {
		if (action === 'add') {
			add();
		} else {
			remove(targetKey);
		}
	};
	
	const onSubmit = async (values) => {
		let variants = [];
		if (available) {
			for (let i = 1; i <= 30; i++) {
				if (values.hasOwnProperty('date' + i)) {
					let prices = [];
					for (let priceIndex = 0; priceIndex <= 50; priceIndex++) {
						if (values.hasOwnProperty('variant' + i + 'priceDescription' + priceIndex)) {
							prices.push({
								description: values['variant' + i + 'priceDescription' + priceIndex],
								quantity: values['variant' + i + 'priceQuantity' + priceIndex],
								singlePrice: values['variant' + i + 'priceSinglePrice' + priceIndex],
								discount: values['variant' + i + 'priceDiscount' + priceIndex],
							});
						}
					}
					variants.push({
						arrivalDate: dayjs(values['date' + i][0]).format('YYYY-MM-DD'),
						departureDate: dayjs(values['date' + i][1]).format('YYYY-MM-DD'),
						roomCode: rooms.find(roomObject => roomObject.value === values['room' + i])?.code,
						mealPlan: values['mealPlan' + i],
						adults: values['adults' + i],
						children: values['children' + i],
						prices,
					});
				}
			}
		}
		try {
			let response = '';
			if (offer) {
				response = await axios.put(apiDomain() + 'offers/' + offer.id, { requestId, variants, hotelId: user.hotelId, available, introText }, {
					headers: {
						'x-access-token': accessToken,
					},
				});
			} else {
				response = await axios.post(apiDomain() + 'offers', { requestId, variants, hotelId: user.hotelId, available, introText }, {
					headers: {
						'x-access-token': accessToken,
					},
				});
			}
			if (response) {
				onClose(true);
			}
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	const onError = (error) => {
		api['error']({
			message: t('request.errorVariantHeadline'),
			description:
				t('request.errorVariantText'),
			placement: 'bottomRight',
		});
	};
	
	const onChangeAvailable = (e) => {
		setAvailable(!e.target.checked);
		if (e.target.checked) {
			setIntroText(introTextDefaultNotAvailable);
		} else {
			setIntroText(introTextDefault);
		}
	};
	
	const changeTextVariant = (text) => {
		setIntroText(text);
	};
	
	useEffect(() => {
		if (offer) {
			setTabCount(offer.offer_variants.length);
		}
	}, [offer]);
	
	return (
		<div className="request-offer">
			{contextHolder}
			<Drawer
				title={offer ? t('request.updateOffer') : t('request.createOffer')}
				width={1024}
				onClose={() => onClose(false)}
				open={open}
				className="request-offer-drawer"
				extra={
					<Space>
						{ items.length > 0 &&
							<Button type="primary" onClick={() => form.submit()}>
								{ offer ? (
									<span>{t('request.updateOffer')}</span>
								) : (
									<span>{t('request.createOffer')}</span>
								)}
							</Button>
						}
					</Space>
				}
			>
				<Card
					title={t('request.summary')}
					size="small"
				>
					<Row gutter={12}>
						{ (arrival || departure) &&
							<Col span={12}>
								<p><b>{t('request.arrivalDeparture')}:</b> {arrival + ' - ' + departure}</p>
							</Col>
						}
						{ roomCode &&
							<Col span={12}>
								<p><b>{t('request.room')}:</b> {rooms.find(roomObject => roomObject.code?.toLowerCase() === roomCode.toLowerCase()) ? rooms.find(roomObject => roomObject.code?.toLowerCase() === roomCode.toLowerCase()).label : roomCode}</p>
							</Col>
						}
						{ adults &&
							<Col span={12}>
								<p><b>{t('request.adults')}:</b> {adults}</p>
							</Col>
						}
						{ children > 0 &&
							<Col span={12}>
								<p><b>{t('request.children')}:</b> {children + (childrenAges ? (' (' + childrenAges + ' ' + t('request.years') + ')') : '')}</p>
							</Col>
						}
						{ mealPlan &&
							<Col span={12}>
								<p><b>{t('request.mealPlan')}:</b> {mealPlans(t, i18n.resolvedLanguage).find(plan => plan.value === mealPlan)?.label ? mealPlans(t, i18n.resolvedLanguage).find(plan => plan.value === mealPlan)?.label : mealPlan}</p>
							</Col>
						}
						{ language &&
							<Col span={12}>
								<p><b>{t('request.language')}:</b> <LanguageFlag language={language} /></p>
							</Col>
						}
						{ comment &&
							<Col span={24}>
								<p><b>{t('request.comment')}:</b> {comment}</p>
							</Col>
						}
					</Row>
				</Card>
				<Divider />
				<Form
					form={form}
					layout="vertical"
					onFinish={onSubmit}
					onFinishFailed={onError}
					requiredMark={false}
					initialValues={initialValues()}
				>
					<div className="available-container">
						<Checkbox
							onChange={onChangeAvailable}
							checked={!available}
						>
							{t('request.offerNotAvailable')}
						</Checkbox>
					</div>
					<Divider />
					<div className="text-container">
						<div className="label df jb">
							{t('request.introText')}
							{ available &&
								<div className="text-variants">
									<Button type="link" onClick={() => changeTextVariant(introTextDefault)}>{t('content.lp.general.text')} 1</Button>
									<Button type="link" onClick={() => changeTextVariant(introTextDefault2)}>{t('content.lp.general.text')} 2</Button>
									<Button type="link" onClick={() => changeTextVariant(introTextDefault3)}>{t('content.lp.general.text')} 3</Button>
								</div>
							}
						</div>
						<TextEditor
							form={form}
							language={language}
							onChange={(value) => setIntroText(value)}
							initialValue={available ? introText : introTextDefaultNotAvailable}
						/>
					</div>
					<Divider />
					{ available &&
						<Tabs
							type="editable-card"
							onChange={onChange}
							activeKey={activeKey}
							onEdit={onEdit}
							items={items}
						/>
					}
				</Form>
			</Drawer>
		</div>
	);
}

function TabContent ({ index, rooms, room, form, adults, children, mealPlan, currentPrices, language, arrival, departure }) {
	const { RangePicker } = DatePicker;
	const { t,i18n } = useTranslation();
	const { accessToken, user } = useAuth();
	const initialPrices = () => {
		if (currentPrices) {
			let priceItems = [];
			currentPrices.forEach((price, priceIndex) => {
				priceItems.push({
					key: (priceIndex + 1),
				});
			});
			return priceItems;
		} else {
			return [
				{
					key: 1,
				},
			];
		}
	};
	
	const [freeRooms, setFreeRooms] = useState([]);
	const [roomPrices, setRoomPrices] = useState([]);
	const [freeRoomsFetched, setFreeRoomsFetched] = useState(false);
	const [roomPricesFetched, setRoomPricesFetched] = useState(false);
	const [prices, setPrices] = useState(initialPrices());
	const [count, setCount] = useState(initialPrices().length);
	const [arrivalDate, setArrivalDate] = useState(arrival);
	const [departureDate, setDepartureDate] = useState(departure);
	const [roomCode, setRoomCode] = useState(room);
	
	const fetchFreeRooms = useCallback(async(arrival, departure) => {
		if (!arrival || !departure) {
			setFreeRooms([]);
			return;
		}
		try {
			const { data: response } = await axios.get(apiDomain() + 'rooms/freeRooms?startDate=' + arrival + '&endDate=' + dayjs(departure, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD'), {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setFreeRooms(response.roomCodes);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken]);
	
	const fetchRoomPrices = useCallback(async(room, arrival, departure) => {
		if (!arrival || !departure || !room) {
			setRoomPrices([]);
			return;
		}
		try {
			const { data: response } = await axios.get(apiDomain() + 'rooms/roomPrices?roomCode=' + room + '&startDate=' + arrival + '&endDate=' + dayjs(departure, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD'), {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setRoomPrices(response.roomPrices);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken]);
	
	useEffect(() => {
		if (!freeRoomsFetched && (user && user.asaFreeRooms === 1)) {
			fetchFreeRooms(arrivalDate, departureDate);
			setFreeRoomsFetched(true);
		}
	}, [fetchFreeRooms, freeRoomsFetched, departureDate, arrivalDate, user]);
	
	useEffect(() => {
		if (!roomPricesFetched && (user && user.asaPrices === 1)) {
			fetchRoomPrices(rooms.find(roomObject => roomObject?.value === roomCode) ? rooms.find(roomObject => roomObject?.value === roomCode).code : '', arrivalDate, departureDate);
			setRoomPricesFetched(true);
		}
	}, [fetchRoomPrices, roomPricesFetched, roomCode, departureDate, arrivalDate, rooms, user]);
	
	useEffect(() => {
		let description = '';
		description = description + (rooms.find(roomObject => roomObject?.value === roomCode) ? rooms.find(roomObject => roomObject?.value === roomCode)['label' + language?.toUpperCase()] : (rooms.length ? rooms[0]['label' + language?.toUpperCase()] : ''));
		if (adults) {
			description = description + (description !== '' ? ' - ' : '') + adults + ' ' + t('request.adults', { lng: language });
		}
		if (children) {
			description = description + (description !== '' ? ' - ' : '') + children + ' ' + t('request.children', { lng: language });
		}
		if (mealPlan) {
			description = description + (description !== '' ? ' - ' : '') + mealPlans(t, language).find(plan => plan.value === mealPlan).label;
		}
		
		if (currentPrices) {
			currentPrices?.forEach((price, priceIndex) => {
				form.setFieldsValue({
					['variant' + index + 'priceDescription' + (priceIndex + 1)]: price.description,
					['variant' + index + 'priceQuantity' + (priceIndex + 1)]: price.quantity,
					['variant' + index + 'priceSinglePrice' + (priceIndex + 1)]: price.singlePrice,
					['variant' + index + 'priceDiscount' + (priceIndex + 1)]: price.discount,
				});
			});
		} else {
			form.setFieldsValue({
				['variant' + index + 'priceDescription1']: description,
				['variant' + index + 'priceQuantity1']: 1,
				['variant' + index + 'priceSinglePrice1']: '',
				['variant' + index + 'priceDiscount1']: 0,
			});
		}
	}, [index,form,rooms,adults,children,mealPlan,roomCode,t,currentPrices,language]);
	
	const columns = [
		{
			title: t('request.prices.description'),
			dataIndex: 'priceDescription',
			width: '50%',
			editable: true,
			inputType: 'text',
		},
		{
			title: t('request.prices.quantity'),
			dataIndex: 'priceQuantity',
			width: '5%',
			editable: true,
			inputType: 'number',
		},
		{
			title: t('request.prices.singlePrice'),
			dataIndex: 'priceSinglePrice',
			width: '15%',
			editable: true,
			inputType: 'price',
		},
		{
			title: t('request.prices.discount'),
			dataIndex: 'priceDiscount',
			width: '15%',
			editable: true,
			inputType: 'discount',
		},
		{
			title: t('request.prices.actions'),
			dataIndex: 'operation',
			width: '15%',
			render: (_, record) => {
				if (record.key <= 1) {
					return '';
				}
				return (
					<div className="delete-container">
						<Typography.Link onClick={() => deleteRow(record.key)}>{t('request.prices.delete')}</Typography.Link>
					</div>
				);
			},
		},
	];
	
	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: col.inputType,
				dataIndex: col.dataIndex,
				title: col.title,
				editing: true,
				variant: index,
			}),
		};
	});
	
	const deleteRow = (key) => {
		const newData = prices.filter((item) => item.key !== key);
		setPrices(newData);
	};
	
	const addRow = () => {
		const newData = {
			key: (count + 1),
			priceDescription: '',
			priceQuantity: 1,
			priceSinglePrice: '',
			priceDiscount: 0,
		};
		setCount(count + 1);
		setPrices([...prices, newData]);
		
		form.setFieldsValue({
			['variant' + index + 'priceDescription' + (count + 1)]: '',
			['variant' + index + 'priceQuantity' + (count + 1)]: 1,
			['variant' + index + 'priceSinglePrice' + (count + 1)]: '',
			['variant' + index + 'priceDiscount' + (count + 1)]: 0,
		});
	};
	
	const disabledDate = (current) => {
		return current && current < dayjs().endOf('day');
	};
	
	const onDateChanged = (dates) => {
		if (dates[0] && dates[1]) {
			setArrivalDate(dayjs(dates[0]).format('YYYY-MM-DD'));
			setDepartureDate(dayjs(dates[1]).format('YYYY-MM-DD'));
			if (user && user.asaFreeRooms === 1) {
				fetchFreeRooms(dayjs(dates[0]).format('YYYY-MM-DD'), dayjs(dates[1]).format('YYYY-MM-DD'));
			}
		}
		if (user && user.asaPrices === 1) {
			fetchRoomPrices(rooms.find(roomObject => roomObject?.value === roomCode).code, dayjs(dates[0]).format('YYYY-MM-DD'), dayjs(dates[1]).format('YYYY-MM-DD'));
		}
	};
	
	const onRoomChange = (roomId) => {
		setRoomCode(roomId);
		if (user && user.asaPrices === 1) {
			fetchRoomPrices(rooms.find(roomObject => roomObject?.value === roomId).code, arrivalDate, departureDate);
		}
	};
	
	return (
		<div className="tab-content" key={index}>
			<Row gutter={0}>
				<Col span={24}>
					<Form.Item
						name={'date' + index}
						label={t('request.arrival') + '/' + t('request.departure')}
						rules={[
							{
								required: true,
								message: t('request.dateError'),
							},
						]}
					>
						<RangePicker
							format="DD/MM/YYYY"
							disabledDate={disabledDate}
							onCalendarChange={(dates) => onDateChanged(dates)}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={12}>
				<Col span={12}>
					<Form.Item
						name={'room' + index}
						label={t('request.room')}
						rules={[
							{
								required: true,
								message: t('request.roomError'),
							},
						]}
					>
						<Select
							showSearch
							optionFilterProp="label"
							options={(user && user.asaFreeRooms === 1) ? getOptionsWithState(rooms, freeRooms || [], t) : rooms}
							notFoundContent={<Empty description={t('request.roomEmpty')}/>}
							onChange={(value) => onRoomChange(value)}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name={'mealPlan' + index}
						label={t('request.mealPlan')}
						rules={[
							{
								required: true,
								message: t('request.mealPlanError'),
							},
						]}
					>
						<Select
							showSearch
							optionFilterProp="label"
							options={mealPlans(t, i18n.resolvedLanguage)}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={12}>
				<Col span={12}>
					<Form.Item
						name={'adults' + index}
						label={t('request.adults')}
					>
						<Select
							options={numberSelect(10)}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name={'children' + index}
						label={t('request.children')}
					>
						<Select
							options={numberSelect(10)}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={0}>
				<Col span={24}>
					<Space direction="vertical" size="middle">
						<div className="label">{t('request.prices.prices')}</div>
						{( user && user.asaPrices === 1 && roomPrices && roomPrices.length && getFromToDate(arrivalDate, dayjs(departureDate, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD')).length > 0) &&
							<div className="asa-prices">
								<div className="dates">
									<div className="heading"><b>{t('request.date')}</b></div>
									{getFromToDate(arrivalDate, dayjs(departureDate, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD')).map((day, indexDay) => (
										<div key={indexDay}>{day}</div>
									))}
									<div className="total"><b>{t('request.prices.total')}</b></div>
								</div>
								{roomPrices.map((ratePlan) => (
									<div className="rateplan" key={ratePlan.ratePlanCode}>
										<div>{ratePlan.ratePlanCode}</div>
										{getFromToDate(arrivalDate, dayjs(departureDate, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD')).map((day, indexDay) => (
											<div key={indexDay}>{ratePlan.dates.find(ratePlanDay => dayjs(ratePlanDay.date, 'YYYY-MM-DD').format('DD/MM/YYYY') === day) ? formatToPrice(ratePlan.dates.find(ratePlanDay => dayjs(ratePlanDay.date, 'YYYY-MM-DD').format('DD/MM/YYYY') === day).price) : '/'}</div>
										))}
										<div className="total">{getTotalPriceOfRateplan(ratePlan)}</div>
									</div>
								))}
							</div>
						}
						<Table
							components={{
								body: {
									cell: EditableCell,
								},
							}}
							bordered
							dataSource={prices}
							columns={mergedColumns}
							rowClassName="editable-row"
							pagination={false}
						/>
						<Button
							onClick={addRow}
							type="primary"
							icon={<PlusOutlined />}
						>
							{t('request.prices.addRow')}
						</Button>
					</Space>
				</Col>
			</Row>
		</div>
	);
}

const EditableCell = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	variant,
	...restProps
}) => {
	let inputNode = <Input />;
	switch (inputType) {
		case 'number':
			inputNode = <InputNumber min={0} controls={false} decimalSeparator=","/>;
			break;
		case 'price':
			inputNode = <InputNumber min={0} controls={false} decimalSeparator="," addonAfter="€"/>;
			break;
		case 'discount':
			inputNode = <InputNumber min={0} controls={false} decimalSeparator="," addonAfter="%"/>;
			break;
		default:
			inputNode = <Input />;
			break;
	}
	const { t } = useTranslation();
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={'variant' + variant + dataIndex + record.key}
					style={{
						margin: 0,
					}}
					rules={[
						{
							required: true,
							message: t('general.form.requiredField'),
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

export default RequestOffer;