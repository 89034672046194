import React, { useEffect, useState } from 'react';
import { apiDomain, findTranslationOrFallback } from 'utils/constants';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import { IconPrevNext } from 'utils/svgs.jsx';
import { getTranslation } from 'pages/offer/translations/translations';
import { getVariantDescription } from 'pages/offer/helpers/helpers';
import { useTranslation } from 'react-i18next';
import Headline from 'pages/offer/components/core/headline';
import PreHeadline from 'pages/offer/components/core/preheadline';
import Text from 'pages/offer/components/core/text';
import Button from 'pages/offer/components/core/button';
import Icon from 'pages/offer/components/core/icon';
import PriceTable from 'pages/offer/components/core/price-table';
import moment from 'moment';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import '@splidejs/react-splide/css';
import './index.scss';

function ModuleRoomT1 ({ offer, content }) {
	const [headline, setHeadline] = useState([]);
	const [description, setDescription] = useState([]);
	const [dates, setDates] = useState('');
	const [roomImages, setRoomImages] = useState([]);
	const [roomSketches, setRoomSketches] = useState([]);
	const [roomDescription, setRoomDescription] = useState([]);
	const [roomPrices, setRoomPrices] = useState([]);
	const { t } = useTranslation();
	
	const openSketch = () => {
		document.querySelectorAll('.room-t1 .lg-react-element.sketches a')[0].click();
	};
	
	const openLightbox = () => {
		document.querySelectorAll('.room-t1 .lg-react-element.roomImages a')[0].click();
	};
	
	useEffect(() => {
		let lang = offer.language.toUpperCase();
		let variant = offer.offer_variants?.filter((elem) => elem.id === offer.bookedVariant)[0];
		
		let name = findTranslationOrFallback(variant.room?.roomTranslations, 'name', lang);
		let size = variant.room?.size;
		
		if (name && size) {
			setHeadline(getTranslation('variants', 'roomHeadlineWithSize', offer.language, {
				'name' : name,
				'size' : size,
			}));
		} else {
			setHeadline(name);
		}
		
		setDescription(getVariantDescription(variant, offer.language, t));
		setRoomImages(variant.room?.images);
		setDates(moment(variant.arrivalDate).format('DD.MM.YYYY') + ' - ' + moment(variant.departureDate).format('DD.MM.YYYY'));
		setRoomSketches(variant.room?.sketches);
		setRoomDescription(findTranslationOrFallback(variant.room?.roomTranslations, 'description', lang));
		setRoomPrices(variant.offer_variants_prices);
	}, [offer,t]);
	
	return (
		<div className="room-t1">
			<div className="centercontentlargest">
				<div className="container bgteaser">
					<div className="centercontent1047">
						<div className="element">
							<div className="content top">
								<PreHeadline
									class={'preheadline t2'}
									text={getTranslation('roomt1', 'yourReservation', offer.language)}
								/>
								{ headline &&
									<Headline
										class={'headline t3'}
										text={ headline }
									/>
								}
								{ dates &&
									<Text
										class={'text'}
										text={ dates }
									/>
								}
								{ description &&
									<Text
										class={'text'}
										text={ description }
									/>
								}
							</div>
							{ roomImages.length > 0 &&
								<div className="gallery">
									<Splide
										hasTrack={false}
										options={{
											type: 'loop',
											perPage: 1,
											pagination: false,
											arrows: true,
										}}
									>
										<SplideTrack>
											{ roomImages &&
												roomImages.map((img, i) => {
													return (
														<SplideSlide key={i}>
															<div className="image">
																<img src={img.path ? (apiDomain() + img.path) : img.externalPath} key={img.name} alt={img.name} />
															</div>
														</SplideSlide>
													);
												})
											}
										</SplideTrack>
										<div className="splide__arrows">
											<button className="splide__arrow splide__arrow--prev">
												<IconPrevNext />
											</button>
											<button className="splide__arrow splide__arrow--next">
												<IconPrevNext />
											</button>
										</div>
									</Splide>
									{ roomSketches.length > 0 &&
										<div className="sketch">
											<Button
												onClick={openSketch}
												class="button1"
												text={getTranslation('variants', 'roomSketch', offer.language)}
											/>
										</div>
									}
									<div className="fullscreen" onClick={() => openLightbox()}>
										<Icon
											icon="fullscreen"
											width="32"
											height="32"
											color="#FFFFFF"
										/>
									</div>
									<LightGallery
										speed={500}
										elementClassNames={'roomImages'}
										licenseKey="5D655E39-81DB-4AB2-BE9F-504D8E7C591D"
									>
										{ roomImages &&
											roomImages.map((img, i) => {
												return (
													<a key={i} href={img.path ? (apiDomain() + img.path) : img.externalPath}>
														<img alt="" src={img.path ? (apiDomain() + img.path) : img.externalPath} />
													</a>
												);
											})
										}
									</LightGallery>
									<LightGallery
										speed={500}
										elementClassNames={'sketches'}
										licenseKey="5D655E39-81DB-4AB2-BE9F-504D8E7C591D"
									>
										{ roomSketches &&
											roomSketches.map((img, i) => {
												return (
													<a key={i} href={img.path ? (apiDomain() + img.path) : img.externalPath}>
														<img alt="" src={img.path ? (apiDomain() + img.path) : img.externalPath} />
													</a>
												);
											})
										}
									</LightGallery>
								</div>
							}
							{ (roomDescription || roomPrices) &&
								<div className="content bottom">
									{ roomDescription &&
										<Text text={roomDescription} />
									}
									{ roomPrices &&
										<>
											<div className="price-table-title">
												<Headline
													class={'title t3'}
													text={getTranslation('roomt1', 'followingBookingWasMade', offer.language)}
												/>
											</div>
											<PriceTable
												prices={roomPrices}
												lang={offer.language}
											/>
										</>
									}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModuleRoomT1;