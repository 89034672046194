import React, { useEffect, useState } from 'react';
import { apiDomain, findTranslationOrFallback } from 'utils/constants';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import { IconPrevNext } from 'utils/svgs.jsx';
import { getTranslation } from 'pages/offer/translations/translations';
import { getVariantDescription, getVariantTotalPrice } from 'pages/offer/helpers/helpers';
import { useTranslation } from 'react-i18next';
import Headline from 'pages/offer/components/core/headline';
import PreHeadline from 'pages/offer/components/core/preheadline';
import Text from 'pages/offer/components/core/text';
import Button from 'pages/offer/components/core/button';
import Link from 'pages/offer/components/core/link';
import Icon from 'pages/offer/components/core/icon';
import PriceTable from 'pages/offer/components/core/price-table';
import moment from 'moment';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import '@splidejs/react-splide/css';
import './index.scss';

function ModuleVariantsT2 ({ offer, onSelectVariant }) {
	const [items, setItems] = useState([]);
	const { t } = useTranslation();
	
	const openSketch = () => {
		document.querySelectorAll('.module-variants-t2 .elements .element.active .lg-react-element.sketches a')[0].click();
	};
	
	const openLightbox = () => {
		document.querySelectorAll('.module-variants-t2 .elements .element.active .lg-react-element.roomImages a')[0].click();
	};
	
	const setTabActive = (e) => {
		const tab = document.querySelectorAll('.module-variants-t2 .tabs .tab[data-id="' + e.target.getAttribute('data-id') + '"]');
		const item = document.querySelectorAll('.module-variants-t2 .elements .element[data-id="' + e.target.getAttribute('data-id') + '"]');
		
		if (tab) {
			tab.forEach((e) => {
				e.classList.add('active');
				[...e.parentNode.children].filter((child) => child !== e).forEach((a) => {
					a.classList.remove('active');
				});
			});
			
			item.forEach((e) => {
				e.classList.add('active');
				[...e.parentNode.children].filter((child) => child !== e).forEach((a) => {
					a.classList.remove('active');
				});
			});
		}
	};
	
	useEffect(() => {
		let variants = [];
		let lang = offer.language.toUpperCase();
		
		if (offer['offer_variants']) {
			variants = offer.offer_variants.map((element, i) => {
				let headline = '';
				let name = findTranslationOrFallback(element.room?.roomTranslations, 'name', lang);
				let size = element.room?.size;
				let date = moment(element.arrivalDate).format('DD.MM.YYYY') + ' - ' + moment(element.departureDate).format('DD.MM.YYYY');
				
				if (name && size) {
					headline = getTranslation('variants', 'roomHeadlineWithSize', offer.language, {
						'name' : name,
						'size' : size,
					});
				} else {
					headline = name;
				}
				return {
					index : i + 1,
					id : element.id,
					headline : headline,
					description : getVariantDescription(element, offer.language, t),
					price : getVariantTotalPrice(element.offer_variants_prices),
					prices : element.offer_variants_prices,
					roomImages : element.room?.images,
					sketches : element.room?.sketches,
					date,
					roomContent : {
						headline: findTranslationOrFallback(element.room?.roomTranslations, 'headline', lang),
						subheadline: findTranslationOrFallback(element.room?.roomTranslations, 'subheadline', lang),
						description: findTranslationOrFallback(element.room?.roomTranslations, 'description', lang),
						size: element.room?.size,
					},
				};
			});
		}
		setItems(variants);
	}, [offer,t]);
	
	return (
		<div className="module-variants-t2" id="module-variants-t2">
			<div className="centercontentlargest">
				<div className="container bgteaser">
					<div className="centercontent1470">
						<div className="tabs">
							{ items && 
								items.map((elem, i) => {
									return (
										<Button
											dataId={elem.id}
											key={i}
											onClick={setTabActive}
											class={`tab button1 small${ i === 0 ? ' active' : ''}`}
											text={getTranslation('variants', 'variant', offer.language, { 'index' : elem.index })}
										/>
									);
								})
							}
						</div>
					</div>
					<div className="centercontent1047">
						<div className="elements">
							{ items && 
								items.map((elem, i) => {
									return (
										<div key={ i } className={`element${ i === 0 ? ' active' : ''}`} data-id={elem.id}>
											<div className="content top">
												<Headline
													class={'headline t1'}
													text={elem.headline}
												/>
												<Text class="date" text={elem.date} />
												<Text text={elem.description} />
											</div>
											<div className="gallery">
												<Splide
													hasTrack={false}
													options={{
														type: 'loop',
														perPage: 1,
														pagination: false,
														arrows: true,
													}}
												>
													<SplideTrack>
														{ elem.roomImages &&
															elem.roomImages.map((img, i) => {
																return (
																	<SplideSlide key={i}>
																		<div className="image">
																			<img src={img.path ? (apiDomain() + img.path) : img.externalPath} key={img.name} alt={img.name} />
																		</div>
																	</SplideSlide>
																);
															})
														}
													</SplideTrack>
													<div className="splide__arrows">
														<button className="splide__arrow splide__arrow--prev">
															<IconPrevNext />
														</button>
														<button className="splide__arrow splide__arrow--next">
															<IconPrevNext />
														</button>
													</div>
												</Splide>
												{ elem.sketches.length > 0 &&
													<div className="sketch">
														<Button
															onClick={openSketch}
															class="button1"
															text={getTranslation('variants', 'roomSketch', offer.language)}
														/>
													</div>
												}
												<div className="fullscreen" onClick={() => openLightbox()}>
													<Icon
														icon="fullscreen"
														width="32"
														height="32"
														color="#FFFFFF"
													/>
												</div>
												<LightGallery
													speed={500}
													elementClassNames={'roomImages'}
													licenseKey="5D655E39-81DB-4AB2-BE9F-504D8E7C591D"
												>
													{ elem.roomImages &&
														elem.roomImages.map((img, i) => {
															return (
																<a key={i} href={img.path ? (apiDomain() + img.path) : img.externalPath}>
																	<img alt="" src={img.path ? (apiDomain() + img.path) : img.externalPath} />
																</a>
															);
														})
													}
												</LightGallery>
												<LightGallery
													speed={500}
													elementClassNames={'sketches'}
													licenseKey="5D655E39-81DB-4AB2-BE9F-504D8E7C591D"
												>
													{ elem.sketches &&
														elem.sketches.map((img, i) => {
															return (
																<a key={i} href={img.path ? (apiDomain() + img.path) : img.externalPath}>
																	<img alt="" src={img.path ? (apiDomain() + img.path) : img.externalPath} />
																</a>
															);
														})
													}
												</LightGallery>
											</div>
											<div className="content bottom">
												<PreHeadline text={elem.roomContent.subheadline} />
												<Headline class="headline t3" text={elem.roomContent.headline} />
												<Text text={elem.roomContent.description} />
												<div className="price-table-scroll"></div>
												<PriceTable
													prices={elem.prices}
													lang={offer.language}
												/>
												<Link
													onClick={() => onSelectVariant(elem)}
													text={getTranslation('variants', 'toBooking', offer.language)} />
												<Text
													class={'validity'}
													text={getTranslation('variants', 'validityPeriod', offer.language)}
												/>
											</div>
										</div>
									);
								})
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModuleVariantsT2;