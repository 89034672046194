import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { apiDomain, findTranslationOrFallback } from 'utils/constants';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import { IconPrevNext } from 'utils/svgs.jsx';
import { getTranslation } from 'pages/offer/translations/translations';
import { useInView } from 'react-intersection-observer';
import Headline from 'pages/offer/components/core/headline';
import Link from 'pages/offer/components/core/link';
import Icon from 'pages/offer/components/core/icon';
import Text from 'pages/offer/components/core/text';
import '@splidejs/react-splide/css';
import './index.scss';

function ModuleHeaderT1 ({ content, offer }) {
	let lang = offer.language.toUpperCase();
	
	const [images, setImages] = useState([]);
	const [headline, setHeadline] = useState([]);
	const [contactPersonHeadline1, setContactPersonHeadline1] = useState([]);
	const [contactPersonHeadline2, setContactPersonHeadline2] = useState([]);
	
	const { ref, inView } = useInView({});
	
	const scrollToOffer = () => {
		let element = document.getElementById('module-variants-t1');
		if (!element) {
			element = document.getElementById('module-variants-t2');
		}
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};
	
	const openHeaderContactPerson = () => {
		const element = document.querySelector('.module-header-t1 .contact-person');
		const textLong = document.querySelector('.module-header-t1 .contact-person .text.long');
		
		if (textLong) {
			element.classList.toggle('active');
		}
	};
	
	useEffect(() => {
		setImages(content.contentImages?.filter(image => image.moduleId === 'headert1').sort((a, b) => {
			if (a.order === null) {
				return 1;
			}
			if (b.order === null) {
				return -1;
			}
			if (a.order === b.order) {
				return 0;
			}
			return a.order < b.order ? -1 : 1;
		}));
		
		let from = moment(offer.dateFrom);
		let to = moment(offer.dateTo);
		let daysBetween = from.diff(to, 'days') * -1;
		let key = (daysBetween === 1) ? 'accommodation' : 'accommodations';
		
		key = (offer.gender === 'M') ? 'titleM' : 'titleF';
		
		setHeadline(getTranslation('header', key, offer.language, {
			'name' : offer.lastName,
		}));
		
		setContactPersonHeadline1(findTranslationOrFallback(offer.hotelInfos?.translations, 'contactPersonShortIntro1', lang));
		setContactPersonHeadline2(findTranslationOrFallback(offer.hotelInfos?.translations, 'contactPersonShortIntro2', lang));
	}, [content, offer, lang]);
	
	return (
		<>
			<div className="module-header-t1" ref={ref}>
				{ (offer.hotelInfos?.imageContactPersonPath || (contactPersonHeadline1 && contactPersonHeadline2)) &&
					<div className="contact-person bghighlight" onClick={ openHeaderContactPerson }>
						{ offer.hotelInfos?.imageContactPersonPath &&
							<div className="image">
								<img src={apiDomain() + offer.hotelInfos.imageContactPersonPath} alt="" />
							</div>
						}
						{ contactPersonHeadline1 && contactPersonHeadline2 &&
							<>
								<div className="text short">
									{ contactPersonHeadline1 }
								</div>
								<div className="text long">
									<div>
										{ contactPersonHeadline2 }
									</div>
									{ offer.hotelInfos?.whatsapp &&
										<div className="item item-whatsapp">
											<a className="df jc" rel="noreferrer" target="_blank" href={'https://api.whatsapp.com/send?phone=' + offer.hotelInfos.whatsapp}>
												<div className="icon">
													<Icon icon="whatsapp" />
												</div>
											</a>
										</div>
									}
									{ offer.hotelInfos?.phone &&
										<div className="item item-phone">
											<a href={'tel: ' + offer.hotelInfos.phone}>
												<Text text={ offer.hotelInfos.phone } />
											</a>
										</div>
									}
									{ offer.hotelInfos?.email &&
										<div className="item item-email">
											<a href={'mailto: ' + offer.hotelInfos.email}>
												<Text text={ offer.hotelInfos.email } />
											</a>
										</div>
									}
								</div>
							</>
						}
					</div>
				}
				{ offer.hotelInfos?.phone &&
					<div className="tel df ac" onClick={() => window.location = ('tel:' + offer.hotelInfos.phone)}>
						<Icon
							icon="tel"
							width="31"
							height="31"
						/>
						<div className="number t2">{offer.hotelInfos.phone}</div>
					</div>
				}
				<div className="center-content-large">
					<div className="gallery">
						<div className="logo">
							{ offer.hotelInfos?.logoHeaderPath &&
								<img src={apiDomain() + offer.hotelInfos.logoHeaderPath} alt="" />
							}
						</div>
						<Splide
							hasTrack={ false }
							options={{
								type: 'loop',
								perPage: 1,
								pagination: false,
								arrows: images && images.length > 1,
							}}
						>
							<SplideTrack>
								{ images &&
									images.map((img, i) => {
										return (
											<SplideSlide key={ i }>
												<div className="image">
													<img src={apiDomain() + img.path} key={img.name} alt={img.name} />
												</div>
											</SplideSlide>
										);
									})
								}
							</SplideTrack>
							<div className="splide__arrows">
								<button className="splide__arrow splide__arrow--prev">
									<IconPrevNext />
								</button>
								<button className="splide__arrow splide__arrow--next">
									<IconPrevNext />
								</button>
							</div>
							<div className="splide__content">
								<div className="centercontent1024 inner-text">
									<Headline
										class={ 'headline t1Large' }
										text={ headline }
									/>
									<Link
										class={'button1' + (offer.booked ? ' disabled' : '')}
										onClick={ scrollToOffer }
										text={ getTranslation('general', offer.booked ? (offer.bookingConfirmed ? 'bookingConfirmed' : 'bookingRequest') : 'toOffer', offer.language) }
									/>
								</div>
							</div>
						</Splide>
					</div>
				</div>
			</div>
			<div className={'sticky-header bgweb df ac' + (!inView ? ' show' : '')}>
				<div className="centercontent1470 df jb ac">
					<div className="booking">
						{ !offer.booked &&
							<Link
								onClick={() => document.querySelector('.module-variants-t2 .element.active .price-table-scroll').scrollIntoView({ behavior: 'smooth' })}
								text={getTranslation('variants', 'toBooking', offer.language)}
								class="button1 small"
							/>
						}
					</div>
					{ offer.hotelInfos?.phone &&
						<div className="tel df ac" onClick={() => window.location = ('tel:' + offer.hotelInfos.phone)}>
							<Icon
								icon="tel"
								width="31"
								height="31"
							/>
							<div className="number t2">{offer.hotelInfos.phone}</div>
						</div>
					}
				</div>
			</div>
		</>
	);
};

export default ModuleHeaderT1;