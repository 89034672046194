import Title from 'antd/es/typography/Title';
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import { Space, Button, Table } from 'antd';
import { apiDomain, RoutePaths } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { findTranslationOrFallback } from 'utils/constants';
import './index.scss';

function UspT1 ({ content, form, language }) {
	const { accessToken, user } = useAuth();
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const { t } = useTranslation();
	const parse = require('html-react-parser');
	
	const columns = [
		{
			title: t('content.lp.general.headline'),
			dataIndex: 'title',
			key: 'title',
			render: (text, record) => {
				return (
					<Button type="link" onClick={() => navigate(RoutePaths.content.usp.index + '/' + record.key, { state: { lang: language }})}>
						{text}
					</Button>
				);
			},
		},
		{
			title: t('content.lp.general.text'),
			dataIndex: 'text',
			key: 'text',
		},
		{
			title: t('content.lp.uspt1.actions'),
			dataIndex: 'uspId',
			key: 'uspId',
			render: (text, record) => {
				return (
					<Space size="middle">
						<Button type="primary" onClick={() => navigate(RoutePaths.content.usp.index + '/' + record.key, { state: { lang: language }})} icon={<SyncOutlined />}></Button>
						<Button type="primary" danger onClick={() => deleteUsp(record.key)} icon={<DeleteOutlined />}></Button>
					</Space>
				);
			},
		},
	];
	
	const deleteUsp = (key) => {
		axios.delete(apiDomain() + 'contentUsp/' + user.hotelId + '/' + key, {
			headers: {
				'x-access-token': accessToken,
			},
		}).then(response => {
			if (response.data.status === 'ok') {
				fetchData();
			}
		});
	};
	
	const fetchData = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'contentUsp/' + user.hotelId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				let usps = response.map(function(obj) {
					return {
						...obj,
						key: obj.id,
						title: findTranslationOrFallback(obj.contentUspTranslations, 'headline', language),
						text: parse(findTranslationOrFallback(obj.contentUspTranslations, 'text', language)),
					};
				});
				setData(usps);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken, user.hotelId, language, parse]);
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	return (
		<div className="usp-t1 block">
			<div className="headline-container">
				<Title level={4}>
					{t('menu.contentUsp')}
				</Title>
				<div className="right-content">
					<Button type="primary" onClick={() => navigate(RoutePaths.content.usp.index + '/', { state: { lang: language }})} icon={<PlusOutlined />}>
						{t('content.lp.uspt1.add')}
					</Button>
				</div>
			</div>
			<div className="fields">
				<Table
					dataSource={data}
					columns={columns}
					bordered={true}
					pagination={false}
				/>
			</div>
		</div>
	);
};

export default UspT1;