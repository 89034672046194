import React, { useEffect, useState } from 'react';
import { findTranslationOrFallback } from 'utils/constants';
import Headline from 'pages/offer/components/core/headline';
import PreHeadline from 'pages/offer/components/core/preheadline';
import Text from 'pages/offer/components/core/text';
import { getTranslation } from 'pages/offer/translations/translations';
import './index.scss';
import { formatToPrice } from 'utils/formats';
import { getVariantTotalPrice } from 'pages/offer/helpers/helpers';

function ModuleTextT1 ({ content, offer }) {
	const [anzahlung_headline, setAnzahlungHeadline] = useState([]);
	const [anzahlung_value, setAnzahlungValue] = useState([]);
	const [anzahlung_subHeadline, setAnzahlungSubHeadline] = useState([]);
	const [anzahlung_text, setAnzahlungText] = useState([]);
	const [gtk_headline, setGtkHeadline] = useState([]);
	const [gtk_subHeadline, setGtkSubHeadline] = useState([]);
	const [gtk_text, setGtkText] = useState([]);
	const [arrival_headline, setArrivalHeadline] = useState([]);
	const [arrival_subHeadline, setArrivalSubHeadline] = useState([]);
	const [arrival_text, setArrivalText] = useState([]);
	
	useEffect(() => {
		let lang = offer.language.toUpperCase();
		
		let anzahlung_headlineKey = 'textT1_anzahlung_headline';
		let anzahlung_valueKey = 'textT1_anzahlung_value';
		let anzahlung_subHeadlineKey = 'textT1_anzahlung_subheadline';
		let anzahlung_textKey = 'textT1_anzahlung_text';
		let gtk_headlineKey = 'textT1_gtk_headline';
		let gtk_subHeadlineKey = 'textT1_gtk_subheadline';
		let gtk_textKey = 'textT1_gtk_text';
		let arrival_headlineKey = 'textT1_arrival_headline';
		let arrival_subHeadlineKey = 'textT1_arrival_subheadline';
		let arrival_textKey = 'textT1_arrival_text';
		
		let variant = offer.offer_variants?.filter((elem) => elem.id === offer.bookedVariant)[0];
		
		setAnzahlungHeadline(findTranslationOrFallback(content.translations, anzahlung_headlineKey, lang));
		
		let anzahlungFinalValue = getVariantTotalPrice(variant.offer_variants_prices);
		let anzahlungProcent = findTranslationOrFallback(content.translations, anzahlung_valueKey, 'DE');
		
		if (anzahlungFinalValue > 0 && !isNaN(anzahlungProcent)) {
			anzahlungFinalValue = getTranslation('booking', 'anzahlungText', offer.language) + ' ' + formatToPrice(anzahlungFinalValue * (anzahlungProcent / 100));
		} else {
			anzahlungFinalValue = '';
		}
		
		setAnzahlungValue(anzahlungFinalValue);
		setAnzahlungSubHeadline(findTranslationOrFallback(content.translations, anzahlung_subHeadlineKey, lang));
		setAnzahlungText(findTranslationOrFallback(content.translations, anzahlung_textKey, lang));
		setGtkHeadline(findTranslationOrFallback(content.translations, gtk_headlineKey, lang));
		setGtkSubHeadline(findTranslationOrFallback(content.translations, gtk_subHeadlineKey, lang));
		setGtkText(findTranslationOrFallback(content.translations, gtk_textKey, lang));
		setArrivalHeadline(findTranslationOrFallback(content.translations, arrival_headlineKey, lang));
		setArrivalSubHeadline(findTranslationOrFallback(content.translations, arrival_subHeadlineKey, lang));
		setArrivalText(findTranslationOrFallback(content.translations, arrival_textKey, lang));
	}, [content, offer]);
	
	return (
		<>
			{((anzahlung_headline || anzahlung_subHeadline || anzahlung_text) || (gtk_headline || gtk_subHeadline || gtk_text) || (arrival_headline || arrival_subHeadline || arrival_text)) &&
				<div className="text-t1">
					<div className="centercontentlargest">
						<div className="container bgteaser">
							<div className="centercontent1470">
								{offer.bookingConfirmed && (anzahlung_headline || gtk_subHeadline || gtk_text) &&
									<div className="text-container">
										{anzahlung_subHeadline &&
											<PreHeadline
												class={ 'preheadline t2' }
												text={ anzahlung_subHeadline }
											/>
										}
										{anzahlung_headline &&
											<Headline
												class={ 'headline t1' }
												text={ anzahlung_headline }
											/>
										}
										{anzahlung_value &&
											<Headline
												class={ 'value t2' }
												text={ anzahlung_value }
											/>
										}
										{anzahlung_text &&
											<Text
												class={ 'text' }
												text={ anzahlung_text }
											/>
										}
									</div>
								}
								{(gtk_headline || gtk_subHeadline || gtk_text) &&
									<div className="text-container">
										{gtk_subHeadline &&
											<PreHeadline
												class={ 'preheadline t2' }
												text={ gtk_subHeadline }
											/>
										}
										{gtk_headline &&
											<Headline
												class={ 'headline t1' }
												text={ gtk_headline }
											/>
										}
										{gtk_text &&
											<Text
												class={ 'text' }
												text={ gtk_text }
											/>
										}
									</div>
								}
								{(arrival_headline || gtk_subHeadline || arrival_text) &&
									<div className="text-container">
										{arrival_subHeadline &&
											<PreHeadline
												class={ 'preheadline t2' }
												text={ arrival_subHeadline }
											/>
										}
										{arrival_headline &&
											<Headline
												class={ 'headline t1' }
												text={ arrival_headline }
											/>
										}
										{arrival_text &&
											<Text
												class={ 'text' }
												text={ arrival_text }
											/>
										}
										{offer.hotelInfos.gmapUrl &&
											<div className="map">
												<iframe src={offer.hotelInfos.gmapUrl} title={'map'} frameBorder={0}></iframe>
											</div>
										}
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
};

export default ModuleTextT1;