import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'translations/locales/en.json';
import de from 'translations/locales/de.json';
import it from 'translations/locales/it.json';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		whitelist: ['de', 'en', 'it'],
		fallbackLng: 'de',
		resources: {
			en: {
				translation: en,
			},
			de: {
				translation: de,
			},
			it: {
				translation: it,
			},
		},
		interpolation: {
			escapeValue: false,
		},
	});
export default i18n;