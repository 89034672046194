import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import ConfigWrapper from 'config-wrapper';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppContextProvider } from 'utils/appContextProvider';
import Layout from './layout';
import 'translations/i18n';
import dayjs from 'dayjs';
import deDE from 'antd/locale/de_DE';
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/de';
import 'antd/dist/reset.css';

dayjs.locale('de');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ConfigWrapper>
			<ConfigProvider
				locale={deDE}
				theme={{
					token: {
						colorPrimary: '#205295',
					},
				}}
			>
				<AppContextProvider>
					<Router>
						<Layout />
					</Router>
				</AppContextProvider>
			</ConfigProvider>
		</ConfigWrapper>
	</React.StrictMode>
);