import React, { useEffect, useState } from 'react';
import { findTranslationOrFallback } from 'utils/constants';
import { getTranslation } from 'pages/offer/translations/translations';
import Headline from 'pages/offer/components/core/headline';
import Text from 'pages/offer/components/core/text';
import './index.scss';

function ModuleAGBT1 ({ offer, customAgb, fallbackAgb }) {
	const [text, setText] = useState([]);
	
	useEffect(() => {
		let lang = offer.language.toUpperCase();
		let customText = '';
		let fallbackText = '';
		
		if (customAgb?.translations) {
			customText = findTranslationOrFallback(customAgb.translations, 'agbReplaceText', lang);
		}
		if (fallbackAgb?.translations) {
			fallbackText = findTranslationOrFallback(fallbackAgb.translations, 'text', lang);
		}
		
		if (customText) {
			setText(customText);
		} else {
			setText(fallbackText);
		}
	}, [offer, customAgb, fallbackAgb]);
	
	return (
		<>
			{ text &&
				<div className="agb-t1">
					<div className="centercontentlargest">
						<div className="container bgteaser">
							<div className="centercontent1470">
								<div className="text-container">
									<Headline
										class={ 'headline t1' }
										text={ getTranslation('agbst1', 'headline', offer.language) }
									/>
									<Text
										class={ 'text' }
										text={ text }
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
};

export default ModuleAGBT1;