function Text (props) {
	return (
		<div className={ props.class }>
			<span dangerouslySetInnerHTML={{ __html: props.text }} />
		</div>
	);
}

Text.defaultProps = {
	class: 'text',
	text: '',
};

export default Text;