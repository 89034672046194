import { Col, Divider, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import TextEditor from 'components/forms/text-editor';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { findTranslationOrFallback, languages } from 'utils/constants';
import LanguageInput from 'components/forms/language-input';

function IntroT1 ({ content, form, language, type }) {
	const { t } = useTranslation();
	
	useEffect(() => {
		languages.forEach(lang => {
			form.setFieldValue(('introT1_subheadline' + lang.key), findTranslationOrFallback(content.translations, 'introT1_subheadline', lang.key));
			form.setFieldValue(('introT1_headline' + lang.key), findTranslationOrFallback(content.translations, 'introT1_headline', lang.key));
			form.setFieldValue(('introT1_subheadline_booked' + lang.key), findTranslationOrFallback(content.translations, 'introT1_subheadline_booked', lang.key));
			form.setFieldValue(('introT1_headline_booked' + lang.key), findTranslationOrFallback(content.translations, 'introT1_headline_booked', lang.key));
			form.setFieldValue(('introT1_subheadline_bookingConfirmed' + lang.key), findTranslationOrFallback(content.translations, 'introT1_subheadline_bookingConfirmed', lang.key));
			form.setFieldValue(('introT1_headline_bookingConfirmed' + lang.key), findTranslationOrFallback(content.translations, 'introT1_headline_bookingConfirmed', lang.key));
		});
	}, [content, form]);
	
	return (
		<div className="intro-t1-container block">
			{ type === 'lp' &&
				<>
					<div className="block">
						<Divider orientation="left" orientationMargin="0">
							<Title level={4}>
								{t('content.lp.introt1.offer')}
							</Title>
						</Divider>
						<div className="fields">
							<LanguageInput
								label={t('content.lp.general.subheadline')}
								name="introT1_subheadline"
								currentLanguage={language}
							/>
							<LanguageInput
								label={t('content.lp.general.headline')}
								name="introT1_headline"
								currentLanguage={language}
							/>
							<Row gutter={24}>
								<Col span={8}>
									<div className="field">
										<div className="label">{t('content.lp.general.text')} 1</div>
										<TextEditor
											formItemName="introT1_text"
											form={form}
											language={language}
											translations={content.translations}
										/>
									</div>
								</Col>
								<Col span={8}>
									<div className="field">
										<div className="label">{t('content.lp.general.text')} 2</div>
										<TextEditor
											formItemName="introT1_text2"
											form={form}
											language={language}
											translations={content.translations}
										/>
									</div>
								</Col>
								<Col span={8}>
									<div className="field">
										<div className="label">{t('content.lp.general.text')} 3</div>
										<TextEditor
											formItemName="introT1_text3"
											form={form}
											language={language}
											translations={content.translations}
										/>
									</div>
								</Col>
							</Row>
						</div>
					</div>
					<div className="block">
						<Divider orientation="left" orientationMargin="0">
							<Title level={4}>
								{t('content.lp.introt1.offerNotPossible')}
							</Title>
						</Divider>
						<div className="fields">
							<div className="field">
								<div className="label">{t('content.lp.general.text')}</div>
								<TextEditor
									formItemName="introT1_textOfferNotAvailable"
									form={form}
									language={language}
									translations={content.translations}
								/>
							</div>
						</div>
					</div>
				</>
			}
			{ type === 'booking' &&
				<>
					<div className="block">
						<Divider orientation="left" orientationMargin="0">
							<Title level={4}>
								{t('content.lp.introt1.booked')}
							</Title>
						</Divider>
						<div className="fields">
							<LanguageInput
								label={t('content.lp.general.subheadline')}
								name="introT1_subheadline_booked"
								currentLanguage={language}
							/>
							<LanguageInput
								label={t('content.lp.general.headline')}
								name="introT1_headline_booked"
								currentLanguage={language}
							/>
							<div className="field">
								<div className="label">{t('content.lp.general.text')}</div>
								<TextEditor
									formItemName="introT1_text_booked"
									form={form}
									language={language}
									translations={content.translations}
								/>
							</div>
						</div>
					</div>
					<div className="block">
						<Divider orientation="left" orientationMargin="0">
							<Title level={4}>
								{t('content.lp.introt1.bookingConfirmed')}
							</Title>
						</Divider>
						<div className="fields">
							<LanguageInput
								label={t('content.lp.general.subheadline')}
								name="introT1_subheadline_bookingConfirmed"
								currentLanguage={language}
							/>
							<LanguageInput
								label={t('content.lp.general.headline')}
								name="introT1_headline_bookingConfirmed"
								currentLanguage={language}
							/>
							<div className="field">
								<div className="label">{t('content.lp.general.text')}</div>
								<TextEditor
									formItemName="introT1_text_bookingConfirmed"
									form={form}
									language={language}
									translations={content.translations}
								/>
							</div>
						</div>
					</div>
				</>
			}
		</div>
	);
};

export default IntroT1;