import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { apiDomain } from 'utils/constants';
import ModuleHeaderSmallT1 from './modules/headersmallt1';
import ModuleFooterT1 from './modules/footert1';
import ModuleAGBT1 from './modules/agbt1';
import OfferStyles from './styles';
import axios from 'axios';
import './index.scss';

function OfferAGBs () {
	const { offerUid } = useParams();
	const { accessToken } = useAuth();
	const [offer, setOffer] = useState(null);
	const [content, setContent] = useState(null);
	const [fallbackAgb, setFallbackAgb] = useState(null);
	const [customAgb, setCustomAgb] = useState(null);
	const { i18n } = useTranslation('translations');
	
	const fetchData = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'offer/' + offerUid, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setOffer(response);
				
				i18n.changeLanguage(response.language);
				
				const { data: content } = await axios.get(apiDomain() + 'content/' + response.hotelId, {
					headers: {
						'x-access-token': accessToken,
					},
				});
				if (content) {
					setContent(content);
				}
				
				const { data: agbFallback } = await axios.get(apiDomain() + 'settings/fallback-agb');
				if (agbFallback) {
					setFallbackAgb(agbFallback);
				}
				
				const { data: privacyCustom } = await axios.get(apiDomain() + 'settings/agb', {
					headers: {
						'x-access-token': accessToken,
					},
				});
				
				if (privacyCustom) {
					setCustomAgb(privacyCustom);
				}
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [offerUid, accessToken, i18n]);
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	return (
		<div className="offer-landingpage offer-landingpage-privacy bgweb">
			{ content && offer && (customAgb || fallbackAgb) &&
				<>
					<OfferStyles
						hotelId={offer.hotelId}
					/>
					<ModuleHeaderSmallT1
						offer={offer}
					/>
					<ModuleAGBT1
						offer={offer}
						customAgb={customAgb}
						fallbackAgb={fallbackAgb}
					/>
					<ModuleFooterT1
						content={content}
						offer={offer}
					/>
				</>
			}
		</div>
	);
};

export default OfferAGBs;