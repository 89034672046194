import RequestOffer from './offer';
import Tag from 'components/core/tag';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import { Affix, Button, Card, Col, Divider, Row, Space, notification } from 'antd';
import { PlusOutlined, SyncOutlined, MailOutlined } from '@ant-design/icons';
import { useAuth } from 'hooks/useAuth';
import { apiDomain, backendDomain, findTranslationOrFallback, mealPlans, RoutePaths } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import LanguageFlag from 'components/core/language-flag';
import HTMLReactParser from 'html-react-parser';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import './index.scss';
import PriceTable from 'pages/offer/components/core/price-table';

function RequestDetail() {
	const { accessToken, user } = useAuth();
	const { requestId } = useParams();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [offer, setOffer] = useState(null);
	const [rooms, setRooms] = useState([]);
	const [content, setContent] = useState();
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [api, contextHolder] = notification.useNotification();
	
	const showDrawer = () => {
		setOpen(true);
	};
	
	const onClose = (offerCreated = false) => {
		fetchData();
		if (data.offerId) {
			fetchOffer();
		}
		setOpen(false);
		if (offerCreated) {
			api['success']({
				message: t('general.emailSendSuccess'),
				placement: 'bottomRight',
				duration: 3,
			});
		}
	};
	
	const resendOffer = useCallback(async() => {
		setLoading(true);
		try {
			const { data: response } = await axios.post(apiDomain() + 'offers/send', { offerId: data.offerId, hotelId: user.hotelId, requestId: data.id }, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				api['success']({
					message: t('general.emailSendSuccess'),
					placement: 'bottomRight',
					duration: 3,
				});
			}
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
		setLoading(false);
	}, [data.offerId, accessToken, user.hotelId, data.id, api, t]);
	
	const fetchData = useCallback(async() => {
		setLoading(true);
		try {
			const { data: response } = await axios.get(apiDomain() + 'requests/' + requestId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				let request = {
					...response,
					key: response.id,
					date: moment(response.date).format('DD.MM.YYYY - HH:mm') + ' ' + t('request.time'),
					arrivalDate: moment(response.arrivalDate).format('DD.MM.YYYY'),
					departureDate: moment(response.departureDate).format('DD.MM.YYYY'),
				};
				setData(request);
			}
		} catch (error) {
			console.error(error.message);
			navigate(RoutePaths.requests.index);
		}
		setLoading(false);
	}, [requestId, accessToken, navigate, t]);
	
	const fetchRooms = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'rooms', {
				headers: {
					'x-access-token': accessToken,
				},
				
			});
			if (response) {
				let rooms = response.map(function(obj) {
					return {
						value: obj.id,
						label: findTranslationOrFallback(obj.roomTranslations, 'name', i18n.resolvedLanguage.toUpperCase()),
						labelDE: findTranslationOrFallback(obj.roomTranslations, 'name', 'DE'),
						labelIT: findTranslationOrFallback(obj.roomTranslations, 'name', 'IT'),
						labelEN: findTranslationOrFallback(obj.roomTranslations, 'name', 'EN'),
						code: obj.code,
					};
				});
				setRooms(rooms);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken, i18n.resolvedLanguage]);
	
	const fetchOffer = useCallback(async() => {
		setLoading(true);
		try {
			const { data: response } = await axios.get(apiDomain() + 'offers/' + data.offerId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setOffer(response);
			}
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	}, [data.offerId, accessToken]);
	
	const fetchContent = useCallback(async() => {
		setLoading(true);
		try {
			const { data: response } = await axios.get(apiDomain() + 'content/' + user.hotelId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setContent(response);
			}
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	}, [user.hotelId, accessToken]);
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	useEffect(() => {
		fetchRooms();
	}, [fetchRooms]);
	
	useEffect(() => {
		fetchContent();
	}, [fetchContent]);
	
	useEffect(() => {
		if (data.offerId) {
			fetchOffer();
		}
	}, [fetchOffer, data.offerId]);
	
	return (
		<div className="request-detail">
			{contextHolder}
			{ (!loading && data) &&
				<>
					<div className="headline-container">
						<Title level={3}>{data.firstName + ' ' + data.lastName}</Title>
						<div className="right-content">
							{data.date} - <LanguageFlag language={data.language} />
							<Tag color={data.offerId ? 'green' : 'geekblue'} text={data.offerId ? t('request.offerSend') : t('request.offerOpen')} />
						</div>
					</div>
					<Divider/>
					<br />
					<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
						<Row gutter={16}>
							{ offer && offer.offer_variants.length > 0 &&
								<Col span={24}>
									<Divider orientation="left" orientationMargin="0">
										<Title level={4}>
											{t('request.offerOverview')}
										</Title>
									</Divider>
									<Row gutter={16} className="request-overview">
										{ offer.offer_variants.map((variant, index) => (
											<Col span={12} key={index}>
												<Card size="small" title={t('request.variant') + ' ' + (index + 1)}>
													<Row gutter={12}>
														{ (variant.arrivalDate || variant.departureDate) &&
															<Col span={12}>
																<p><b>{t('request.arrivalDeparture')}:</b> {moment(variant.arrivalDate).format('DD.MM.YYYY') + ' - ' + moment(variant.departureDate).format('DD.MM.YYYY')}</p>
															</Col>
														}
														{ variant.roomCode &&
															<Col span={12}>
																<p><b>{t('request.room')}:</b> {rooms.find(roomObject => roomObject.code?.toLowerCase() === variant.roomCode.toLowerCase()) ? rooms.find(roomObject => roomObject.code?.toLowerCase() === variant.roomCode.toLowerCase()).label : variant.roomCode}</p>
															</Col>
														}
														{ variant.adults &&
															<Col span={12}>
																<p><b>{t('request.adults')}:</b> {variant.adults}</p>
															</Col>
														}
														{ variant.children > 0 &&
															<Col span={12}>
																<p><b>{t('request.children')}:</b> {variant.children}</p>
															</Col>
														}
														{ variant.mealPlan &&
															<Col span={12}>
																<p><b>{t('request.mealPlan')}:</b> {mealPlans(t, i18n.resolvedLanguage).find(plan => plan.value === variant.mealPlan)?.label ? mealPlans(t, i18n.resolvedLanguage).find(plan => plan.value === variant.mealPlan)?.label : variant.mealPlan}</p>
															</Col>
														}
														{ variant.offer_variants_prices.length &&
															<Col span={24}>
																<b>{t('request.prices.prices')}:</b>
																<PriceTable prices={variant.offer_variants_prices} lang={i18n.resolvedLanguage}/>
															</Col>
														}
													</Row>
												</Card>
												<br />
											</Col>
										))}
									</Row>
								</Col>
							}
							<Col span={24}>
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('request.dateInfo')}
									</Title>
								</Divider>
							</Col>
							<Col span={12}>
								<Card size="small" title={t('request.arrival')}>{data.arrivalDate ? data.arrivalDate : '-'}</Card>
							</Col>
							<Col span={12}>
								<Card size="small" title={t('request.departure')}>{data.departureDate ? data.departureDate : '-'}</Card>
							</Col>
							<Col span={12}>
								<Card size="small" title={t('request.room')}>
									{ data.roomCode ? (rooms.find(room => room.code === data.roomCode) ? ( rooms.find(room => room.code === data.roomCode).label ) : (data.roomCode)) : '-'}
								</Card>
							</Col>
							<Col span={12}>
								<Card size="small" title={t('request.mealPlan')}>{data.mealPlan ? (mealPlans(t, i18n.resolvedLanguage).find(plan => plan.value === data.mealPlan)?.label ? mealPlans(t, i18n.resolvedLanguage).find(plan => plan.value === data.mealPlan)?.label : data.mealPlan) : '-'}</Card>
							</Col>
							<Col span={12}>
								<Card size="small" title={t('request.adults')}>{data.adults ? data.adults : '-'}</Card>
							</Col>
							<Col span={12}>
								<Card size="small" title={t('request.children')}>{data.children > 0 ? (data.children + (data.childrenAges ? (' (' + data.childrenAges + ' ' + t('request.years') + ')') : '')) : '-'}</Card>
							</Col>
							<Col span={24}>
								<Card size="small" title={t('request.commentAdditional')}>{data.comment ? HTMLReactParser(data.comment.replaceAll('\\n', '<br />').replaceAll(/\n/g, '<br />')) : '-'}</Card>
							</Col>
							<Col span={24}>
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('request.personalData')}
									</Title>
								</Divider>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.salutation')}>{data.gender ? (data.gender === 'M' ? t('request.salutationMale') : t('request.salutationFemale')) : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.firstName')}>{data.firstName ? data.firstName : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.lastName')}>{data.lastName ? data.lastName : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.email')}>
									{data.email ? (
										<a href={'mailto:' + data.email}>{data.email}</a>
									) : (
										'-'
									)}
								</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.phone')}>{data.phone ? data.phone : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.street')}>{data.street ? data.street : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.cap')}>{data.cap ? data.cap : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.place')}>{data.place ? data.place : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.country')}>{data.country ? data.country : '-'}</Card>
							</Col>
							<Col span={24}>
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('request.source')}
									</Title>
								</Divider>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.utm_source')}>{data.utm_source ? data.utm_source : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.utm_medium')}>{data.utm_medium ? data.utm_medium : '-'}</Card>
							</Col>
							<Col span={8}>
								<Card size="small" title={t('request.utm_campaign')}>{data.utm_campaign ? data.utm_campaign : '-'}</Card>
							</Col>
						</Row>
					</Space>
					<br />
					<Divider />
					<br />
					<Affix offsetBottom={0} className="sticky-save-container">
						<div className="content">
							{ (!offer) ? (
								<Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
									{t('request.createOffer')}
								</Button>
							) : (
								<Space wrap size="middle">
									<Button type="primary" onClick={showDrawer} icon={<SyncOutlined />}>
										{t('request.updateOffer')}
									</Button>
									<Button type="primary" onClick={resendOffer} icon={<MailOutlined />}>
										{t('request.resendOffer')}
									</Button>
									<Button href={backendDomain() + 'offer/' + offer.uid} target="_blank">
										{t('request.offerLink')}
									</Button>
								</Space>
							)}
						</div>
					</Affix>
					{ open &&
						<RequestOffer
							requestId={data.id}
							open={open}
							onClose={onClose}
							arrival={data.arrivalDate}
							departure={data.departureDate}
							room={data.roomId}
							roomCode={data.roomCode}
							offer={offer}
							mealPlan={data.mealPlan}
							rooms={rooms}
							adults={data.adults ? data.adults : 0}
							children={data.children ? data.children : 0}
							childrenAges={data.childrenAges}
							comment={data.comment}
							language={data.language}
							introTextDefault={content?.translations?.filter(translation => translation.key === 'introT1_text')[0]['value' + data?.language?.toUpperCase()]}
							introTextDefault2={content?.translations?.filter(translation => translation.key === 'introT1_text2')[0]['value' + data?.language?.toUpperCase()]}
							introTextDefault3={content?.translations?.filter(translation => translation.key === 'introT1_text3')[0]['value' + data?.language?.toUpperCase()]}
							introTextDefaultNotAvailable={content?.translations?.filter(translation => translation.key === 'introT1_textOfferNotAvailable')[0]['value' + data?.language?.toUpperCase()]}
						/>
					}
				</>
			}
		</div>
	);
}

export default RequestDetail;