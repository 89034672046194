import { Tag as TagAntd } from 'antd';

function Tag({ color, text }) {
	return (
		<div className="flag-container">
			<TagAntd color={color}>
				{text}
			</TagAntd>
		</div>
	);
}

export default Tag;
