import { Affix, Button, Divider, Form, notification } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import LanguageSwitcher from 'components/core/language-switcher';
import IntroT1 from './modules/introt1';
import VariantsT1 from './modules/variantst1';
import TeaserT1 from './modules/teasert1';
import BookingOverlayT1 from './modules/booking-overlayt1';
import UspT1 from './modules/uspt1';
import { useAuth } from 'hooks/useAuth';
import { apiDomain } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './index.scss';

function SettingsContentLandingpage ({ language, onLanguageChange }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { accessToken, user } = useAuth();
	const [content, setContent] = useState([]);
	const [imagesTeaserT1, setImagesTeaserT1] = useState([]);
	const [removeListTeaserT1, setRemoveListTeaserT1] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	
	const onSubmit = async (values) => {
		try {
			const data = new FormData();
			imagesTeaserT1.forEach((file) => {
				if (!file.existingImage) {
					data.append('teasert1', file, file.name);
				}
			});
			
			let fileToDeleteArray = [];
			
			removeListTeaserT1.forEach((file) => {
				fileToDeleteArray.push(file.fileName);
			});
			
			if (fileToDeleteArray.length > 0) {
				data.append('filesToDelete', fileToDeleteArray.join('|'));
			}
			
			data.append('hotelId', user.hotelId);
			
			Object.keys(values).forEach(function(key,index) {
				data.append(key, values[key]);
			});
			
			await axios.put(apiDomain() + 'content', data, {
				headers: {
					'x-access-token': accessToken,
				},
			}).then(done => {
				api['success']({
					message: t('general.saveSuccess'),
					description: t('general.saveSuccessText'),
					placement: 'bottomRight',
					duration: 3,
				});
				setImagesTeaserT1([]);
				setRemoveListTeaserT1([]);
				fetchData();
			});
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	const fetchData = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'content/' + user.hotelId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setContent(response);
				setImagesTeaserT1(response.contentImages?.filter(image => image.moduleId === 'teasert1').map((file, i) => {
					return {
						index: i,
						name: 'Bild ' + i,
						fileName: file.name,
						thumbUrl: apiDomain() + file.path,
						url: apiDomain() + file.path,
						existingImage: true,
					};
				}));
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken, user.hotelId]);
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	return (
		<div className="settings-content-landingpage">
			{contextHolder}
			{ content &&
				<Form
					layout={'vertical'}
					onFinish={onSubmit}
					form={form}
				>
					<div className="modules">
						<IntroT1 content={content} form={form} language={language} type={'lp'}/>
						<VariantsT1 content={content} form={form} language={language} />
						<UspT1
							content={content}
							form={form}
							language={language}
						/>
						<TeaserT1
							content={content}
							form={form}
							language={language}
							images={imagesTeaserT1 ? imagesTeaserT1 : []}
							removeList={removeListTeaserT1}
							onAddImages={(images) => setImagesTeaserT1(images)}
							onRemoveImages={(images) => setRemoveListTeaserT1(images)}
						/>
						<BookingOverlayT1 content={content} form={form} language={language} />
					</div>
					<Divider/>
					<br />
					<Affix offsetBottom={0} className="sticky-save-container">
						<div className="content">
							<Form.Item>
								<Button type="primary" htmlType="submit">{t('content.lp.general.save')}</Button>
							</Form.Item>
							<LanguageSwitcher
								currentLanguage={language}
								onSwitch={(lang) => onLanguageChange(lang)}
							/>
						</div>
					</Affix>
				</Form>
			}
		</div>
	);
};

export default SettingsContentLandingpage;