import { Col, Divider, Row, Upload } from 'antd';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { findTranslationOrFallback, isValidUploadFormat, languages } from 'utils/constants';
import LanguageInput from 'components/forms/language-input';
import TextEditor from 'components/forms/text-editor';

function TeaserT2 ({ content, form, language, images, removeList, onAddImages, onRemoveImages }) {
	const { t } = useTranslation();
	const teaserCount = useMemo(() => [1, 2, 3], []);
	
	const beforeUpload = () => {
		return false;
	};
	
	const onChangeHandle = (info,count) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		onAddImages(tempFiles, count);
	};
	
	const onRemove = (file,count) => {
		const index = images[count - 1].indexOf(file);
		const newFileList = images[count - 1].slice();
		newFileList.splice(index, 1);
		onAddImages(newFileList, count);
		onRemoveImages([...removeList, file]);
	};
	
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				{t('content.lp.general.upload')}
			</div>
		</div>
	);
	
	useEffect(() => {
		languages.forEach(lang => {
			teaserCount.forEach(count => {
				form.setFieldValue(('teaserT2_headline' + count + lang.key), findTranslationOrFallback(content.translations, 'teaserT2_headline' + count, lang.key));
			});
		});
	}, [content, form, teaserCount]);
	
	return (
		<div className="teaser-t2-container block">
			<Divider orientation="left" orientationMargin="0">
				<Title level={4}>
					{t('content.lp.teasert2.teasert2')}
				</Title>
			</Divider>
			<div className="fields">
				<Row gutter={24}>
					{ teaserCount.map((count) => {
						return (
							<Col span={8} key={count}>
								<div className="label">{t('content.lp.general.image')}</div>
								<Upload
									listType="picture-card"
									fileList={images[count - 1]}
									beforeUpload={beforeUpload}
									onChange={(values) => onChangeHandle(values,count)}
									onRemove={(values) => onRemove(values,count)}
									multiple
								>
									{images[count - 1].length >= 1 ? null : uploadButton}
								</Upload>
								<LanguageInput
									label={t('content.lp.general.headline')}
									name={'teaserT2_headline' + count}
									currentLanguage={language}
								/>
								<div className="field">
									<div className="label">{t('content.lp.general.text')}</div>
									<TextEditor
										formItemName={'teaserT2_text' + count}
										form={form}
										language={language}
										translations={content.translations}
									/>
								</div>
							</Col>
						);
					})}
				</Row>
				
			</div>
		</div>
	);
};

export default TeaserT2;