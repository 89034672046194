import { useAuth } from 'hooks/useAuth';
import { apiDomain, RoutePaths } from 'utils/constants';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import axios from 'axios';

function SettingsChangeClient () {
	const { accessToken, user, updateHotelId } = useAuth();
	const [clients, setClients] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const { t } = useTranslation();
	
	const fetchClients = useCallback(async() => {
		setLoading(true);
		try {
			if (user) {
				const { data: response } = await axios.get(apiDomain() + 'clients', {
					headers: {
						'x-access-token': accessToken,
					},
				});
				if (response) {
					setClients(response);
				}
			}
			setLoading(false);
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken, user]);
	
	const onSubmit = async (clientId) => {
		try {
			await axios.put(apiDomain() + 'clients/' + clientId, {}, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			await updateHotelId(clientId);
			navigate(RoutePaths.requests.index);
		} catch (error) {
			console.error(error.message);
		}
	};
	
	const columns = [
		{
			title: t('clients.name'),
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: t('clients.place'),
			dataIndex: 'place',
			key: 'place',
		},
		{
			dataIndex: 'id',
			key: 'id',
			render: (_, { id }) => {
				return (
					<Button type="link" onClick={() => onSubmit(id)}>{t('clients.select')}</Button>
				);
			},
		},
	];
	
	useEffect(() => {
		fetchClients();
	}, [fetchClients]);
	
	return (
		<div className="settings-change-client">
			<div className="clients">
				<Table
					dataSource={clients}
					columns={columns}
					loading={loading}
					rowKey="id"
				/>
			</div>
		</div>
	);
};

export default SettingsChangeClient;