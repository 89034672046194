import React, { useEffect } from 'react';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { findTranslationOrFallback, languages } from 'utils/constants';
import Title from 'antd/es/typography/Title';
import TextEditor from 'components/forms/text-editor';
import LanguageInput from 'components/forms/language-input';

function TextT1 ({ content, form, language }) {
	const { t } = useTranslation();
	
	useEffect(() => {
		languages.forEach(lang => {
			form.setFieldValue(('textT1_anzahlung_subheadline' + lang.key), findTranslationOrFallback(content.translations, 'textT1_anzahlung_subheadline', lang.key));
			form.setFieldValue(('textT1_anzahlung_headline' + lang.key), findTranslationOrFallback(content.translations, 'textT1_anzahlung_headline', lang.key));
			form.setFieldValue(('textT1_anzahlung_value' + lang.key), findTranslationOrFallback(content.translations, 'textT1_anzahlung_value', lang.key));
			form.setFieldValue(('textT1_gtk_subheadline' + lang.key), findTranslationOrFallback(content.translations, 'textT1_gtk_subheadline', lang.key));
			form.setFieldValue(('textT1_gtk_headline' + lang.key), findTranslationOrFallback(content.translations, 'textT1_gtk_headline', lang.key));
			form.setFieldValue(('textT1_arrival_subheadline' + lang.key), findTranslationOrFallback(content.translations, 'textT1_arrival_subheadline', lang.key));
			form.setFieldValue(('textT1_arrival_headline' + lang.key), findTranslationOrFallback(content.translations, 'textT1_arrival_headline', lang.key));
		});
	}, [content, form]);
	
	return (
		<div className="text-t1-container block">
			<div className="block">
				<Divider orientation="left" orientationMargin="0">
					<Title level={4}>
						{t('content.lp.text1.text1_anzahlung')}
					</Title>
				</Divider>
				<div className="fields">
					<LanguageInput
						label={t('content.lp.general.subheadline')}
						name="textT1_anzahlung_subheadline"
						currentLanguage={language}
					/>
					<LanguageInput
						label={t('content.lp.general.headline')}
						name="textT1_anzahlung_headline"
						currentLanguage={language}
					/>
					<LanguageInput
						label={t('content.lp.general.anzahlung_prozent')}
						name="textT1_anzahlung_value"
						currentLanguage="DE"
						type="number"
					/>
					<div className="field">
						<div className="label">{t('content.lp.general.text')}</div>
						<TextEditor
							formItemName="textT1_anzahlung_text"
							form={form}
							language={language}
							translations={content.translations}
						/>
					</div>
				</div>
			</div>
			<div className="block">
				<Divider orientation="left" orientationMargin="0">
					<Title level={4}>
						{t('content.lp.text1.text1_gtk')}
					</Title>
				</Divider>
				<div className="fields">
					<LanguageInput
						label={t('content.lp.general.subheadline')}
						name="textT1_gtk_subheadline"
						currentLanguage={language}
					/>
					<LanguageInput
						label={t('content.lp.general.headline')}
						name="textT1_gtk_headline"
						currentLanguage={language}
					/>
					<div className="field">
						<div className="label">{t('content.lp.general.text')}</div>
						<TextEditor
							formItemName="textT1_gtk_text"
							form={form}
							language={language}
							translations={content.translations}
						/>
					</div>
				</div>
			</div>
			<div className="block">
				<Divider orientation="left" orientationMargin="0">
					<Title level={4}>
						{t('content.lp.text1.text1_arrival')}
					</Title>
				</Divider>
				<div className="fields">
					<LanguageInput
						label={t('content.lp.general.subheadline')}
						name="textT1_arrival_subheadline"
						currentLanguage={language}
					/>
					<LanguageInput
						label={t('content.lp.general.headline')}
						name="textT1_arrival_headline"
						currentLanguage={language}
					/>
					<div className="field">
						<div className="label">{t('content.lp.general.text')}</div>
						<TextEditor
							formItemName="textT1_arrival_text"
							form={form}
							language={language}
							translations={content.translations}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TextT1;