import Icon from 'pages/offer/components/core/icon';
import Text from 'pages/offer/components/core/text';
import Link from 'pages/offer/components/core/link';
import React, { useEffect, useState } from 'react';
import { RoutePaths, apiDomain, findTranslationOrFallback } from 'utils/constants';
import { getTranslation } from 'pages/offer/translations/translations';
import './index.scss';

function ModuleFooterT1 ({ content, offer }) {
	const [text, setText] = useState([]);
	
	useEffect(() => {
		let lang = offer.language.toUpperCase();
		let textKey = 'additionalText';
		
		setText(findTranslationOrFallback(offer.hotelInfos?.translations, textKey, lang));
	}, [content, offer]);
	
	return (
		<div className="module-footer-t1">
			{ offer &&
				<>
					<div className="centercontent1470 df ac inner">
						<div className="socials">
							{ offer.hotelInfos?.facebook &&
								<a href={offer.hotelInfos.facebook} rel="noreferrer" target="_blank">
									<Icon
										icon="facebook"
										width="16"
										height="35"
									/>
								</a>
							}
							{ offer.hotelInfos?.instagram &&
								<a href={offer.hotelInfos.instagram} rel="noreferrer" target="_blank">
									<Icon
										icon="instagram"
										width="36"
										height="36"
									/>
								</a>
							}
							{ offer.hotelInfos?.linkedin &&
								<a href={offer.hotelInfos.linkedin} rel="noreferrer" target="_blank">
									<Icon
										icon="linkedin"
										width="30"
										height="29"
									/>
								</a>
							}
						</div>
						<div className="logo">
							{ offer.hotelInfos?.logoFooterPath &&
								<img src={apiDomain() + offer.hotelInfos.logoFooterPath} alt="" />
							}
						</div>
						<div className="client-data">
							<div className="top">
								{ offer.hotelInfos?.phone &&
									<div className="tel"><a href={'tel:' + offer.hotelInfos.phone}>{offer.hotelInfos.phone}</a></div>
								}
								{ offer.hotelInfos?.email &&
									<div className="email"><a href={'mailto:' + offer.hotelInfos.email}>{offer.hotelInfos.email}</a></div>
								}
							</div>
							<div className="mid">
								<div className="name">{findTranslationOrFallback(offer.hotelInfos?.translations, 'name', offer.language.toUpperCase()) + ' ' + findTranslationOrFallback(offer.hotelInfos?.translations, 'nameAdditional', offer.language.toUpperCase())}</div>
								<div className="address">{findTranslationOrFallback(offer.hotelInfos?.translations, 'address', offer.language.toUpperCase()) + ' , ' + offer.hotelInfos?.cap + ' ' + findTranslationOrFallback(offer.hotelInfos?.translations, 'place', offer.language.toUpperCase())}</div>
							</div>
							{ text &&
								<div className="bottom">
									<div className="text">
										<Text text={ text } />
									</div>
								</div>
							}
						</div>
					</div>
					<div className="centercontent1470 credits tac df jc">
						<Link text={getTranslation('general', 'credits', offer.language)} class="link-text" href={RoutePaths.offer.credits.replace(':offerUid', offer.uid)}/>
						<Link text={getTranslation('general', 'privacy', offer.language)} class="link-text" href={RoutePaths.offer.privacy.replace(':offerUid', offer.uid)}/>
						<Link text={getTranslation('general', 'agbs', offer.language)} class="link-text" href={RoutePaths.offer.agbs.replace(':offerUid', offer.uid)}/>
						<div className="created">
							<a className="df ac" href="https://www.co-berta.com/" target="_blank" rel="noreferrer" >created with <Icon icon="coberta" /></a>
						</div>
					</div>
				</>
			}
		</div>
	);
};

export default ModuleFooterT1;