function Headline (props) {
	return (
		<div className={ props.class }>
			<span dangerouslySetInnerHTML={{ __html: props.text }} />
		</div>
	);
}

Headline.defaultProps = {
	class: 'headline t1',
	text: '',
};

export default Headline;