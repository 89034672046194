import React, { useEffect, useState } from 'react';
import { findTranslationOrFallback } from 'utils/constants';
import Intro from 'pages/offer/components/modules/intro';

function ModuleIntroT1 ({ content, offer }) {
	const [headline, setHeadline] = useState([]);
	const [subHeadline, setSubHeadline] = useState([]);
	const [text, setText] = useState([]);
	
	useEffect(() => {
		let lang = offer.language.toUpperCase();
		let headlineKey = 'introT1_headline';
		let subHeadlineKey = 'introT1_subheadline';
		let textKey = '';
		
		if (offer.booked) {
			if (offer.bookingConfirmed) {
				headlineKey += '_bookingConfirmed';
				subHeadlineKey += '_bookingConfirmed';
				textKey += 'introT1_text_bookingConfirmed';
			} else {
				headlineKey += '_booked';
				subHeadlineKey += '_booked';
				textKey += 'introT1_text_booked';
			}
		}
		
		setHeadline(findTranslationOrFallback(content.translations, headlineKey, lang));
		setSubHeadline(findTranslationOrFallback(content.translations, subHeadlineKey, lang));
		setText(textKey !== '' ? findTranslationOrFallback(content.translations, textKey, lang) : offer.introText);
	}, [content, offer]);
	
	return (
		<>
			{(headline || subHeadline || text) &&
				<Intro
					preheadline={ subHeadline }
					headline={ headline }
					text={ text }
				/>
			}
		</>
	);
};

export default ModuleIntroT1;