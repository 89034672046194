import Tag from 'components/core/tag';
import LanguageFlag from 'components/core/language-flag';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react';
import { Button, Table, Divider } from 'antd';
import { apiDomain, RoutePaths } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, EyeTwoTone } from '@ant-design/icons';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';

function RequestList() {
	const navigate = useNavigate();
	const { accessToken } = useAuth();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const { t } = useTranslation();
	
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 10,
		},
	});
	
	const columns = [
		{
			title: t('request.name'),
			dataIndex: 'name',
			key: 'name',
			render: (text, record) => <Button type="link" onClick={() => navigate(RoutePaths.requests.index + '/' + record.key)}>{text}</Button>,
		},
		{
			title: t('request.date'),
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: t('request.language'),
			dataIndex: 'language',
			key: 'language',
			render: (_, { language }) => {
				return (
					<LanguageFlag language={language} />
				);
			},
		},
		{
			title: t('request.state'),
			dataIndex: 'offerId',
			key: 'offerId',
			render: (_, offer ) => {
				let color = offer.offerId ? 'green' : 'geekblue';
				let text = offer.offerId ? t('request.offerSend') : t('request.offerOpen');
				return (
					<div className="state df ac">
						<Tag color={color} text={text} />
						{ offer.viewed === 1 &&
							<EyeTwoTone twoToneColor="#52c41a" title={t('request.viewed')}/>
						}
					</div>
				);
			},
		},
	];
	
	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			...sorter,
		});
		if (pagination.pageSize !== tableParams.pagination?.pageSize) {
			setData([]);
		}
	};
	
	const fetchData = async () => {
		setLoading(true);
		try {
			const { data: response } = await axios.get(apiDomain() + 'requests?page=' + tableParams.pagination.current + '&pageSize=' + tableParams.pagination.pageSize, {
				headers: {
					'x-access-token': accessToken,
				},
				
			});
			if (response.data) {
				let requests = response.data.map(function(obj) {
					return {
						...obj,
						key: obj.id,
						date: moment(obj.date).format('DD.MM.YYYY - HH:mm') + ' ' + t('request.time'),
						name: obj.lastName + ' ' + obj.firstName,
					};
				});
				setData(requests);
				setTableParams({
					...tableParams,
					pagination: {
						...tableParams.pagination,
						total: response.meta.totalCount,
					},
				});
			}
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	};
	
	useEffect(() => {
		fetchData();
		const interval = setInterval(() => fetchData(), 15000);
		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(tableParams)]);
	
	return (
		<div className="request-list">
			<div className="headline-container">
				<Title level={3}>
					{t('menu.requests')}
				</Title>
				<div className="right-content">
					<Button type="primary" onClick={() => navigate(RoutePaths.requests.create)} icon={<PlusOutlined />}>
						{t('request.add')}
					</Button>
				</div>
			</div>
			<Divider/>
			<Table
				dataSource={data}
				columns={columns}
				loading={loading}
				pagination={tableParams.pagination}
				onChange={handleTableChange}
			/>
		</div>
	);
}

export default RequestList;