function PreHeadline (props) {
	return (
		<div className={ props.class }>
			<span dangerouslySetInnerHTML={{ __html: props.text }} />
		</div>
	);
}

PreHeadline.defaultProps = {
	class: 'preheadline t2',
	text: '',
};

export default PreHeadline;
