import { Affix, Button, Divider, Form, notification } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import LanguageSwitcher from 'components/core/language-switcher';
import IntroT1 from './modules/introt1';
import TextT1 from './modules/textt1';
import { useAuth } from 'hooks/useAuth';
import { apiDomain } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './index.scss';

function SettingsContentLandingpage ({ language, onLanguageChange }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { accessToken, user } = useAuth();
	const [content, setContent] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	
	const onSubmit = async (values) => {
		try {
			const data = new FormData();
			
			data.append('hotelId', user.hotelId);
			
			Object.keys(values).forEach(function(key,index) {
				data.append(key, values[key]);
			});
			
			await axios.put(apiDomain() + 'content', data, {
				headers: {
					'x-access-token': accessToken,
				},
			}).then(done => {
				api['success']({
					message: t('general.saveSuccess'),
					description: t('general.saveSuccessText'),
					placement: 'bottomRight',
					duration: 3,
				});
				fetchData();
			});
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	const fetchData = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'content/' + user.hotelId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setContent(response);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken, user.hotelId]);
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	return (
		<div className="settings-content-landingpage settings-content-landingpage-booking">
			{contextHolder}
			{ content &&
				<Form
					layout={'vertical'}
					onFinish={onSubmit}
					form={form}
				>
					<div className="modules">
						<IntroT1 content={content} form={form} language={language} type={'booking'}/>
						<TextT1 content={content} form={form} language={language}/>
					</div>
					<Divider/>
					<br />
					<Affix offsetBottom={0} className="sticky-save-container">
						<div className="content">
							<Form.Item>
								<Button type="primary" htmlType="submit">{t('content.lp.general.save')}</Button>
							</Form.Item>
							<LanguageSwitcher
								currentLanguage={language}
								onSwitch={(lang) => onLanguageChange(lang)}
							/>
						</div>
					</Affix>
				</Form>
			}
		</div>
	);
};

export default SettingsContentLandingpage;