export const countriesDE = [
	{
		value: 'AF',
		label: 'Afghanistan',
	},
	{
		value: 'AX',
		label: 'Aland Islands',
	},
	{
		value: 'AL',
		label: 'Albanien',
	},
	{
		value: 'DZ',
		label: 'Algerien',
	},
	{
		value: 'AS',
		label: 'Amerikanischen Samoa-Inseln',
	},
	{
		value: 'AD',
		label: 'Andorra',
	},
	{
		value: 'AO',
		label: 'Angola',
	},
	{
		value: 'AI',
		label: 'Anguilla',
	},
	{
		value: 'AQ',
		label: 'Antarktis',
	},
	{
		value: 'AG',
		label: 'Antigua und Barbuda',
	},
	{
		value: 'AR',
		label: 'Argentinien',
	},
	{
		value: 'AM',
		label: 'Armenien',
	},
	{
		value: 'AW',
		label: 'Aruba',
	},
	{
		value: 'AU',
		label: 'Australien',
	},
	{
		value: 'AT',
		label: 'Österreich',
	},
	{
		value: 'AZ',
		label: 'Aserbaidschan',
	},
	{
		value: 'BS',
		label: 'Bahamas',
	},
	{
		value: 'BH',
		label: 'Bahrain',
	},
	{
		value: 'BD',
		label: 'Bangladesch',
	},
	{
		value: 'BB',
		label: 'Barbados',
	},
	{
		value: 'BY',
		label: 'Weißrussland',
	},
	{
		value: 'BE',
		label: 'Belgien',
	},
	{
		value: 'BZ',
		label: 'Belize',
	},
	{
		value: 'BJ',
		label: 'Benin',
	},
	{
		value: 'BM',
		label: 'Bermuda',
	},
	{
		value: 'BT',
		label: 'Bhutan',
	},
	{
		value: 'BO',
		label: 'Bolivien',
	},
	{
		value: 'BQ',
		label: 'Bonaire, Sint Eustatius und Saba',
	},
	{
		value: 'BA',
		label: 'Bosnien und Herzegowina',
	},
	{
		value: 'BW',
		label: 'Botswana',
	},
	{
		value: 'BV',
		label: 'Bouvet Island',
	},
	{
		value: 'BR',
		label: 'Brasilien',
	},
	{
		value: 'IO',
		label: 'Britisches Territorium des Indischen Ozeans',
	},
	{
		value: 'BN',
		label: 'Brunei Darussalam',
	},
	{
		value: 'BG',
		label: 'Bulgarien',
	},
	{
		value: 'BF',
		label: 'Burkina Faso',
	},
	{
		value: 'BI',
		label: 'Burundi',
	},
	{
		value: 'KH',
		label: 'Kambodscha',
	},
	{
		value: 'CM',
		label: 'Kamerun',
	},
	{
		value: 'CA',
		label: 'Kanada',
	},
	{
		value: 'CV',
		label: 'Kap Verde',
	},
	{
		value: 'KY',
		label: 'Cayman Inseln',
	},
	{
		value: 'CF',
		label: 'Zentralafrikanische Republik',
	},
	{
		value: 'TD',
		label: 'Tschad',
	},
	{
		value: 'CL',
		label: 'Chile',
	},
	{
		value: 'CN',
		label: 'China',
	},
	{
		value: 'CX',
		label: 'Weihnachtsinsel',
	},
	{
		value: 'CC',
		label: 'Kokosinseln (Keelinginseln)',
	},
	{
		value: 'CO',
		label: 'Kolumbien',
	},
	{
		value: 'KM',
		label: 'Komoren',
	},
	{
		value: 'CG',
		label: 'Kongo',
	},
	{
		value: 'CD',
		label: 'Kongo, Demokratische Republik Kongo',
	},
	{
		value: 'CK',
		label: 'Cookinseln',
	},
	{
		value: 'CR',
		label: 'Costa Rica',
	},
	{
		value: 'CI',
		label: 'Elfenbeinküste',
	},
	{
		value: 'HR',
		label: 'Kroatien',
	},
	{
		value: 'CU',
		label: 'Kuba',
	},
	{
		value: 'CW',
		label: 'Curacao',
	},
	{
		value: 'CY',
		label: 'Zypern',
	},
	{
		value: 'CZ',
		label: 'Tschechien',
	},
	{
		value: 'DK',
		label: 'Dänemark',
	},
	{
		value: 'DJ',
		label: 'Dschibuti',
	},
	{
		value: 'DM',
		label: 'Dominica',
	},
	{
		value: 'DO',
		label: 'Dominikanische Republik',
	},
	{
		value: 'EC',
		label: 'Ecuador',
	},
	{
		value: 'EG',
		label: 'Ägypten',
	},
	{
		value: 'SV',
		label: 'El Salvador',
	},
	{
		value: 'GQ',
		label: 'Äquatorialguinea',
	},
	{
		value: 'ER',
		label: 'Eritrea',
	},
	{
		value: 'EE',
		label: 'Estland',
	},
	{
		value: 'ET',
		label: 'Äthiopien',
	},
	{
		value: 'FK',
		label: 'Falklandinseln (Malvinas)',
	},
	{
		value: 'FO',
		label: 'Färöer Inseln',
	},
	{
		value: 'FJ',
		label: 'Fidschi',
	},
	{
		value: 'FI',
		label: 'Finnland',
	},
	{
		value: 'FR',
		label: 'Frankreich',
	},
	{
		value: 'GF',
		label: 'Französisch-Guayana',
	},
	{
		value: 'PF',
		label: 'Französisch Polynesien',
	},
	{
		value: 'TF',
		label: 'Südfranzösische Territorien',
	},
	{
		value: 'GA',
		label: 'Gabun',
	},
	{
		value: 'GM',
		label: 'Gambia',
	},
	{
		value: 'GE',
		label: 'Georgia',
	},
	{
		value: 'DE',
		label: 'Deutschland',
	},
	{
		value: 'GH',
		label: 'Ghana',
	},
	{
		value: 'GI',
		label: 'Gibraltar',
	},
	{
		value: 'GR',
		label: 'Griechenland',
	},
	{
		value: 'GL',
		label: 'Grönland',
	},
	{
		value: 'GD',
		label: 'Grenada',
	},
	{
		value: 'GP',
		label: 'Guadeloupe',
	},
	{
		value: 'GU',
		label: 'Guam',
	},
	{
		value: 'GT',
		label: 'Guatemala',
	},
	{
		value: 'GG',
		label: 'Guernsey',
	},
	{
		value: 'GN',
		label: 'Guinea',
	},
	{
		value: 'GW',
		label: 'Guinea-Bissau',
	},
	{
		value: 'GY',
		label: 'Guyana',
	},
	{
		value: 'HT',
		label: 'Haiti',
	},
	{
		value: 'HM',
		label: 'Heard Island und McDonald Islands',
	},
	{
		value: 'VA',
		label: 'Heiliger Stuhl (Staat der Vatikanstadt)',
	},
	{
		value: 'HN',
		label: 'Honduras',
	},
	{
		value: 'HK',
		label: 'Hongkong',
	},
	{
		value: 'HU',
		label: 'Ungarn',
	},
	{
		value: 'IS',
		label: 'Island',
	},
	{
		value: 'IN',
		label: 'Indien',
	},
	{
		value: 'ID',
		label: 'Indonesien',
	},
	{
		value: 'IR',
		label: 'Iran, Islamische Republik',
	},
	{
		value: 'IQ',
		label: 'Irak',
	},
	{
		value: 'IE',
		label: 'Irland',
	},
	{
		value: 'IM',
		label: 'Isle of Man',
	},
	{
		value: 'IL',
		label: 'Israel',
	},
	{
		value: 'IT',
		label: 'Italien',
	},
	{
		value: 'JM',
		label: 'Jamaika',
	},
	{
		value: 'JP',
		label: 'Japan',
	},
	{
		value: 'JE',
		label: 'Jersey',
	},
	{
		value: 'JO',
		label: 'Jordanien',
	},
	{
		value: 'KZ',
		label: 'Kasachstan',
	},
	{
		value: 'KE',
		label: 'Kenia',
	},
	{
		value: 'KI',
		label: 'Kiribati',
	},
	{
		value: 'KP',
		label: 'Korea, Demokratische Volksrepublik',
	},
	{
		value: 'KR',
		label: 'Korea, Republik von',
	},
	{
		value: 'XK',
		label: 'Kosovo',
	},
	{
		value: 'KW',
		label: 'Kuwait',
	},
	{
		value: 'KG',
		label: 'Kirgisistan',
	},
	{
		value: 'LA',
		label: 'Demokratische Volksrepublik Laos',
	},
	{
		value: 'LV',
		label: 'Lettland',
	},
	{
		value: 'LB',
		label: 'Libanon',
	},
	{
		value: 'LS',
		label: 'Lesotho',
	},
	{
		value: 'LR',
		label: 'Liberia',
	},
	{
		value: 'LY',
		label: 'Libyscher arabischer Jamahiriya',
	},
	{
		value: 'LI',
		label: 'Liechtenstein',
	},
	{
		value: 'LT',
		label: 'Litauen',
	},
	{
		value: 'LU',
		label: 'Luxemburg',
	},
	{
		value: 'MO',
		label: 'Macao',
	},
	{
		value: 'MK',
		label: 'Mazedonien, die ehemalige jugoslawische Republik',
	},
	{
		value: 'MG',
		label: 'Madagaskar',
	},
	{
		value: 'MW',
		label: 'Malawi',
	},
	{
		value: 'MY',
		label: 'Malaysia',
	},
	{
		value: 'MV',
		label: 'Malediven',
	},
	{
		value: 'ML',
		label: 'Mali',
	},
	{
		value: 'MT',
		label: 'Malta',
	},
	{
		value: 'MH',
		label: 'Marshallinseln',
	},
	{
		value: 'MQ',
		label: 'Martinique',
	},
	{
		value: 'MR',
		label: 'Mauretanien',
	},
	{
		value: 'MU',
		label: 'Mauritius',
	},
	{
		value: 'YT',
		label: 'Mayotte',
	},
	{
		value: 'MX',
		label: 'Mexiko',
	},
	{
		value: 'FM',
		label: 'Mikronesien, Föderierte Staaten von',
	},
	{
		value: 'MD',
		label: 'Moldawien, Republik',
	},
	{
		value: 'MC',
		label: 'Monaco',
	},
	{
		value: 'MN',
		label: 'Mongolei',
	},
	{
		value: 'ME',
		label: 'Montenegro',
	},
	{
		value: 'MS',
		label: 'Montserrat',
	},
	{
		value: 'MA',
		label: 'Marokko',
	},
	{
		value: 'MZ',
		label: 'Mosambik',
	},
	{
		value: 'MM',
		label: 'Myanmar',
	},
	{
		value: 'NA',
		label: 'Namibia',
	},
	{
		value: 'NR',
		label: 'Nauru',
	},
	{
		value: 'NP',
		label: 'Nepal',
	},
	{
		value: 'NL',
		label: 'Niederlande',
	},
	{
		value: 'AN',
		label: 'Niederländische Antillen',
	},
	{
		value: 'NC',
		label: 'Neu-Kaledonien',
	},
	{
		value: 'NZ',
		label: 'Neuseeland',
	},
	{
		value: 'NI',
		label: 'Nicaragua',
	},
	{
		value: 'NE',
		label: 'Niger',
	},
	{
		value: 'NG',
		label: 'Nigeria',
	},
	{
		value: 'NU',
		label: 'Niue',
	},
	{
		value: 'NF',
		label: 'Norfolkinsel',
	},
	{
		value: 'MP',
		label: 'Nördliche Marianneninseln',
	},
	{
		value: 'NO',
		label: 'Norwegen',
	},
	{
		value: 'OM',
		label: 'Oman',
	},
	{
		value: 'PK',
		label: 'Pakistan',
	},
	{
		value: 'PW',
		label: 'Palau',
	},
	{
		value: 'PS',
		label: 'Besetzte palästinensische Gebiete',
	},
	{
		value: 'PA',
		label: 'Panama',
	},
	{
		value: 'PG',
		label: 'Papua Neu-Guinea',
	},
	{
		value: 'PY',
		label: 'Paraguay',
	},
	{
		value: 'PE',
		label: 'Peru',
	},
	{
		value: 'PH',
		label: 'Philippinen',
	},
	{
		value: 'PN',
		label: 'Pitcairn',
	},
	{
		value: 'PL',
		label: 'Polen',
	},
	{
		value: 'PT',
		label: 'Portugal',
	},
	{
		value: 'PR',
		label: 'Puerto Rico',
	},
	{
		value: 'QA',
		label: 'Katar',
	},
	{
		value: 'RE',
		label: 'Wiedervereinigung',
	},
	{
		value: 'RO',
		label: 'Rumänien',
	},
	{
		value: 'RU',
		label: 'Russische Föderation',
	},
	{
		value: 'RW',
		label: 'Ruanda',
	},
	{
		value: 'BL',
		label: 'Heiliger Barthelemy',
	},
	{
		value: 'SH',
		label: 'Heilige Helena',
	},
	{
		value: 'KN',
		label: 'St. Kitts und Nevis',
	},
	{
		value: 'LC',
		label: 'St. Lucia',
	},
	{
		value: 'MF',
		label: 'Sankt Martin',
	},
	{
		value: 'PM',
		label: 'Saint Pierre und Miquelon',
	},
	{
		value: 'VC',
		label: 'St. Vincent und die Grenadinen',
	},
	{
		value: 'WS',
		label: 'Samoa',
	},
	{
		value: 'SM',
		label: 'San Marino',
	},
	{
		value: 'ST',
		label: 'Sao Tome und Principe',
	},
	{
		value: 'SA',
		label: 'Saudi-Arabien',
	},
	{
		value: 'SN',
		label: 'Senegal',
	},
	{
		value: 'RS',
		label: 'Serbien',
	},
	{
		value: 'CS',
		label: 'Serbien und Montenegro',
	},
	{
		value: 'SC',
		label: 'Seychellen',
	},
	{
		value: 'SL',
		label: 'Sierra Leone',
	},
	{
		value: 'SG',
		label: 'Singapur',
	},
	{
		value: 'SX',
		label: 'St. Martin',
	},
	{
		value: 'SK',
		label: 'Slowakei',
	},
	{
		value: 'SI',
		label: 'Slowenien',
	},
	{
		value: 'SB',
		label: 'Salomon-Inseln',
	},
	{
		value: 'SO',
		label: 'Somalia',
	},
	{
		value: 'ZA',
		label: 'Südafrika',
	},
	{
		value: 'GS',
		label: 'Süd-Georgien und die südlichen Sandwich-Inseln',
	},
	{
		value: 'SS',
		label: 'Südsudan',
	},
	{
		value: 'ES',
		label: 'Spanien',
	},
	{
		value: 'LK',
		label: 'Sri Lanka',
	},
	{
		value: 'SD',
		label: 'Sudan',
	},
	{
		value: 'SR',
		label: 'Suriname',
	},
	{
		value: 'SJ',
		label: 'Spitzbergen und Jan Mayen',
	},
	{
		value: 'SZ',
		label: 'Swasiland',
	},
	{
		value: 'SE',
		label: 'Schweden',
	},
	{
		value: 'CH',
		label: 'Schweiz',
	},
	{
		value: 'SY',
		label: 'Syrische Arabische Republik',
	},
	{
		value: 'TW',
		label: 'Taiwan, Provinz Chinas',
	},
	{
		value: 'TJ',
		label: 'Tadschikistan',
	},
	{
		value: 'TZ',
		label: 'Tansania, Vereinigte Republik',
	},
	{
		value: 'TH',
		label: 'Thailand',
	},
	{
		value: 'TL',
		label: 'Timor-Leste',
	},
	{
		value: 'TG',
		label: 'Gehen',
	},
	{
		value: 'TK',
		label: 'Tokelau',
	},
	{
		value: 'TO',
		label: 'Tonga',
	},
	{
		value: 'TT',
		label: 'Trinidad und Tobago',
	},
	{
		value: 'TN',
		label: 'Tunesien',
	},
	{
		value: 'TR',
		label: 'Türkei',
	},
	{
		value: 'TM',
		label: 'Turkmenistan',
	},
	{
		value: 'TC',
		label: 'Turks- und Caicosinseln',
	},
	{
		value: 'TV',
		label: 'Tuvalu',
	},
	{
		value: 'UG',
		label: 'Uganda',
	},
	{
		value: 'UA',
		label: 'Ukraine',
	},
	{
		value: 'AE',
		label: 'Vereinigte Arabische Emirate',
	},
	{
		value: 'GB',
		label: 'Vereinigtes Königreich',
	},
	{
		value: 'US',
		label: 'Vereinigte Staaten',
	},
	{
		value: 'UM',
		label: 'Kleinere abgelegene Inseln der Vereinigten Staaten',
	},
	{
		value: 'UY',
		label: 'Uruguay',
	},
	{
		value: 'UZ',
		label: 'Usbekistan',
	},
	{
		value: 'VU',
		label: 'Vanuatu',
	},
	{
		value: 'VE',
		label: 'Venezuela',
	},
	{
		value: 'VN',
		label: 'Vietnam',
	},
	{
		value: 'VG',
		label: 'Virgin Inseln, Britisch',
	},
	{
		value: 'VI',
		label: 'Jungferninseln, USA',
	},
	{
		value: 'WF',
		label: 'Wallis und Futuna',
	},
	{
		value: 'EH',
		label: 'Westsahara',
	},
	{
		value: 'YE',
		label: 'Jemen',
	},
	{
		value: 'ZM',
		label: 'Sambia',
	},
	{
		value: 'ZW',
		label: 'Zimbabwe',
	},
];