import './index.scss';

function Variant (props) {
	return (
		<div className={ props.class }>
			{ props.text }
		</div>
	);
}

Variant.defaultProps = {
	class: 'variant t2',
	text: '',
};

export default Variant;