import { Button, Col, Divider, Form, Input, Row, Upload, notification, Affix } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import React, { useState, useCallback, useEffect } from 'react';
import { apiDomain, findTranslationOrFallback, isValidUploadFormat, languages } from 'utils/constants';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import LanguageInput from 'components/forms/language-input';
import LanguageSwitcher from 'components/core/language-switcher';
import TextEditor from 'components/forms/text-editor';
import axios from 'axios';
import './index.scss';

function SettingsClientData () {
	const [logoHeader, setLogoHeader] = useState([]);
	const [logoFooter, setLogoFooter] = useState([]);
	const [imageContactPerson, setImageContactPerson] = useState([]);
	const [data, setData] = useState(null);
	const { accessToken, user } = useAuth();
	const [removeList, setRemoveList] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	const [language, setLanguage] = useState(languages.find(lang => lang.default).key);
	const { t } = useTranslation();
	const [form] = Form.useForm();
	
	const fetchData = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'settings/client-data', {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setData(response);
				if (response.logoHeaderName) {
					setLogoHeader([{
						index: 1,
						name: 'Bild ' + 1,
						fileName: response.logoHeaderName,
						thumbUrl: apiDomain() + response.logoHeaderPath,
						url: apiDomain() + response.logoHeaderPath,
						existingImage: true,
					}]);
				}
				if (response.logoFooterName) {
					setLogoFooter([{
						index: 2,
						name: 'Bild ' + 2,
						fileName: response.logoFooterName,
						thumbUrl: apiDomain() + response.logoFooterPath,
						url: apiDomain() + response.logoFooterPath,
						existingImage: true,
					}]);
				}
				if (response.imageContactPersonName) {
					setImageContactPerson([{
						index: 3,
						name: 'Bild ' + 3,
						fileName: response.imageContactPersonName,
						thumbUrl: apiDomain() + response.imageContactPersonPath,
						url: apiDomain() + response.imageContactPersonPath,
						existingImage: true,
					}]);
				}
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken]);
	
	const onSubmit = async (values) => {
		try {
			const data = new FormData();
			logoHeader.forEach((file, i) => {
				if (!file.existingImage) {
					data.append('logoHeader', file, file.name);
				}
			});
			logoFooter.forEach((file, i) => {
				if (!file.existingImage) {
					data.append('logoFooter', file, file.name);
				}
			});
			imageContactPerson.forEach((file, i) => {
				if (!file.existingImage) {
					data.append('imageContactPerson', file, file.name);
				}
			});
			
			let fileToDeleteArray = [];
			removeList.forEach((file, i) => {
				fileToDeleteArray.push(file.fileName);
			});
			
			if (fileToDeleteArray.length > 0) {
				data.append('filesToDelete', fileToDeleteArray.join('|'));
			}
			
			Object.keys(values).forEach(function(key,index) {
				data.append(key, values[key]);
			});
			
			data.append('hotelId', user.hotelId);
			
			await axios.put(apiDomain() + 'settings/client-data', data, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			api['success']({
				message: t('general.saveSuccess'),
				description: t('general.saveSuccessText'),
				placement: 'bottomRight',
				duration: 3,
			});
			fetchData();
			setRemoveList([]);
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	const beforeUploadLogoHeader = () => {
		return false;
	};
	
	const onChangeHandleLogoHeader = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t, true)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		setLogoHeader(tempFiles);
	};
	
	const onRemoveLogoHeader = (file) => {
		const index = logoHeader.indexOf(file);
		const newFileList = logoHeader.slice();
		newFileList.splice(index, 1);
		setLogoHeader(newFileList);
		setRemoveList([...removeList, file]);
	};
	
	const beforeUploadLogoFooter = () => {
		return false;
	};
	
	const onChangeHandleLogoFooter = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t, true)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		setLogoFooter(tempFiles);
	};
	
	const onRemoveLogoFooter = (file) => {
		const index = logoFooter.indexOf(file);
		const newFileList = logoFooter.slice();
		newFileList.splice(index, 1);
		setLogoFooter(newFileList);
		setRemoveList([...removeList, file]);
	};
	
	const beforeUploadImageContactPerson = () => {
		return false;
	};
	
	const onChangeHandleImageContactPerson = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t, true)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		setImageContactPerson(tempFiles);
	};
	
	const onRemoveImageContactPerson = (file) => {
		const index = imageContactPerson.indexOf(file);
		const newFileList = imageContactPerson.slice();
		newFileList.splice(index, 1);
		setImageContactPerson(newFileList);
		setRemoveList([...removeList, file]);
	};
	
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				{t('clientData.upload')}
			</div>
		</div>
	);
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	useEffect(() => {
		if (form && data) {
			form.setFieldValue('cap', data.cap ? data.cap : '');
			form.setFieldValue('email', data.email ? data.email : '');
			form.setFieldValue('phone', data.phone ? data.phone : '');
			form.setFieldValue('whatsapp', data.whatsapp ? data.whatsapp : '');
			form.setFieldValue('website', data.website ? data.website : '');
			form.setFieldValue('facebook', data.facebook ? data.facebook : '');
			form.setFieldValue('instagram', data.instagram ? data.instagram : '');
			form.setFieldValue('linkedin', data.linkedin ? data.linkedin : '');
			form.setFieldValue('gmapUrl', data.gmapUrl ? data.gmapUrl : '');
			form.setFieldValue('language', data.language ? data.language : '');
			form.setFieldValue('contactPersonName', data.contactPersonName ? data.contactPersonName : '');
			languages.forEach(lang => {
				form.setFieldValue(('name' + lang.key), findTranslationOrFallback(data.translations, 'name', lang.key));
				form.setFieldValue(('nameAdditional' + lang.key), findTranslationOrFallback(data.translations, 'nameAdditional', lang.key));
				form.setFieldValue(('place' + lang.key), findTranslationOrFallback(data.translations, 'place', lang.key));
				form.setFieldValue(('address' + lang.key), findTranslationOrFallback(data.translations, 'address', lang.key));
				form.setFieldValue(('province' + lang.key), findTranslationOrFallback(data.translations, 'province', lang.key));
				form.setFieldValue(('country' + lang.key), findTranslationOrFallback(data.translations, 'country', lang.key));
				// form.setFieldValue(('additionalText' + lang.key), findTranslationOrFallback(data.translations, 'additionalText', lang.key));
				form.setFieldValue(('contactPersonShortIntro1' + lang.key), findTranslationOrFallback(data.translations, 'contactPersonShortIntro1', lang.key));
				form.setFieldValue(('contactPersonShortIntro2' + lang.key), findTranslationOrFallback(data.translations, 'contactPersonShortIntro2', lang.key));
				form.setFieldValue(('contactPersonHeadline' + lang.key), findTranslationOrFallback(data.translations, 'contactPersonHeadline', lang.key));
				form.setFieldValue(('contactPersonText' + lang.key), findTranslationOrFallback(data.translations, 'contactPersonText', lang.key));
				form.setFieldValue(('contactPersonJob' + lang.key), findTranslationOrFallback(data.translations, 'contactPersonJob', lang.key));
			});
		}
	}, [data,form]);
	
	return (
		<div className="settings-client-data">
			{contextHolder}
			{ data &&
				<Form
					layout={'vertical'}
					onFinish={onSubmit}
					form={form}
				>
					<div className="headline-container">
						<Title level={3}>{t('clientData.clientData')}</Title>
						<LanguageSwitcher
							currentLanguage={language}
							onSwitch={(lang) => setLanguage(lang)}
						/>
					</div>
					<Divider/>
					<br />
					<Input.Group>
						<Row gutter={8}>
							<Col span={8}>
								<div className="label">{t('clientData.logoHeader')}</div>
								<Upload
									listType="picture-card"
									fileList={logoHeader}
									beforeUpload={beforeUploadLogoHeader}
									onRemove={onRemoveLogoHeader}
									onChange={onChangeHandleLogoHeader}
								>
									{logoHeader.length >= 1 ? null : uploadButton}
								</Upload>
							</Col>
							<Col span={8}>
								<div className="label">{t('clientData.logoFooter')}</div>
								<Upload
									listType="picture-card"
									fileList={logoFooter}
									beforeUpload={beforeUploadLogoFooter}
									onRemove={onRemoveLogoFooter}
									onChange={onChangeHandleLogoFooter}
								>
									{logoFooter.length >= 1 ? null : uploadButton}
								</Upload>
							</Col>
						</Row>
						<Divider/>
						<Row gutter={8}>
							<Col span={8}>
								<LanguageInput
									label={t('clientData.name')}
									name="name"
									currentLanguage={language}
								/>
							</Col>
							<Col span={8}>
								<LanguageInput
									label={t('clientData.additionalName')}
									name="nameAdditional"
									currentLanguage={language}
								/>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={8}>
								<LanguageInput
									label={t('clientData.place')}
									name="place"
									currentLanguage={language}
								/>
							</Col>
							<Col span={8}>
								<LanguageInput
									label={t('clientData.address')}
									name="address"
									currentLanguage={language}
								/>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={8}>
								<Form.Item label={t('clientData.cap')} name="cap">
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<LanguageInput
									label={t('clientData.province')}
									name="province"
									currentLanguage={language}
								/>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={16}>
								<LanguageInput
									label={t('clientData.country')}
									name="country"
									currentLanguage={language}
								/>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={8}>
								<Form.Item label={t('clientData.email')} name="email">
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label={t('clientData.phone')} name="phone">
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={8}>
								<Form.Item label={t('clientData.whatsapp')} name="whatsapp">
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label={t('clientData.website')} name="website">
									<Input/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={8}>
								<Form.Item label={t('clientData.gmapUrl')} name="gmapUrl">
									<Input />
								</Form.Item>
							</Col>
							{ user?.isAdmin &&
								<Col span={8}>
									<Form.Item label={t('clientData.language')} name="language">
										<Input />
									</Form.Item>
								</Col>
							}
						</Row>
						<Row gutter={8}>
							<Col span={16}>
								<div className="field">
									<div className="label">{t('clientData.additionalText')}</div>
									<TextEditor
										formItemName={'additionalText'}
										form={form}
										language={language}
										translations={data.translations}
									/>
								</div>
							</Col>
						</Row>
					</Input.Group>
					<Divider/>
					<h2>{t('clientData.contactPerson')}</h2>
					<Input.Group>
						<Row gutter={8}>
							<Col span={8}>
								<div className="label">{t('clientData.imageContactPerson')}</div>
								<Upload
									listType="picture-card"
									fileList={imageContactPerson}
									beforeUpload={beforeUploadImageContactPerson}
									onRemove={onRemoveImageContactPerson}
									onChange={onChangeHandleImageContactPerson}
								>
									{imageContactPerson.length >= 1 ? null : uploadButton}
								</Upload>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={8}>
								<Form.Item label={t('clientData.contactPersonName')} name="contactPersonName">
									<Input/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<LanguageInput
									label={t('clientData.contactPersonJob')}
									name="contactPersonJob"
									currentLanguage={language}
								/>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={8}>
								<LanguageInput
									label={t('clientData.contactPersonShortIntro1')}
									name="contactPersonShortIntro1"
									currentLanguage={language}
								/>
							</Col>
							<Col span={8}>
								<LanguageInput
									label={t('clientData.contactPersonShortIntro2')}
									name="contactPersonShortIntro2"
									currentLanguage={language}
								/>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={8}>
								<LanguageInput
									label={t('clientData.contactPersonHeadline')}
									name="contactPersonHeadline"
									currentLanguage={language}
								/>
							</Col>
							<Col span={8}>
								<LanguageInput
									label={t('clientData.contactPersonText')}
									name="contactPersonText"
									currentLanguage={language}
								/>
							</Col>
						</Row>
					</Input.Group>
					<Divider/>
					<h2>{t('clientData.socials')}</h2>
					<Input.Group>
						<Row gutter={8}>
							<Col span={8}>
								<Form.Item label={t('clientData.facebook')} name="facebook">
									<Input/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label={t('clientData.instagram')} name="instagram">
									<Input/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={8}>
								<Form.Item label={t('clientData.linkedin')} name="linkedin">
									<Input/>
								</Form.Item>
							</Col>
						</Row>
					</Input.Group>
					<Divider />
					<br />
					<Affix offsetBottom={0} className="sticky-save-container">
						<div className="content">
							<Form.Item>
								<Button type="primary" htmlType="submit">{t('clientData.save')}</Button>
							</Form.Item>
							<LanguageSwitcher
								currentLanguage={language}
								onSwitch={(lang) => setLanguage(lang)}
							/>
						</div>
					</Affix>
				</Form>
			}
		</div>
	);
};

export default SettingsClientData;