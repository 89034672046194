import { Affix, Button, Divider, Form, notification } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import LanguageSwitcher from 'components/core/language-switcher';
import HeaderT1 from './modules/headert1';
import TeaserT2 from './modules/teasert2';
import FooterGalleryT1 from './modules/footergalleryt1';
import { useAuth } from 'hooks/useAuth';
import { apiDomain } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './index.scss';

function SettingsContentLandingpage ({ language, onLanguageChange }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { accessToken, user } = useAuth();
	const [content, setContent] = useState([]);
	const [imagesHeaderT1, setImagesHeaderT1] = useState([]);
	const [imagesTeaserT2, setImagesTeaserT2] = useState([[], [], []]);
	const [imagesFooterGalleryT1, setImagesFooterGalleryT1] = useState([]);
	const [removeListHeaderT1, setRemoveListHeaderT1] = useState([]);
	const [removeListTeaserT2, setRemoveListTeaserT2] = useState([]);
	const [removeListFooterGalleryT1, setRemoveListFooterGalleryT1] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	
	const onSubmit = async (values) => {
		try {
			const data = new FormData();
			imagesHeaderT1.forEach((file) => {
				if (!file.existingImage) {
					data.append('headert1', file, file.name);
				}
			});
			imagesTeaserT2.forEach((image, i) => {
				image.forEach((file) => {
					if (!file.existingImage) {
						data.append('teasert2_' + (i + 1), file, file.name);
					}
				});
			});
			imagesFooterGalleryT1.forEach((file) => {
				if (!file.existingImage) {
					data.append('footergalleryt1', file, file.name);
				}
			});
			
			let fileToDeleteArray = [];
			removeListHeaderT1.forEach((file) => {
				fileToDeleteArray.push(file.fileName);
			});
			removeListTeaserT2.forEach((file) => {
				fileToDeleteArray.push(file.fileName);
			});
			removeListFooterGalleryT1.forEach((file) => {
				fileToDeleteArray.push(file.fileName);
			});
			if (fileToDeleteArray.length > 0) {
				data.append('filesToDelete', fileToDeleteArray.join('|'));
			}
			
			let imagesHeaderT1sorting = [];
			imagesHeaderT1.forEach((file, i) => {
				if (file.fileName !== undefined) {
					imagesHeaderT1sorting.push(file.fileName + '_|_' + i);
				}
			});
			if (imagesHeaderT1sorting.length > 0) {
				data.append('imagesHeaderT1sorting', imagesHeaderT1sorting.join('||'));
			}
			
			let imagesFooterGalleryT1sorting = [];
			imagesFooterGalleryT1.forEach((file, i) => {
				if (file.fileName !== undefined) {
					imagesFooterGalleryT1sorting.push(file.fileName + '_|_' + i);
				}
			});
			if (imagesFooterGalleryT1sorting.length > 0) {
				data.append('imagesFooterGalleryT1sorting', imagesFooterGalleryT1sorting.join('||'));
			}
			
			data.append('hotelId', user.hotelId);
			
			Object.keys(values).forEach(function(key,index) {
				data.append(key, values[key]);
			});
			
			await axios.put(apiDomain() + 'content', data, {
				headers: {
					'x-access-token': accessToken,
				},
			}).then(done => {
				api['success']({
					message: t('general.saveSuccess'),
					description: t('general.saveSuccessText'),
					placement: 'bottomRight',
					duration: 3,
				});
				setImagesHeaderT1([]);
				setImagesTeaserT2([[], [], []]);
				setImagesFooterGalleryT1([]);
				setRemoveListHeaderT1([]);
				setRemoveListTeaserT2([]);
				setRemoveListFooterGalleryT1([]);
				fetchData();
			});
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	const setTeaserT2Images = (images,count) => {
		let tmpArray = [imagesTeaserT2[0], imagesTeaserT2[1], imagesTeaserT2[2]];
		tmpArray[count - 1] = images;
		setImagesTeaserT2(tmpArray);
	};
	
	const setImagesHeaderT1Sorting = (images) => {
		setImagesHeaderT1(images ? images : []);
	};
	
	const setImagesFooterGalleryT1Sorting = (images) => {
		setImagesFooterGalleryT1(images ? images : []);
	};
	
	const fetchData = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'content/' + user.hotelId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setContent(response);
				let headerImages = response.contentImages?.filter(image => image.moduleId === 'headert1').map((file, i) => {
					return {
						index: i,
						name: 'Bild ' + i,
						fileName: file.name,
						thumbUrl: apiDomain() + file.path,
						url: apiDomain() + file.path,
						existingImage: true,
						order: file.order,
					};
				}).sort((a, b) => {
					if (a.order === null) {
						return 1;
					}
					if (b.order === null) {
						return -1;
					}
					if (a.order === b.order) {
						return 0;
					}
					return a.order < b.order ? -1 : 1;
				});
				setImagesHeaderT1(headerImages ? headerImages : []);
				let teaserT2Images = [[], [], []];
				for (let i = 1; i <= 3; i++) {
					let tmpImage = response.contentImages?.filter(image => image.moduleId === 'teasert2_' + i);
					if (tmpImage && tmpImage.length) {
						teaserT2Images[i - 1] = (tmpImage.map((file, i) => {
							return {
								index: i,
								name: 'Bild ' + i,
								fileName: file.name,
								thumbUrl: apiDomain() + file.path,
								url: apiDomain() + file.path,
								existingImage: true,
							};
						}));
					}
				}
				setImagesTeaserT2(teaserT2Images);
				let footerImages = response.contentImages?.filter(image => image.moduleId === 'footergalleryt1').map((file, i) => {
					return {
						index: i,
						name: 'Bild ' + i,
						fileName: file.name,
						thumbUrl: apiDomain() + file.path,
						url: apiDomain() + file.path,
						existingImage: true,
						order: file.order,
					};
				}).sort((a, b) => {
					if (a.order === null) {
						return 1;
					}
					if (b.order === null) {
						return -1;
					}
					if (a.order === b.order) {
						return 0;
					}
					return a.order < b.order ? -1 : 1;
				});
				setImagesFooterGalleryT1(footerImages ? footerImages : []);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken, user.hotelId]);
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	return (
		<div className="settings-content-landingpage">
			{contextHolder}
			{ content &&
				<Form
					layout={'vertical'}
					onFinish={onSubmit}
					form={form}
				>
					<div className="modules">
						<HeaderT1
							images={imagesHeaderT1 ? imagesHeaderT1 : []}
							removeList={removeListHeaderT1}
							onAddImages={(images) => setImagesHeaderT1(images ? images : [])}
							onRemoveImages={(images) => setRemoveListHeaderT1(images ? images : [])}
							onChangeImages={(images) => setImagesHeaderT1Sorting(images ? images : [])}
						/>
						<TeaserT2
							content={content}
							form={form}
							language={language}
							images={imagesTeaserT2 ? imagesTeaserT2 : []}
							removeList={removeListTeaserT2}
							onAddImages={(images,count) => setTeaserT2Images(images ? images : [],count)}
							onRemoveImages={(images) => setRemoveListTeaserT2(images ? images : [])}
						/>
						<FooterGalleryT1
							content={content}
							form={form}
							language={language}
							images={imagesFooterGalleryT1 ? imagesFooterGalleryT1 : []}
							removeList={removeListFooterGalleryT1}
							onAddImages={(images) => setImagesFooterGalleryT1(images ? images : [])}
							onRemoveImages={(images) => setRemoveListFooterGalleryT1(images ? images : [])}
							onChangeImages={(images) => setImagesFooterGalleryT1Sorting(images ? images : [])}
						/>
					</div>
					<Divider/>
					<br />
					<Affix offsetBottom={0} className="sticky-save-container">
						<div className="content">
							<Form.Item>
								<Button type="primary" htmlType="submit">{t('content.lp.general.save')}</Button>
							</Form.Item>
							<LanguageSwitcher
								currentLanguage={language}
								onSwitch={(lang) => onLanguageChange(lang)}
							/>
						</div>
					</Affix>
				</Form>
			}
		</div>
	);
};

export default SettingsContentLandingpage;