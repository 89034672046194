import { RoutePaths, apiDomain } from 'utils/constants';
import Icon from 'pages/offer/components/core/icon';
import '@splidejs/react-splide/css';
import './index.scss';

function ModuleHeaderSmallT1 ({ offer }) {
	return (
		<div className="module-header-small-t1">
			{ offer.hotelInfos?.logoFooterPath &&
				<div className="logo">
					{ offer.hotelInfos?.logoFooterPath &&
						<a href={RoutePaths.offer.index.replace(':offerUid', offer.uid)}><img src={apiDomain() + offer.hotelInfos.logoFooterPath} alt="" /></a>
					}
				</div>
			}
			{ offer.hotelInfos?.phone &&
				<div className="tel df ac" onClick={() => window.location = ('tel:' + offer.hotelInfos.phone)}>
					<Icon
						icon="tel"
						width="31"
						height="31"
					/>
					<div className="number t2">{offer.hotelInfos.phone}</div>
				</div>
			}
		</div>
	);
};

export default ModuleHeaderSmallT1;