import { Affix, Button, Divider, Form, Upload, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useCallback } from 'react';
import LanguageInput from 'components/forms/language-input';
import TextEditor from 'components/forms/text-editor';
import Title from 'antd/es/typography/Title';
import { useAuth } from 'hooks/useAuth';
import { apiDomain, findTranslationOrFallback, isValidUploadFormat, languages } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './index.scss';
import LanguageSwitcher from 'components/core/language-switcher';

function SettingsContentMails ({ language, onLanguageChange }) {
	const [imageCreation, setImageCreation] = useState([]);
	const [imageUpdate, setImageUpdate] = useState([]);
	const [imageBooked, setImageBooked] = useState([]);
	const [imageReminder, setImageReminder] = useState([]);
	const [imageAnticipations, setImageAnticipations] = useState([]);
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { accessToken, user } = useAuth();
	const [removeList, setRemoveList] = useState([]);
	const [content, setContent] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	
	const onSubmit = async (values) => {
		const data = new FormData();
		imageCreation.forEach((file, i) => {
			if (!file.existingImage) {
				data.append('imageCreation', file, file.name);
			}
		});
		
		imageUpdate.forEach((file, i) => {
			if (!file.existingImage) {
				data.append('imageUpdate', file, file.name);
			}
		});
		
		imageBooked.forEach((file, i) => {
			if (!file.existingImage) {
				data.append('imageBooked', file, file.name);
			}
		});
		
		imageReminder.forEach((file, i) => {
			if (!file.existingImage) {
				data.append('imageReminder', file, file.name);
			}
		});
		
		imageAnticipations.forEach((file, i) => {
			if (!file.existingImage) {
				data.append('imageAnticipations', file, file.name);
			}
		});
		
		let fileToDeleteArray = [];
		removeList.forEach((file, i) => {
			fileToDeleteArray.push(file.fileName);
		});
		
		if (fileToDeleteArray.length > 0) {
			data.append('filesToDelete', fileToDeleteArray.join('|'));
		}
		
		Object.keys(values).forEach(function(key,index) {
			data.append(key, values[key]);
		});
		
		data.append('hotelId', user.hotelId);
		
		try {
			await axios.put(apiDomain() + 'mails', data, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			api['success']({
				message: t('general.saveSuccess'),
				description: t('general.saveSuccessText'),
				placement: 'bottomRight',
				duration: 3,
			});
			fetchData();
			setRemoveList([]);
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	const fetchData = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'mails/', {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setContent(response);
				if (response.imageCreationName) {
					setImageCreation([{
						index: 1,
						name: 'Bild ' + 1,
						fileName: response.imageCreationName,
						thumbUrl: apiDomain() + response.imageCreationPath,
						url: apiDomain() + response.imageCreationPath,
						existingImage: true,
					}]);
				}
				if (response.imageUpdateName) {
					setImageUpdate([{
						index: 1,
						name: 'Bild ' + 1,
						fileName: response.imageUpdateName,
						thumbUrl: apiDomain() + response.imageUpdatePath,
						url: apiDomain() + response.imageUpdatePath,
						existingImage: true,
					}]);
				}
				if (response.imageBookedName) {
					setImageBooked([{
						index: 1,
						name: 'Bild ' + 1,
						fileName: response.imageBookedName,
						thumbUrl: apiDomain() + response.imageBookedPath,
						url: apiDomain() + response.imageBookedPath,
						existingImage: true,
					}]);
				}
				if (response.imageReminderName) {
					setImageReminder([{
						index: 1,
						name: 'Bild ' + 1,
						fileName: response.imageReminderName,
						thumbUrl: apiDomain() + response.imageReminderPath,
						url: apiDomain() + response.imageReminderPath,
						existingImage: true,
					}]);
				}
				if (response.imageAnticipationsName) {
					setImageAnticipations([{
						index: 1,
						name: 'Bild ' + 1,
						fileName: response.imageAnticipationsName,
						thumbUrl: apiDomain() + response.imageAnticipationsPath,
						url: apiDomain() + response.imageAnticipationsPath,
						existingImage: true,
					}]);
				}
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken]);
	
	useEffect(() => {
		languages.forEach(lang => {
			form.setFieldValue(('offer_headline_creation' + lang.key), findTranslationOrFallback(content.mailTranslations, 'offer_headline_creation', lang.key));
			form.setFieldValue(('offer_headline_update' + lang.key), findTranslationOrFallback(content.mailTranslations, 'offer_headline_update', lang.key));
			form.setFieldValue(('offer_headline_booked' + lang.key), findTranslationOrFallback(content.mailTranslations, 'offer_headline_booked', lang.key));
			form.setFieldValue(('offer_headline_reminder' + lang.key), findTranslationOrFallback(content.mailTranslations, 'offer_headline_reminder', lang.key));
			form.setFieldValue(('offer_headline_anticipations' + lang.key), findTranslationOrFallback(content.mailTranslations, 'offer_headline_anticipations', lang.key));
		});
	}, [content, form]);
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	const beforeUploadImageCreation = () => {
		return false;
	};
	
	const onChangeHandleImageCreation = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t, true)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		setImageCreation(tempFiles);
	};
	
	const onRemoveImageCreation = (file) => {
		const index = imageCreation.indexOf(file);
		const newFileList = imageCreation.slice();
		newFileList.splice(index, 1);
		setImageCreation(newFileList);
		setRemoveList([...removeList, file]);
	};
	
	const beforeUploadImageUpdate = () => {
		return false;
	};
	
	const onChangeHandleImageUpdate = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t, true)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		setImageUpdate(tempFiles);
	};
	
	const onRemoveImageUpdate = (file) => {
		const index = imageUpdate.indexOf(file);
		const newFileList = imageUpdate.slice();
		newFileList.splice(index, 1);
		setImageUpdate(newFileList);
		setRemoveList([...removeList, file]);
	};
	
	const beforeUploadImageBooked = () => {
		return false;
	};
	
	const onChangeHandleImageBooked = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t, true)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		setImageBooked(tempFiles);
	};
	
	const onRemoveImageBooked = (file) => {
		const index = imageBooked.indexOf(file);
		const newFileList = imageBooked.slice();
		newFileList.splice(index, 1);
		setImageBooked(newFileList);
		setRemoveList([...removeList, file]);
	};
	
	const onChangeHandleImageReminder = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t, true)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		setImageReminder(tempFiles);
	};
	
	const onRemoveImageReminder = (file) => {
		const index = imageReminder.indexOf(file);
		const newFileList = imageReminder.slice();
		newFileList.splice(index, 1);
		setImageReminder(newFileList);
		setRemoveList([...removeList, file]);
	};
	
	const beforeUploadImageReminder = () => {
		return false;
	};
	
	const onChangeHandleImageAnticipations = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (file.hasOwnProperty('existingImage')) {
				tempFiles.push(file);
			} else {
				if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t, true)) {
					tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
				}
			}
		});
		setImageAnticipations(tempFiles);
	};
	
	const onRemoveImageAnticipations = (file) => {
		const index = imageAnticipations.indexOf(file);
		const newFileList = imageAnticipations.slice();
		newFileList.splice(index, 1);
		setImageAnticipations(newFileList);
		setRemoveList([...removeList, file]);
	};
	
	const beforeUploadImageAnticipations = () => {
		return false;
	};
	
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				{t('content.lp.general.upload')}
			</div>
		</div>
	);
	
	return (
		<div className="settings-content-mails">
			{contextHolder}
			{ content &&
				<Form
					layout={'vertical'}
					onFinish={onSubmit}
					form={form}
				>
					<div className="modules">
						<div className="offer-mail">
							<div className="block">
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('content.mails.offer_create')}
									</Title>
								</Divider>
								<div className="fields">
									<LanguageInput
										label={t('content.lp.general.headline')}
										name="offer_headline_creation"
										currentLanguage={language}
									/>
									<div className="field">
										<div className="label">{t('content.lp.general.introText')}</div>
										<TextEditor
											formItemName="offer_introText_creation"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.clientName')}</div>
										<TextEditor
											formItemName="offer_clientName_creation"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.text')}</div>
										<TextEditor
											formItemName="offer_text_creation"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.image')}</div>
										<Upload
											listType="picture-card"
											fileList={imageCreation}
											beforeUpload={beforeUploadImageCreation}
											onRemove={onRemoveImageCreation}
											onChange={onChangeHandleImageCreation}
										>
											{imageCreation.length >= 1 ? null : uploadButton}
										</Upload>
									</div>
								</div>
							</div>
							<div className="block">
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('content.mails.offer_update')}
									</Title>
								</Divider>
								<div className="fields">
									<LanguageInput
										label={t('content.lp.general.headline')}
										name="offer_headline_update"
										currentLanguage={language}
									/>
									<div className="field">
										<div className="label">{t('content.lp.general.introText')}</div>
										<TextEditor
											formItemName="offer_introText_update"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.clientName')}</div>
										<TextEditor
											formItemName="offer_clientName_update"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.text')}</div>
										<TextEditor
											formItemName="offer_text_update"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.image')}</div>
										<Upload
											listType="picture-card"
											fileList={imageUpdate}
											beforeUpload={beforeUploadImageUpdate}
											onRemove={onRemoveImageUpdate}
											onChange={onChangeHandleImageUpdate}
										>
											{imageUpdate.length >= 1 ? null : uploadButton}
										</Upload>
									</div>
								</div>
							</div>
							<div className="block">
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('content.mails.offer_booked')}
									</Title>
								</Divider>
								<div className="fields">
									<LanguageInput
										label={t('content.lp.general.headline')}
										name="offer_headline_booked"
										currentLanguage={language}
									/>
									<div className="field">
										<div className="label">{t('content.lp.general.introText')}</div>
										<TextEditor
											formItemName="offer_introText_booked"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.clientName')}</div>
										<TextEditor
											formItemName="offer_clientName_booked"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.text')}</div>
										<TextEditor
											formItemName="offer_text_booked"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.image')}</div>
										<Upload
											listType="picture-card"
											fileList={imageBooked}
											beforeUpload={beforeUploadImageBooked}
											onRemove={onRemoveImageBooked}
											onChange={onChangeHandleImageBooked}
										>
											{imageBooked.length >= 1 ? null : uploadButton}
										</Upload>
									</div>
								</div>
							</div>
							<div className="block">
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('content.mails.offer_reminder')}
									</Title>
								</Divider>
								<div className="fields">
									<LanguageInput
										label={t('content.lp.general.headline')}
										name="offer_headline_reminder"
										currentLanguage={language}
									/>
									<div className="field">
										<div className="label">{t('content.lp.general.introText')}</div>
										<TextEditor
											formItemName="offer_introText_reminder"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.clientName')}</div>
										<TextEditor
											formItemName="offer_clientName_reminder"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.text')}</div>
										<TextEditor
											formItemName="offer_text_reminder"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.image')}</div>
										<Upload
											listType="picture-card"
											fileList={imageReminder}
											beforeUpload={beforeUploadImageReminder}
											onRemove={onRemoveImageReminder}
											onChange={onChangeHandleImageReminder}
										>
											{imageReminder.length >= 1 ? null : uploadButton}
										</Upload>
									</div>
								</div>
							</div>
							<div className="block">
								<Divider orientation="left" orientationMargin="0">
									<Title level={4}>
										{t('content.mails.offer_anticipations')}
									</Title>
								</Divider>
								<div className="fields">
									<LanguageInput
										label={t('content.lp.general.headline')}
										name="offer_headline_anticipations"
										currentLanguage={language}
									/>
									<div className="field">
										<div className="label">{t('content.lp.general.introText')}</div>
										<TextEditor
											formItemName="offer_introText_anticipations"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.clientName')}</div>
										<TextEditor
											formItemName="offer_clientName_anticipations"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.text')}</div>
										<TextEditor
											formItemName="offer_text_anticipations"
											form={form}
											language={language}
											translations={content.mailTranslations}
										/>
									</div>
									<div className="field">
										<div className="label">{t('content.lp.general.image')}</div>
										<Upload
											listType="picture-card"
											fileList={imageAnticipations}
											beforeUpload={beforeUploadImageAnticipations}
											onRemove={onRemoveImageAnticipations}
											onChange={onChangeHandleImageAnticipations}
										>
											{imageAnticipations.length >= 1 ? null : uploadButton}
										</Upload>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Divider/>
					<br />
					<Affix offsetBottom={0} className="sticky-save-container">
						<div className="content">
							<Form.Item>
								<Button type="primary" htmlType="submit">{t('content.lp.general.save')}</Button>
							</Form.Item>
							<LanguageSwitcher
								currentLanguage={language}
								onSwitch={(lang) => onLanguageChange(lang)}
							/>
						</div>
					</Affix>
				</Form>
			}
		</div>
	);
};

export default SettingsContentMails;