import { message } from 'antd';

const offerHomeBasePath = '/offer';
const loginBasePath = '/';
const requestBasePath = '/requests';
const bookingsBasePath = '/bookings';
const roomsBasePath = '/rooms';
const contentBasePath = '/content';
const settingsBasePath = '/settings';

export const RoutePaths = {
	offer: {
		index: offerHomeBasePath + '/:offerUid',
		privacy: offerHomeBasePath + '/:offerUid/privacy',
		credits: offerHomeBasePath + '/:offerUid/credits',
		agbs: offerHomeBasePath + '/:offerUid/agbs',
	},
	login: {
		index: loginBasePath,
	},
	requests: {
		index: requestBasePath,
		create: requestBasePath + '/create',
		detail: requestBasePath + '/:requestId',
	},
	bookings: {
		index: bookingsBasePath,
		detail: bookingsBasePath + '/:requestId',
	},
	rooms: {
		index: roomsBasePath,
		detail: roomsBasePath + '/:roomId',
	},
	content: {
		index: contentBasePath,
		usp: {
			index: contentBasePath + '/usp',
			detail: contentBasePath + '/usp/:uspId',
		},
	},
	settings: {
		index: settingsBasePath,
		clientData: settingsBasePath + '/client-data',
		styles: settingsBasePath + '/styles',
		export: settingsBasePath + '/export',
		import: settingsBasePath + '/import',
		changeClient: settingsBasePath + '/change-client',
		privacy: settingsBasePath + '/privacy',
		fallbackPrivacy: settingsBasePath + '/fallback-privacy',
		agb: settingsBasePath + '/agb',
		fallbackAgb: settingsBasePath + '/fallback-agb',
	},
};

export const defaultStyles = {
	bgweb_backgroundColor: '#F2F2F2',
	bgteaser_backgroundColor: '#FFFFFF',
	bghighlight_backgroundColor: '#65373A',
	t1Large_color: '#FFFFFF',
	t1Large_fontFamily: 'opensans_semibold',
	t1Large_textTransform: 'none',
	t1Large_letterSpacing: 0,
	t1Large_fontSizeDesktop: 55,
	t1Large_lineHeightDesktop: 75,
	t1Large_fontSizeMobile: 25,
	t1Large_lineHeightMobile: 45,
	t2Large_color: '#FFFFFF',
	t2Large_fontFamily: 'opensans_semibold',
	t2Large_textTransform: 'none',
	t2Large_letterSpacing: 0.02,
	t2Large_fontSizeDesktop: 25,
	t2Large_lineHeightDesktop: 30,
	t2Large_fontSizeMobile: 14,
	t2Large_lineHeightMobile: 25,
	h1_color: '#000000',
	h1_fontFamily: 'opensans_bold',
	h1_textTransform: 'none',
	h1_letterSpacing: 0,
	h1_fontSizeDesktop: 35,
	h1_lineHeightDesktop: 50,
	h1_fontSizeMobile: 20,
	h1_lineHeightMobile: 28,
	h2_color: '#000000',
	h2_fontFamily: 'opensans_semibold',
	h2_textTransform: 'uppercase',
	h2_letterSpacing: 0,
	h2_fontSizeDesktop: 20,
	h2_lineHeightDesktop: 30,
	h2_fontSizeMobile: 14,
	h2_lineHeightMobile: 20,
	h3_color: '#000000',
	h3_fontFamily: 'opensans_semibold',
	h3_textTransform: 'none',
	h3_letterSpacing: 0,
	h3_fontSizeDesktop: 25,
	h3_lineHeightDesktop: 50,
	h3_fontSizeMobile: 20,
	h3_lineHeightMobile: 30,
	body_color: '#000000',
	body_fontFamily: 'opensans_regular',
	body_textTransform: 'none',
	body_letterSpacing: 0,
	body_fontSizeDesktop: 17,
	body_lineHeightDesktop: 30,
	body_fontSizeMobile: 13,
	body_lineHeightMobile: 22,
	button1_color: '#FFFFFF',
	button1_fontFamily: 'opensans_semibold',
	button1_textTransform: 'uppercase',
	button1_letterSpacing: 50,
	button1_fontSizeDesktop: 18,
	button1_lineHeightDesktop: 24,
	button1_fontSizeMobile: 14,
	button1_lineHeightMobile: 19,
	button1_bg: '#98746B',
	button1_bg_hover: '#AF948D',
};

export const fonts = [
	{
		label: 'Open Sans Bold',
		value: 'opensans_bold',
	},
	{
		label: 'Open Sans Regular',
		value: 'opensans_regular',
	},
	{
		label: 'Open Sans Semibold',
		value: 'opensans_semibold',
	},
	{
		label: 'Riforma Regular',
		value: 'riforma_regular',
	},
	{
		label: 'Kaisei Regular',
		value: 'kaiseiopti_regular',
	},
	{
		label: 'Rokkitt Regular',
		value: 'rokkitt_regular',
	},
	{
		label: 'Rokkitt Bold',
		value: 'rokkitt_bold',
	},
	{
		label: 'Figtree Regular',
		value: 'Figtree-Regular_1',
	},
	{
		label: 'Figtree Semibold',
		value: 'Figtree-SemiBold_2',
	},
	{
		label: 'Mulish Bold',
		value: 'Mulish-Bold',
	},
	{
		label: 'Mulish Regular',
		value: 'Mulish-Regular',
	},
	{
		label: 'RozhaOne Regular',
		value: 'RozhaOne-Regular',
	},
	{
		label: 'Roboto Condensed Regular',
		value: 'RobotoCondensed-Regular',
	},
	{
		label: 'Roboto Condensed Bold',
		value: 'RobotoCondensed-Bold',
	},
	{
		label: 'Roboto Light',
		value: 'Roboto-Light',
	},
	{
		label: 'Roboto Slab Regular',
		value: 'RobotoSlab-Regular',
	},
	{
		label: 'Crimson Text Regular',
		value: 'CrimsonText-Regular',
	},
	{
		label: 'Crimson Text SemiBold',
		value: 'CrimsonText-SemiBold',
	},
	{
		label: 'Crimson Text Bold',
		value: 'CrimsonText-Bold',
	},
	{
		label: 'Prata Regular',
		value: 'Prata-Regular',
	},
	{
		label: 'WorkSans Regular',
		value: 'WorkSans-Regular',
	},
	{
		label: 'WorkSans Medium',
		value: 'WorkSans-Medium',
	},
	{
		label: 'Gyst Regular',
		value: 'Gyst-Regular',
	},
	{
		label: 'Gyst Medium',
		value: 'Gyst-Medium',
	},
	{
		label: 'Gyst Bold',
		value: 'Gyst-Bold',
	},
	{
		label: 'Alegreya Sans Regular',
		value: 'AlegreyaSans-Regular',
	},
	{
		label: 'Alegreya Sans Light',
		value: 'AlegreyaSans-Light',
	},
	{
		label: 'Alegreya Regular',
		value: 'Alegreya-Regular',
	},
];

export const fontsMail = [
	{
		label: 'Arial',
		value: 'Arial',
	},
	{
		label: 'Georgia',
		value: 'Georgia',
	},
];

export const textTransforms = [
	{
		value: 'none',
		label: 'none',
	},
	{
		value: 'lowercase',
		label: 'lowercase',
	},
	{
		value: 'uppercase',
		label: 'uppercase',
	},
];

export const languages = [
	{
		id: 1,
		key: 'DE',
		default: true,
	},
	{
		id: 2,
		key: 'IT',
		default: false,
	},
	{
		id: 3,
		key: 'EN',
		default: false,
	},
];

export const mealPlans = (t, langKey) => [
	{
		label: t('general.mealPlans.allInclusive', { lng: langKey }),
		value: 1,
	},{
		label: t('general.mealPlans.bedAndBreakfast', { lng: langKey }),
		value: 3,
	},{
		label: t('general.mealPlans.fullBoard', { lng: langKey }),
		value: 10,
	},{
		label: t('general.mealPlans.halfBoard', { lng: langKey }),
		value: 12,
	},{
		label: t('general.mealPlans.roomOnly', { lng: langKey }),
		value: 14,
	},
];

export const languageSelect = () => [
	{
		label: 'DE',
		value: 'de',
	},
	{
		label: 'IT',
		value: 'it',
	},
	{
		label: 'EN',
		value: 'en',
	},
];

export const salutations = (t, langKey) => [
	{
		label: t('general.salutations.M', { lng: langKey }),
		value: 'M',
	},{
		label: t('general.salutations.F', { lng: langKey }),
		value: 'F',
	},
];

export const numberSelect = (count) => {
	let numbers = [];
	for (let i = 0; i <= count; i++) {
		numbers.push({
			label: i,
			value: i,
		});
	}
	return numbers;
};

export const findTranslationOrFallback = (translations, key, lang) => {
	let translation = '';
	if (translations && translations.length) {
		if (translations.find(translation => translation.key === key)) {
			translation = translations.find(translation => translation.key === key);
			if (translation['value' + lang] === null) {
				translation = '';
			} else {
				translation = translation['value' + lang];
			}
		}
		// show fallback language values
		/*if (translation === null) {
			translation = translations.find(translation => translation.key === key)['valueDE'];
		}*/
	}
	return translation;
};

export const isValidUploadFormat = (file, t, svg = false) => {
	let formats = 'png | jpg' + (svg ? ' | svg' : '');
	if ((file.type === 'image/png' || file.type === 'image/jpeg' || (svg && file.type === 'image/svg+xml'))) {
		return true;
	} else {
		message.error(t('general.upload.wrongFormat') + ' ' + formats);
		return false;
	}
};

export const apiDomain = () => {
	return window.location.hostname === 'localhost' ? 'http://localhost:3000/' : 'https://api.co-berta.com/';
};

export const backendDomain = () => {
	return window.location.hostname === 'localhost' ? 'http://localhost:3001/' : 'https://login.co-berta.com/';
};