import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input } from 'antd';
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'utils/constants';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

function Login () {
	const { t } = useTranslation();
	const { login, user, loginError } = useAuth();
	const navigate = useNavigate();
	const onFinish = async (values) => {
		const user = await login(values.email, values.password);
		if (user) {
			if (user.isAdmin) {
				navigate(RoutePaths.settings.changeClient);
			} else {
				navigate(RoutePaths.requests.index);
			}
		}
	};
	
	useEffect(() => {
		if (user) {
			navigate(RoutePaths.requests.index);
		}
	}, [user,navigate]);
	
	return (
		<div className="login-wrapper">
			<Card
				bordered={false}
				style={{
					width: 300,
				}}
			>
				<Form
					name="normal_login"
					className="login-form"
					onFinish={onFinish}
				>
					<Form.Item
						name="email"
						rules={[
							{
								required: true,
								message: t('login.userNameError'),
							},
						]}
					>
						<Input suffix={<UserOutlined className="site-form-item-icon" />} placeholder={t('login.useerName')} />
					</Form.Item>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: t('login.passwordError'),
							},
						]}
					>
						<Input
							suffix={<LockOutlined className="site-form-item-icon" />}
							type="password"
							placeholder={t('login.password')}
						/>
					</Form.Item>
					{ loginError &&
						<div className="error-container color-error">
							{ t('login.error') }
						</div>
					}
					<Form.Item>
						<Button type="primary" htmlType="submit" className="login-form-button">
							{t('login.login')}
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</div>
	);
};

export default Login;