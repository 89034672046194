export const countriesIT = [
	{
		value: 'AF',
		label: 'Afghanistan',
	},
	{
		value: 'AX',
		label: 'Isole Aland',
	},
	{
		value: 'AL',
		label: 'Albania',
	},
	{
		value: 'DZ',
		label: 'Algeria',
	},
	{
		value: 'AS',
		label: 'Samoa americane',
	},
	{
		value: 'AD',
		label: 'Andorra',
	},
	{
		value: 'AO',
		label: 'Angola',
	},
	{
		value: 'AI',
		label: 'Anguilla',
	},
	{
		value: 'AQ',
		label: 'Antartide',
	},
	{
		value: 'AG',
		label: 'Antigua e Barbuda',
	},
	{
		value: 'AR',
		label: 'Argentina',
	},
	{
		value: 'AM',
		label: 'Armenia',
	},
	{
		value: 'AW',
		label: 'Aruba',
	},
	{
		value: 'AU',
		label: 'Australia',
	},
	{
		value: 'AT',
		label: 'Austria',
	},
	{
		value: 'AZ',
		label: 'Azerbaigian',
	},
	{
		value: 'BS',
		label: 'Bahamas',
	},
	{
		value: 'BH',
		label: 'Bahrein',
	},
	{
		value: 'BD',
		label: 'Bangladesh',
	},
	{
		value: 'BB',
		label: 'Barbados',
	},
	{
		value: 'BY',
		label: 'Bielorussia',
	},
	{
		value: 'BE',
		label: 'Belgio',
	},
	{
		value: 'BZ',
		label: 'Belize',
	},
	{
		value: 'BJ',
		label: 'Benin',
	},
	{
		value: 'BM',
		label: 'Bermuda',
	},
	{
		value: 'BT',
		label: 'Bhutan',
	},
	{
		value: 'BO',
		label: 'Bolivia',
	},
	{
		value: 'BQ',
		label: 'Bonaire, Sint Eustatius e Saba',
	},
	{
		value: 'BA',
		label: 'Bosnia Erzegovina',
	},
	{
		value: 'BW',
		label: 'Botswana',
	},
	{
		value: 'BV',
		label: 'Isola Bouvet',
	},
	{
		value: 'BR',
		label: 'Brasile',
	},
	{
		value: 'IO',
		label: 'Territorio britannico dell\'Oceano Indiano',
	},
	{
		value: 'BN',
		label: 'Brunei Darussalam',
	},
	{
		value: 'BG',
		label: 'Bulgaria',
	},
	{
		value: 'BF',
		label: 'Burkina Faso',
	},
	{
		value: 'BI',
		label: 'Burundi',
	},
	{
		value: 'KH',
		label: 'Cambogia',
	},
	{
		value: 'CM',
		label: 'Camerun',
	},
	{
		value: 'CA',
		label: 'Canada',
	},
	{
		value: 'CV',
		label: 'capo Verde',
	},
	{
		value: 'KY',
		label: 'Isole Cayman',
	},
	{
		value: 'CF',
		label: 'Repubblica Centrafricana',
	},
	{
		value: 'TD',
		label: 'Chad',
	},
	{
		value: 'CL',
		label: 'Chile',
	},
	{
		value: 'CN',
		label: 'Cina',
	},
	{
		value: 'CX',
		label: 'Isola di Natale',
	},
	{
		value: 'CC',
		label: 'Isole Cocos (Keeling)',
	},
	{
		value: 'CO',
		label: 'Colombia',
	},
	{
		value: 'KM',
		label: 'Comore',
	},
	{
		value: 'CG',
		label: 'Congo',
	},
	{
		value: 'CD',
		label: 'Congo, Repubblica Democratica del Congo',
	},
	{
		value: 'CK',
		label: 'Isole Cook',
	},
	{
		value: 'CR',
		label: 'Costa Rica',
	},
	{
		value: 'CI',
		label: 'Costa d\'Avorio',
	},
	{
		value: 'HR',
		label: 'Croazia',
	},
	{
		value: 'CU',
		label: 'Cuba',
	},
	{
		value: 'CW',
		label: 'Curacao',
	},
	{
		value: 'CY',
		label: 'Cipro',
	},
	{
		value: 'CZ',
		label: 'Repubblica Ceca',
	},
	{
		value: 'DK',
		label: 'Danimarca',
	},
	{
		value: 'DJ',
		label: 'Gibuti',
	},
	{
		value: 'DM',
		label: 'Dominica',
	},
	{
		value: 'DO',
		label: 'Repubblica Dominicana',
	},
	{
		value: 'EC',
		label: 'Ecuador',
	},
	{
		value: 'EG',
		label: 'Egitto',
	},
	{
		value: 'SV',
		label: 'El Salvador',
	},
	{
		value: 'GQ',
		label: 'Guinea Equatoriale',
	},
	{
		value: 'ER',
		label: 'Eritrea',
	},
	{
		value: 'EE',
		label: 'Estonia',
	},
	{
		value: 'ET',
		label: 'Etiopia',
	},
	{
		value: 'FK',
		label: 'Isole Falkland (Malvinas)',
	},
	{
		value: 'FO',
		label: 'Isole Faroe',
	},
	{
		value: 'FJ',
		label: 'Figi',
	},
	{
		value: 'FI',
		label: 'Finlandia',
	},
	{
		value: 'FR',
		label: 'Francia',
	},
	{
		value: 'GF',
		label: 'Guiana francese',
	},
	{
		value: 'PF',
		label: 'Polinesia francese',
	},
	{
		value: 'TF',
		label: 'Territori della Francia del sud',
	},
	{
		value: 'GA',
		label: 'Gabon',
	},
	{
		value: 'GM',
		label: 'Gambia',
	},
	{
		value: 'GE',
		label: 'Georgia',
	},
	{
		value: 'DE',
		label: 'Germania',
	},
	{
		value: 'GH',
		label: 'Ghana',
	},
	{
		value: 'GI',
		label: 'Gibilterra',
	},
	{
		value: 'GR',
		label: 'Grecia',
	},
	{
		value: 'GL',
		label: 'Groenlandia',
	},
	{
		value: 'GD',
		label: 'Grenada',
	},
	{
		value: 'GP',
		label: 'Guadalupa',
	},
	{
		value: 'GU',
		label: 'Guam',
	},
	{
		value: 'GT',
		label: 'Guatemala',
	},
	{
		value: 'GG',
		label: 'Guernsey',
	},
	{
		value: 'GN',
		label: 'Guinea',
	},
	{
		value: 'GW',
		label: 'Guinea-Bissau',
	},
	{
		value: 'GY',
		label: 'Guyana',
	},
	{
		value: 'HT',
		label: 'Haiti',
	},
	{
		value: 'HM',
		label: 'Heard Island e McDonald Islands',
	},
	{
		value: 'VA',
		label: 'Santa Sede (Stato della Città del Vaticano)',
	},
	{
		value: 'HN',
		label: 'Honduras',
	},
	{
		value: 'HK',
		label: 'Hong Kong',
	},
	{
		value: 'HU',
		label: 'Ungheria',
	},
	{
		value: 'IS',
		label: 'Islanda',
	},
	{
		value: 'IN',
		label: 'India',
	},
	{
		value: 'ID',
		label: 'Indonesia',
	},
	{
		value: 'IR',
		label: 'Iran (Repubblica Islamica del',
	},
	{
		value: 'IQ',
		label: 'Iraq',
	},
	{
		value: 'IE',
		label: 'Irlanda',
	},
	{
		value: 'IM',
		label: 'Isola di Man',
	},
	{
		value: 'IL',
		label: 'Israele',
	},
	{
		value: 'IT',
		label: 'Italia',
	},
	{
		value: 'JM',
		label: 'Giamaica',
	},
	{
		value: 'JP',
		label: 'Giappone',
	},
	{
		value: 'JE',
		label: 'Jersey',
	},
	{
		value: 'JO',
		label: 'Giordania',
	},
	{
		value: 'KZ',
		label: 'Kazakistan',
	},
	{
		value: 'KE',
		label: 'Kenya',
	},
	{
		value: 'KI',
		label: 'Kiribati',
	},
	{
		value: 'KP',
		label: 'Corea, Repubblica Popolare Democratica di',
	},
	{
		value: 'KR',
		label: 'Corea, Repubblica di',
	},
	{
		value: 'XK',
		label: 'Kosovo',
	},
	{
		value: 'KW',
		label: 'Kuwait',
	},
	{
		value: 'KG',
		label: 'Kirghizistan',
	},
	{
		value: 'LA',
		label: 'Repubblica Democratica Popolare del Laos',
	},
	{
		value: 'LV',
		label: 'Lettonia',
	},
	{
		value: 'LB',
		label: 'Libano',
	},
	{
		value: 'LS',
		label: 'Lesotho',
	},
	{
		value: 'LR',
		label: 'Liberia',
	},
	{
		value: 'LY',
		label: 'Giamahiria araba libica',
	},
	{
		value: 'LI',
		label: 'Liechtenstein',
	},
	{
		value: 'LT',
		label: 'Lituania',
	},
	{
		value: 'LU',
		label: 'Lussemburgo',
	},
	{
		value: 'MO',
		label: 'Macao',
	},
	{
		value: 'MK',
		label: 'Macedonia, ex Repubblica iugoslava di',
	},
	{
		value: 'MG',
		label: 'Madagascar',
	},
	{
		value: 'MW',
		label: 'Malawi',
	},
	{
		value: 'MY',
		label: 'Malaysia',
	},
	{
		value: 'MV',
		label: 'Maldive',
	},
	{
		value: 'ML',
		label: 'Mali',
	},
	{
		value: 'MT',
		label: 'Malta',
	},
	{
		value: 'MH',
		label: 'Isole Marshall',
	},
	{
		value: 'MQ',
		label: 'Martinica',
	},
	{
		value: 'MR',
		label: 'Mauritania',
	},
	{
		value: 'MU',
		label: 'Maurizio',
	},
	{
		value: 'YT',
		label: 'Mayotte',
	},
	{
		value: 'MX',
		label: 'Messico',
	},
	{
		value: 'FM',
		label: 'Micronesia, Stati federati di',
	},
	{
		value: 'MD',
		label: 'Moldova, Repubblica di',
	},
	{
		value: 'MC',
		label: 'Monaco',
	},
	{
		value: 'MN',
		label: 'Mongolia',
	},
	{
		value: 'ME',
		label: 'Montenegro',
	},
	{
		value: 'MS',
		label: 'Montserrat',
	},
	{
		value: 'MA',
		label: 'Marocco',
	},
	{
		value: 'MZ',
		label: 'Mozambico',
	},
	{
		value: 'MM',
		label: 'Myanmar',
	},
	{
		value: 'NA',
		label: 'Namibia',
	},
	{
		value: 'NR',
		label: 'Nauru',
	},
	{
		value: 'NP',
		label: 'Nepal',
	},
	{
		value: 'NL',
		label: 'Olanda',
	},
	{
		value: 'AN',
		label: 'Antille Olandesi',
	},
	{
		value: 'NC',
		label: 'Nuova Caledonia',
	},
	{
		value: 'NZ',
		label: 'Nuova Zelanda',
	},
	{
		value: 'NI',
		label: 'Nicaragua',
	},
	{
		value: 'NE',
		label: 'Niger',
	},
	{
		value: 'NG',
		label: 'Nigeria',
	},
	{
		value: 'NU',
		label: 'Niue',
	},
	{
		value: 'NF',
		label: 'Isola Norfolk',
	},
	{
		value: 'MP',
		label: 'Isole Marianne settentrionali',
	},
	{
		value: 'NO',
		label: 'Norvegia',
	},
	{
		value: 'OM',
		label: 'Oman',
	},
	{
		value: 'PK',
		label: 'Pakistan',
	},
	{
		value: 'PW',
		label: 'Palau',
	},
	{
		value: 'PS',
		label: 'Territori palestinesi occupati',
	},
	{
		value: 'PA',
		label: 'Panama',
	},
	{
		value: 'PG',
		label: 'Papua Nuova Guinea',
	},
	{
		value: 'PY',
		label: 'Paraguay',
	},
	{
		value: 'PE',
		label: 'Perù',
	},
	{
		value: 'PH',
		label: 'Filippine',
	},
	{
		value: 'PN',
		label: 'Pitcairn',
	},
	{
		value: 'PL',
		label: 'Polonia',
	},
	{
		value: 'PT',
		label: 'Portogallo',
	},
	{
		value: 'PR',
		label: 'Porto Rico',
	},
	{
		value: 'QA',
		label: 'Qatar',
	},
	{
		value: 'RE',
		label: 'Riunione',
	},
	{
		value: 'RO',
		label: 'Romania',
	},
	{
		value: 'RU',
		label: 'Federazione Russa',
	},
	{
		value: 'RW',
		label: 'Ruanda',
	},
	{
		value: 'BL',
		label: 'Saint Barthelemy',
	},
	{
		value: 'SH',
		label: 'Sant\'Elena',
	},
	{
		value: 'KN',
		label: 'Saint Kitts e Nevis',
	},
	{
		value: 'LC',
		label: 'Santa Lucia',
	},
	{
		value: 'MF',
		label: 'Saint Martin',
	},
	{
		value: 'PM',
		label: 'Saint Pierre e Miquelon',
	},
	{
		value: 'VC',
		label: 'Saint Vincent e Grenadine',
	},
	{
		value: 'WS',
		label: 'Samoa',
	},
	{
		value: 'SM',
		label: 'San Marino',
	},
	{
		value: 'ST',
		label: 'Sao Tome e Principe',
	},
	{
		value: 'SA',
		label: 'Arabia Saudita',
	},
	{
		value: 'SN',
		label: 'Senegal',
	},
	{
		value: 'RS',
		label: 'Serbia',
	},
	{
		value: 'CS',
		label: 'Serbia e Montenegro',
	},
	{
		value: 'SC',
		label: 'Seychelles',
	},
	{
		value: 'SL',
		label: 'Sierra Leone',
	},
	{
		value: 'SG',
		label: 'Singapore',
	},
	{
		value: 'SX',
		label: 'St Martin',
	},
	{
		value: 'SK',
		label: 'Slovacchia',
	},
	{
		value: 'SI',
		label: 'Slovenia',
	},
	{
		value: 'SB',
		label: 'Isole Salomone',
	},
	{
		value: 'SO',
		label: 'Somalia',
	},
	{
		value: 'ZA',
		label: 'Sud Africa',
	},
	{
		value: 'GS',
		label: 'Georgia del Sud e isole Sandwich meridionali',
	},
	{
		value: 'SS',
		label: 'Sudan del Sud',
	},
	{
		value: 'ES',
		label: 'Spagna',
	},
	{
		value: 'LK',
		label: 'Sri Lanka',
	},
	{
		value: 'SD',
		label: 'Sudan',
	},
	{
		value: 'SR',
		label: 'Suriname',
	},
	{
		value: 'SJ',
		label: 'Svalbard e Jan Mayen',
	},
	{
		value: 'SZ',
		label: 'Swaziland',
	},
	{
		value: 'SE',
		label: 'Svezia',
	},
	{
		value: 'CH',
		label: 'Svizzera',
	},
	{
		value: 'SY',
		label: 'Repubblica Araba Siriana',
	},
	{
		value: 'TW',
		label: 'Taiwan, provincia della Cina',
	},
	{
		value: 'TJ',
		label: 'Tagikistan',
	},
	{
		value: 'TZ',
		label: 'Tanzania, Repubblica Unita di',
	},
	{
		value: 'TH',
		label: 'Tailandia',
	},
	{
		value: 'TL',
		label: 'Timor-Leste',
	},
	{
		value: 'TG',
		label: 'Andare',
	},
	{
		value: 'TK',
		label: 'Tokelau',
	},
	{
		value: 'TO',
		label: 'Tonga',
	},
	{
		value: 'TT',
		label: 'Trinidad e Tobago',
	},
	{
		value: 'TN',
		label: 'Tunisia',
	},
	{
		value: 'TR',
		label: 'tacchino',
	},
	{
		value: 'TM',
		label: 'Turkmenistan',
	},
	{
		value: 'TC',
		label: 'Isole Turks e Caicos',
	},
	{
		value: 'TV',
		label: 'Tuvalu',
	},
	{
		value: 'UG',
		label: 'Uganda',
	},
	{
		value: 'UA',
		label: 'Ucraina',
	},
	{
		value: 'AE',
		label: 'Emirati Arabi Uniti',
	},
	{
		value: 'GB',
		label: 'Regno Unito',
	},
	{
		value: 'US',
		label: 'stati Uniti',
	},
	{
		value: 'UM',
		label: 'Isole minori esterne degli Stati Uniti',
	},
	{
		value: 'UY',
		label: 'Uruguay',
	},
	{
		value: 'UZ',
		label: 'Uzbekistan',
	},
	{
		value: 'VU',
		label: 'Vanuatu',
	},
	{
		value: 'VE',
		label: 'Venezuela',
	},
	{
		value: 'VN',
		label: 'Viet Nam',
	},
	{
		value: 'VG',
		label: 'Isole Vergini britanniche',
	},
	{
		value: 'VI',
		label: 'Isole Vergini, Stati Uniti',
	},
	{
		value: 'WF',
		label: 'Wallis e Futuna',
	},
	{
		value: 'EH',
		label: 'Sahara occidentale',
	},
	{
		value: 'YE',
		label: 'Yemen',
	},
	{
		value: 'ZM',
		label: 'Zambia',
	},
	{
		value: 'ZW',
		label: 'Zimbabwe',
	},
];