import { Divider } from 'antd';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { findTranslationOrFallback, languages } from 'utils/constants';
import LanguageInput from 'components/forms/language-input';

function BookingOverlayT1 ({ content, form, language }) {
	const { t } = useTranslation();
	
	useEffect(() => {
		languages.forEach(lang => {
			form.setFieldValue(('bookingOverlayT1_headline' + lang.key), findTranslationOrFallback(content.translations, 'bookingOverlayT1_headline', lang.key));
			form.setFieldValue(('bookingOverlayT1_subheadline' + lang.key), findTranslationOrFallback(content.translations, 'bookingOverlayT1_subheadline', lang.key));
			form.setFieldValue(('bookingOverlayT1_headline_storno' + lang.key), findTranslationOrFallback(content.translations, 'bookingOverlayT1_headline_storno', lang.key));
		});
	}, [content, form]);
	
	return (
		<div className="variants-t1 block">
			<Divider orientation="left" orientationMargin="0">
				<Title level={4}>
					{t('content.lp.bookingOverlayT1.bookingOverlayT1')}
				</Title>
			</Divider>
			<div className="fields">
				<LanguageInput
					label={t('content.lp.general.subheadline')}
					name="bookingOverlayT1_subheadline"
					currentLanguage={language}
				/>
				<LanguageInput
					label={t('content.lp.general.headline')}
					name="bookingOverlayT1_headline"
					currentLanguage={language}
				/>
				<LanguageInput
					label={t('content.lp.bookingOverlayT1.headlineStorno')}
					name="bookingOverlayT1_headline_storno"
					currentLanguage={language}
				/>
			</div>
		</div>
	);
};

export default BookingOverlayT1;