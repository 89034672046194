import React, { useEffect, useState } from 'react';
import { apiDomain, findTranslationOrFallback } from 'utils/constants';
import { getTranslation } from 'pages/offer/translations/translations';
import Headline from 'pages/offer/components/core/headline';
import Text from 'pages/offer/components/core/text';
import Icon from 'pages/offer/components/core/icon';
import './index.scss';

function ModuleContactPersonT1 ({ content, offer }) {
	const [headline, setHeadline] = useState();
	const [text, setText] = useState();
	const [contactPersonJob, setContactPersonJob] = useState();
	
	useEffect(() => {
		let lang = offer.language.toUpperCase();
		
		setHeadline(findTranslationOrFallback(offer.hotelInfos?.translations, 'contactPersonHeadline', lang));
		setText(findTranslationOrFallback(offer.hotelInfos?.translations, 'contactPersonText', lang));
		setContactPersonJob(findTranslationOrFallback(offer.hotelInfos?.translations, 'contactPersonJob', lang));
	}, [content, offer]);
	
	return (
		<div className="module-contact-person-t1">
			{ offer &&
				<div className="centercontent1047 df ac">
					{ (offer.hotelInfos?.contactPersonName || contactPersonJob || offer.hotelInfos?.imageContactPersonPath) &&
						<div className="left">
							{ offer.hotelInfos?.imageContactPersonPath &&
								<div className="image">
									<img src={apiDomain() + offer.hotelInfos.imageContactPersonPath} alt="" />
								</div>
							}
							{ (offer.hotelInfos.contactPersonName || contactPersonJob) &&
								<div className="content">
									{ offer.hotelInfos?.contactPersonName &&
										<Headline
											class={ 'headline t3' }
											text={ offer.hotelInfos.contactPersonName }
										/>
									}
									{ contactPersonJob &&
										<Text text={ contactPersonJob } />
									}
								</div>
							}
						</div>
					}
					{ (headline || text || offer.hotelInfos?.whatsapp || offer.hotelInfos?.phone || offer.hotelInfos?.email) &&
						<div className="right">
							{ headline &&
								<Headline
									class={ 'headline t1' }
									text={ headline }
								/>
							}
							{ text &&
								<Text text={ text } />
							}
							{ offer.hotelInfos?.whatsapp &&
								<div className="item item-whatsapp">
									<a className="df ac" rel="noreferrer" target="_blank" href={'https://api.whatsapp.com/send?phone=' + offer.hotelInfos.whatsapp}>
										<div className="icon">
											<Icon
												icon="whatsapp"
												width="47"
												height="47"
											/>
										</div>
										<Text text={ getTranslation('contactpersont1', 'writeawhatsapp', offer.language) } />
									</a>
								</div>
							}
							{ offer.hotelInfos?.phone &&
								<div className="item item-phone">
									<a className="df ac" href={'tel: ' + offer.hotelInfos.phone}>
										<div className="icon">
											<Icon
												icon="tel"
												width="27"
												height="27"
											/>
										</div>
										<Text text={ offer.hotelInfos.phone } />
									</a>
								</div>
							}
							{ offer.hotelInfos?.email &&
								<div className="item item-email">
									<a className="df ac" href={'mailto: ' + offer.hotelInfos.email}>
										<div className="icon">
											<Icon
												icon="email"
												width="36"
												height="25"
											/>
										</div>
										<Text text={ offer.hotelInfos.email } />
									</a>
								</div>
							}
						</div>
					}
				</div>
			}
		</div>
	);
};

export default ModuleContactPersonT1;