import { useAuth } from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';
import { RoutePaths } from 'utils/constants';
import { useLocation } from 'react-router-dom';

function PrivateRoute({ children }) {
	const auth = useAuth();
	let location = useLocation();
	if (!auth.user) {
		return <Navigate to={RoutePaths.login.index} state={{ from: location }} />;
	}
	return children;
}

export default PrivateRoute;