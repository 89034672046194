import { languages } from 'utils/constants';
import { Input, Form, InputNumber } from 'antd';
import './index.scss';

function LanguageInput({ label, name, currentLanguage, type, size = 'middle', rules, disabled }) {
	return (
		<div className="language-input">
			{ languages.map((language) => (
				<div className={currentLanguage === language.key ? 'active' : ''} key={name + language.key}>
					<Form.Item label={label} name={name + language.key} rules={rules}>
						{ type === 'number' ? (
							<InputNumber min={0} size={size} disabled={disabled}/>
						) : (
							<Input type={type ? type : 'text'} size={size} disabled={disabled}/>
						)}
					</Form.Item>
				</div>
			))}
		</div>
	);
}

export default LanguageInput;
