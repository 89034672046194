import React from 'react';

export const combineComponents = (...components) => {
	return components.reduce(
		(AccumulatedComponents, { component: CurrentComponent, ...props }) => {
			return ({ children }) => (
				<AccumulatedComponents>
					<CurrentComponent {...props}>{children}</CurrentComponent>
				</AccumulatedComponents>
			);
		},
		({ children }) => <>{children}</>,
	);
};