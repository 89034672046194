import React, { useEffect, useState } from 'react';
import { apiDomain, findTranslationOrFallback } from 'utils/constants';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import { IconPrevNext } from 'utils/svgs.jsx';
import Headline from 'pages/offer/components/core/headline';
import Text from 'pages/offer/components/core/text';
import './index.scss';

function ModuleUspT1 ({ contentUsp, offer }) {
	const [items, setItems] = useState([]);
	
	const setProgressBarState = (splide) => {
		var bar = splide.root.querySelector('.splide__progress__bar');
		var end  = splide.Components.Controller.getEnd() + 1;
		
		if (splide.Components.Controller.getEnd() > -1) {
			var rate = Math.min( ( splide.index + 1 ) / end, 1 );
			bar.style.width = String( 100 * rate ) + '%';
		}
	};
	
	useEffect(() => {
		let lang = offer.language.toUpperCase();
		let elements = [];
		
		if (contentUsp) {
			elements = contentUsp.map((element, i) => {
				let headline = '';
				let text = '';
				let image = '';
				
				if (element['imagePath']) {
					image = apiDomain() + element['imagePath'];
				}
				
				headline = findTranslationOrFallback(element?.contentUspTranslations, 'headline', lang);
				text = findTranslationOrFallback(element?.contentUspTranslations, 'text', lang);
				
				return {
					index: i + 1,
					id: element.id,
					headline: headline,
					text: text,
					image: image,
				};
			});
			setItems(elements);
		}
	}, [contentUsp, offer]);
	
	return (
		<div className="usp-t1">
			<div className="elements">
				<div className="centercontent1047">
					<div className={'gallery gallery-items-' + items.length}>
						<Splide
							hasTrack={ false }
							options={{
								perPage: 3,
								perMove: 1,
								drag: true,
								snap: true,
								pagination: false,
								arrows: true,
								gap: '50px',
								breakpoints: {
									650: {
										perPage: 1,
										gap: '25px',
									},
								},
							}}
							onMounted={(splide) => {
								setProgressBarState(splide);
							}}
							onMoved={(splide) => {
								setProgressBarState(splide);
							}}
							onDragged={(splide) => {
								setProgressBarState(splide);
							}}
						>
							<div className="splide__arrows">
								<button className="splide__arrow splide__arrow--prev">
									<IconPrevNext />
								</button>
								<button className="splide__arrow splide__arrow--next">
									<IconPrevNext />
								</button>
							</div>
							<SplideTrack>
								{ items && 
									items.map((elem, i) => {
										return (
											<SplideSlide key={ i }>
												<div className="element">
													<div className="image">
														<img src={elem.image} alt="" />
													</div>
													<div className="content">
														<Headline
															class={ 'headline t2' }
															text={ elem.headline }
														/>
														<Text text={ elem.text } />
													</div>
												</div>
											</SplideSlide>
										);
									})
								}
							</SplideTrack>
							<div className="splide__progress">
								<div className="splide__progress__bar" />
							</div>
						</Splide>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModuleUspT1;