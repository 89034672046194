import { Tabs, Divider } from 'antd';
import Title from 'antd/es/typography/Title';
import SettingsContentGeneral from './general';
import SettingsContentLandingpage from './landingpage';
import SettingsContentBooking from './booking';
import SettingsContentMails from './mails';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/core/language-switcher';
import React, { useState } from 'react';
import { languages } from 'utils/constants';
import { useLocation } from 'react-router-dom';

function SettingsContent () {
	const { t } = useTranslation();
	const location = useLocation();
	const [language, setLanguage] = useState((location.state && location.state.lang) ? location.state.lang : languages.find(lang => lang.default).key);
	
	const tabs = [
		{
			key: '1',
			label: t('content.tabs.general'),
			children: <SettingsContentGeneral language={language} onLanguageChange={(lang) => setLanguage(lang)}/>,
		},
		{
			key: '2',
			label: t('content.tabs.landingpage'),
			children: <SettingsContentLandingpage language={language} onLanguageChange={(lang) => setLanguage(lang)}/>,
		},
		{
			key: '3',
			label: t('content.tabs.booking'),
			children: <SettingsContentBooking language={language} onLanguageChange={(lang) => setLanguage(lang)}/>,
		},
		{
			key: '4',
			label: t('content.tabs.mails'),
			children: <SettingsContentMails language={language} onLanguageChange={(lang) => setLanguage(lang)}/>,
		},
	];
	
	return (
		<div className="settings-content">
			<Title level={3}>
				{t('menu.content')}
			</Title>
			<Divider/>
			<Tabs defaultActiveKey={(location.state && location.state.activeTab) ? location.state.activeTab : '1'} items={tabs} />
			<LanguageSwitcher
				currentLanguage={language}
				onSwitch={(lang) => setLanguage(lang)}
			/>
		</div>
	);
};

export default SettingsContent;