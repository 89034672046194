import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, notification, Affix } from 'antd';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { apiDomain, defaultStyles, fonts, fontsMail, textTransforms } from 'utils/constants';
import Title from 'antd/es/typography/Title';
import { MobileOutlined, DesktopOutlined } from '@ant-design/icons';
import axios from 'axios';

function SettingsStyles () {
	const { accessToken, user } = useAuth();
	const [loading, setLoading] = useState(true);
	const [settingsStyles, setSettingsStyles] = useState();
	const { t } = useTranslation();
	const [api, contextHolder] = notification.useNotification();
	
	const fetchData = useCallback(async() => {
		setLoading(true);
		try {
			const { data: response } = await axios.get(apiDomain() + 'settings/styles/' + user.hotelId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setSettingsStyles(response);
			}
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	}, [accessToken,user.hotelId]);
	
	const onSubmit = async (values) => {
		try {
			await axios.put(apiDomain() + 'settings/styles', {
				hotelId: user.hotelId,
				...values,
			}, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			api['success']({
				message: t('general.saveSuccess'),
				description: t('general.saveSuccessText'),
				placement: 'bottomRight',
				duration: 3,
			});
			fetchData();
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	useEffect(() => {
		fetchData();
	}, [fetchData]);
	
	return (
		<div className="settings-styles">
			{contextHolder}
			{ (!loading && settingsStyles) &&
				<>
					<Form
						layout={'vertical'}
						onFinish={onSubmit}
						initialValues={Object.keys(settingsStyles).length > 0 ? settingsStyles : defaultStyles}
					>
						{ ['t1Large', 't2Large', 'h1', 'h2', 'h3', 'body'].map(key => <HeadlineFields key={key} fieldKey={key}/>)}
						{ ['button1'].map(key => <ButtonFields key={key} fieldKey={key}/>)}
						<Divider orientation="left" orientationMargin="0">
							<Title level={4}>
								{t('styles.mail')}
							</Title>
						</Divider>
						<Row gutter={10}>
							<Col span={4}>
								<Form.Item
									label={t('styles.fontFamily')}
									name={'mail_fontFamily'}
								>
									<Select
										showSearch
										options={fontsMail}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Affix offsetBottom={0} className="sticky-save-container">
							<div className="content">
								<Form.Item>
									<Button type="primary" htmlType="submit">
										{t('styles.submit')}
									</Button>
								</Form.Item>
							</div>
						</Affix>
					</Form>
				</>
			}
		</div>
	);
};

function HeadlineFields ({ fieldKey }) {
	const { t } = useTranslation();
	
	return (
		<>
			<Divider orientation="left" orientationMargin="0">
				<Title level={4}>
					{t('styles.' + fieldKey)}
				</Title>
			</Divider>
			<Row gutter={10}>
				<Col span={4}>
					<Form.Item
						label={t('styles.color')}
						name={fieldKey + '_color'}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={t('styles.fontFamily')}
						name={fieldKey + '_fontFamily'}
					>
						<Select
							showSearch
							options={fonts}
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={t('styles.textTransform')}
						name={fieldKey + '_textTransform'}
					>
						<Select
							options={textTransforms}
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={t('styles.letterSpacing')}
						name={fieldKey + '_letterSpacing'}
					>
						<InputNumber />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col span={4}>
					<Form.Item
						label={<div>{t('styles.fontSize')} <DesktopOutlined /></div>}
						name={fieldKey + '_fontSizeDesktop'}
					>
						<InputNumber
							addonAfter="px"
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={<div>{t('styles.lineHeight')} <DesktopOutlined /></div>}
						name={fieldKey + '_lineHeightDesktop'}
					>
						<InputNumber
							addonAfter="px"
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={<div>{t('styles.fontSize')} <MobileOutlined /></div>}
						name={fieldKey + '_fontSizeMobile'}
					>
						<InputNumber
							addonAfter="px"
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={<div>{t('styles.lineHeight')} <MobileOutlined /></div>}
						name={fieldKey + '_lineHeightMobile'}
					>
						<InputNumber
							addonAfter="px"
						/>
					</Form.Item>
				</Col>
			</Row>
		</>
	);
}

function ButtonFields ({ fieldKey }) {
	const { t } = useTranslation();
	
	return (
		<>
			<Divider orientation="left" orientationMargin="0">
				<Title level={4}>
					{t('styles.' + fieldKey)}
				</Title>
			</Divider>
			<Row gutter={10}>
				<Col span={4}>
					<Form.Item
						label={t('styles.color')}
						name={fieldKey + '_color'}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={t('styles.fontFamily')}
						name={fieldKey + '_fontFamily'}
					>
						<Select
							showSearch
							options={fonts}
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={t('styles.textTransform')}
						name={fieldKey + '_textTransform'}
					>
						<Select
							options={textTransforms}
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={t('styles.letterSpacing')}
						name={fieldKey + '_letterSpacing'}
					>
						<InputNumber />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col span={4}>
					<Form.Item
						label={<div>{t('styles.fontSize')} <DesktopOutlined /></div>}
						name={fieldKey + '_fontSizeDesktop'}
					>
						<InputNumber
							addonAfter="px"
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={<div>{t('styles.lineHeight')} <DesktopOutlined /></div>}
						name={fieldKey + '_lineHeightDesktop'}
					>
						<InputNumber
							addonAfter="px"
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={<div>{t('styles.fontSize')} <MobileOutlined /></div>}
						name={fieldKey + '_fontSizeMobile'}
					>
						<InputNumber
							addonAfter="px"
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={<div>{t('styles.lineHeight')} <MobileOutlined /></div>}
						name={fieldKey + '_lineHeightMobile'}
					>
						<InputNumber
							addonAfter="px"
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col span={4}>
					<Form.Item
						label={t('styles.background')}
						name={fieldKey + '_bg'}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label={t('styles.background_hover')}
						name={fieldKey + '_bg_hover'}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
}

export default SettingsStyles;