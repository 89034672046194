import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';
import { apiDomain } from 'utils/constants';
import './index.scss';

function OfferStyles ({ hotelId }) {
	const { accessToken } = useAuth();
	const [styles, setStyles] = useState();
	
	const fetchStyles = useCallback(async() => {
		try {
			const { data: response } = await axios.get(apiDomain() + 'settings/styles/' + hotelId, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			if (response) {
				setStyles(response);
			}
		} catch (error) {
			console.error(error.message);
		}
	}, [accessToken,hotelId]);
	
	const getStyles = () => {
		let css = ':root {';
		let fonts = [];
		
		Object.keys(styles).forEach(function(key, index) {
			if (key.includes('fontSize') || key.includes('lineHeight')) {
				css += '--' + key + ':' + styles[key] + 'px;';
				css += '--' + key + '_calc:' + styles[key] + ';';
			} else {
				css += '--' + key + ':' + styles[key] + ';';
			}
			if (key.includes('fontFamily') && !fonts.includes(styles[key])) {
				fonts.push(styles[key]);
			}
		});
		
		css =  css + '}';
		
		fonts.forEach(font => {
			css =  css + '@font-face {';
			css =  css + 'font-family:' + font + ';';
			css =  css + 'src: url(\'/fonts/' + font + '.woff2\') format(\'woff2\'),';
			css =  css + 'url(\'/fonts/' + font + '.woff\') format(\'woff\');';
			css =  css + 'font-weight: normal;';
			css =  css + 'font-display: swap;';
			css =  css + '}';
		});
		
		return css;
	};
	
	useEffect(() => {
		fetchStyles();
	}, [fetchStyles]);
	
	return (
		<div className="offer-styles">
			{ styles &&
				<style>
					{getStyles()}
				</style>
			}
		</div>
	);
};

export default OfferStyles;