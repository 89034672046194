import { Form, Input, Row, Col, Select, Checkbox } from 'antd';
import Icon from 'pages/offer/components/core/icon';
import Button from 'pages/offer/components/core/button';
import PriceTable from 'pages/offer/components/core/price-table';
import { getTranslation } from 'pages/offer/translations/translations';
import Headline from 'pages/offer/components/core/headline';
import Text from 'pages/offer/components/core/text';
import { RoutePaths, apiDomain, findTranslationOrFallback, salutations } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { countriesDE } from 'utils/countries/de';
import { countriesIT } from 'utils/countries/it';
import { countriesEN } from 'utils/countries/en';
import { useState } from 'react';
import axios from 'axios';
import './index.scss';

function BookingT1 ({ offer, open, onCloseBooking, selectedVariant, content }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [isCompany, setIsCompany] = useState(false);
	
	const onSubmit = async (values) => {
		try {
			await axios.post(apiDomain() + 'bookings/' + offer.uid, {
				hotelId: offer.hotelId,
				variant: selectedVariant.id,
				...values,
			});
			window.location.reload();
		} catch (error) {
			console.error(error.message);
		}
	};
	
	return (
		open &&
			<div className="module-booking-t1">
				<div className="bg" onClick={() => onCloseBooking()}></div>
				<div className="scroll-container">
					<div className="container centercontent1470">
						<div className="close" onClick={() => onCloseBooking()}></div>
						<div className="inner centercontent1047">
							<div className="intro">
								<div className="t2 pre-headline">{findTranslationOrFallback(content.translations, 'bookingOverlayT1_subheadline', offer.language.toUpperCase())}</div>
								<div className="t1 headline">{findTranslationOrFallback(content.translations, 'bookingOverlayT1_headline', offer.language.toUpperCase())}</div>
							</div>
							<div className="room">
								<div className="desc">
									<Headline
										class={'headline t1'}
										text={selectedVariant.headline}
									/>
									<Text text={selectedVariant.description} />
								</div>
								<div className="cont">
									{ selectedVariant.roomImages.length > 0 &&
										<div className="image">
											<img src={selectedVariant.roomImages[0].path ? (apiDomain() + selectedVariant.roomImages[0].path) : selectedVariant.roomImages[0].externalPath} alt="" />
										</div>
									}
									<div className="text-container">
										<div className="description">
											<Text text={selectedVariant.roomContent.description} />
										</div>
									</div>
								</div>
							</div>
							<div className="content">
								<div className="prices block">
									<div className="header">
										{getTranslation('booking', 'price', offer.language)}
									</div>
									<div className="data bgteaser">
										<div className="inner-data">
											<PriceTable
												prices={selectedVariant?.prices}
												lang={offer.language}
											/>
											<div className="storno">
												<Icon
													icon="info"
													width="25"
													height="25"
												/>
												<div className="headline">{findTranslationOrFallback(content.translations, 'bookingOverlayT1_headline_storno', offer.language.toUpperCase())}</div>
											</div>
										</div>
									</div>
								</div>
								<Form
									layout="vertical"
									onFinish={onSubmit}
									form={form}
									initialValues={{
										salutation: offer.gender,
										firstName: offer.firstName,
										lastName: offer.lastName,
										email: offer.email,
										phone: offer.phone,
										street: offer.street,
										cap: offer.cap,
										place: offer.place,
										country: offer.country,
									}}
								>
									<div className="personal-data block">
										<div className="header">
											{getTranslation('booking', 'personalData', offer.language)}
										</div>
										<div className="data bgteaser">
											<Row gutter={24}>
												<Col xs={24} sm={12} md={12} lg={8} xl={8}>
													<Form.Item
														label={getTranslation('booking', 'salutation', offer.language)}
														name="salutation"
														rules={[
															{
																required: true,
																message: getTranslation('booking', 'requiredField', offer.language),
															},
														]}
													>
														<Select
															showSearch
															optionFilterProp="label"
															size="large"
															options={salutations(t, offer.language)}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12} md={12} lg={8} xl={8}>
													<Form.Item
														label={getTranslation('booking', 'firstName', offer.language)}
														name="firstName"
														rules={[
															{
																required: true,
																message: getTranslation('booking', 'requiredField', offer.language),
															},
														]}
													>
														<Input
															size="large"
															type="text"
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12} md={12} lg={8} xl={8}>
													<Form.Item
														label={getTranslation('booking', 'lastName', offer.language)}
														name="lastName"
														rules={[
															{
																required: true,
																message: getTranslation('booking', 'requiredField', offer.language),
															},
														]}
													>
														<Input
															size="large"
															type="text"
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12} md={12} lg={8} xl={8}>
													<Form.Item
														label={getTranslation('booking', 'email', offer.language)}
														name="email"
														rules={[
															{
																required: true,
																message: getTranslation('booking', 'requiredField', offer.language),
															},
														]}
													>
														<Input
															size="large"
															type="text"
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12} md={12} lg={8} xl={8}>
													<Form.Item
														label={getTranslation('booking', 'phone', offer.language)}
														name="phone"
														rules={[
															{
																required: true,
																message: getTranslation('booking', 'requiredField', offer.language),
															},
														]}
													>
														<Input
															size="large"
															type="text"
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12} md={12} lg={8} xl={8}>
													<Form.Item
														label={getTranslation('booking', 'street', offer.language)}
														name="street"
														rules={[
															{
																required: true,
																message: getTranslation('booking', 'requiredField', offer.language),
															},
														]}
													>
														<Input
															size="large"
															type="text"
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12} md={12} lg={8} xl={8}>
													<Form.Item
														label={getTranslation('booking', 'cap', offer.language)}
														name="cap"
														rules={[
															{
																required: true,
																message: getTranslation('booking', 'requiredField', offer.language),
															},
														]}
													>
														<Input
															size="large"
															type="text"
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12} md={12} lg={8} xl={8}>
													<Form.Item
														label={getTranslation('booking', 'place', offer.language)}
														name="place"
														rules={[
															{
																required: true,
																message: getTranslation('booking', 'requiredField', offer.language),
															},
														]}
													>
														<Input
															size="large"
															type="text"
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={12} md={12} lg={8} xl={8}>
													<Form.Item
														label={getTranslation('booking', 'country', offer.language)}
														name="country"
														rules={[
															{
																required: true,
																message: getTranslation('booking', 'requiredField', offer.language),
															},
														]}
													>
														<Select
															showSearch
															optionFilterProp="label"
															size="large"
															options={offer.language === 'de' ? countriesDE : offer.language === 'it' ? countriesIT : countriesEN}
														/>
													</Form.Item>
												</Col>
											</Row>
											<div className="company-container">
												<Form.Item
													name="isCompany"
												>
													<Checkbox
														onChange={() => setIsCompany(!isCompany)}
														checked={isCompany}
													>
														{getTranslation('booking', 'company', offer.language)}
													</Checkbox>
												</Form.Item>
											</div>
											{ isCompany &&
												<div className="company-fields">
													<Row gutter={24}>
														<Col xs={24} sm={12} md={12} lg={8} xl={8}>
															<Form.Item
																label={getTranslation('booking', 'companyName', offer.language)}
																name="companyName"
																rules={[
																	{
																		required: true,
																		message: getTranslation('booking', 'requiredField', offer.language),
																	},
																]}
															>
																<Input
																	size="large"
																	type="text"
																/>
															</Form.Item>
														</Col>
														<Col xs={24} sm={12} md={12} lg={8} xl={8}>
															<Form.Item
																label={getTranslation('booking', 'vat', offer.language)}
																name="companyVat"
																rules={[
																	{
																		required: true,
																		message: getTranslation('booking', 'requiredField', offer.language),
																	},
																]}
															>
																<Input
																	size="large"
																	type="text"
																/>
															</Form.Item>
														</Col>
														<Col xs={24} sm={12} md={12} lg={8} xl={8}>
															<Form.Item
																label={getTranslation('booking', 'recipientCode', offer.language)}
																name="companyRecipientCode"
															>
																<Input
																	size="large"
																	type="text"
																/>
															</Form.Item>
														</Col>
														<Col xs={24} sm={12} md={12} lg={8} xl={8}>
															<Form.Item
																label={getTranslation('booking', 'street', offer.language)}
																name="companyStreet"
															>
																<Input
																	size="large"
																	type="text"
																/>
															</Form.Item>
														</Col>
														<Col xs={24} sm={12} md={12} lg={8} xl={8}>
															<Form.Item
																label={getTranslation('booking', 'place', offer.language)}
																name="companyPlace"
															>
																<Input
																	size="large"
																	type="text"
																/>
															</Form.Item>
														</Col>
														<Col xs={24} sm={12} md={12} lg={8} xl={8}>
															<Form.Item
																label={getTranslation('booking', 'cap', offer.language)}
																name="companyCap"
															>
																<Input
																	size="large"
																	type="text"
																/>
															</Form.Item>
														</Col>
														<Col xs={24} sm={12} md={12} lg={8} xl={8}>
															<Form.Item
																label={getTranslation('booking', 'country', offer.language)}
																name="companyCountry"
															>
																<Select
																	showSearch
																	optionFilterProp="label"
																	size="large"
																	options={offer.language === 'de' ? countriesDE : offer.language === 'it' ? countriesIT : countriesEN}
																/>
															</Form.Item>
														</Col>
													</Row>
												</div>
											}
										</div>
									</div>
									<div className="privacy">
										<Form.Item
											name="privacy"
											valuePropName="checked"
											rules={[
												{
													validator: (_, value) =>
														value ? Promise.resolve() : Promise.reject(new Error(getTranslation('booking', 'requiredField', offer.language))),
												},
											]}
										>
											<Checkbox>
												<span dangerouslySetInnerHTML={{ __html: getTranslation('booking', 'privacy', offer.language, {
													url: RoutePaths.offer.privacy.replace(':offerUid', offer.uid),
												}) }} />
												
											</Checkbox>
										</Form.Item>
									</div>
									<div className="submit">
										<Button
											text={getTranslation('booking', 'toSummary', offer.language)}
											onClick={() => form.submit()}
										/>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
	);
};

export default BookingT1;