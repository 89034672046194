import React, { useEffect, useState } from 'react';
import { apiDomain, findTranslationOrFallback } from 'utils/constants';
import Headline from 'pages/offer/components/core/headline';
import Text from 'pages/offer/components/core/text';
import './index.scss';

function ModuleTeaserT1 ({ content, offer }) {
	const [headline, setHeadline] = useState([]);
	const [text, setText] = useState([]);
	const [image, setImage] = useState([]);
	
	useEffect(() => {
		let lang = offer.language.toUpperCase();
		let headlineKey = 'teaserT1_headline';
		let textKey = 'teaserT1_text';
		
		setHeadline(findTranslationOrFallback(content.translations, headlineKey, lang));
		setText(findTranslationOrFallback(content.translations, textKey, lang));
		setImage(content.contentImages?.filter(image => image.moduleId === 'teasert1'));
	}, [content, offer]);
	
	return (
		<>
			{(headline || text || image) &&
				<div className="teaser-t1">
					<div className="centercontent1047 df js">
						{ image &&
							image.map((img, i) => {
								return (
									<div className="image" key={i}>
										<img src={apiDomain() + img.path} key={img.name} alt={img.name} />
									</div>
								);
							})
						}
						{ (headline || text) &&
							<div className="content">
								{ headline &&
									<Headline
										class={ 'headline t1' }
										text={ headline }
									/>
								}
								{ text &&
									<Text text={ text } />
								}
							</div>
						}
					</div>
				</div>
			}
		</>
	);
};

export default ModuleTeaserT1;