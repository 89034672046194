import React, { useState } from 'react';
import { Button, Col, Divider, Drawer, Form, Input, Row, Space, Upload, notification } from 'antd';
import { useAuth } from 'hooks/useAuth';
import { PlusOutlined } from '@ant-design/icons';
import { apiDomain, isValidUploadFormat, languages } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import TextEditor from 'components/forms/text-editor';
import LanguageSwitcher from 'components/core/language-switcher';
import LanguageInput from 'components/forms/language-input';
import axios from 'axios';
import './index.scss';

function RoomCreate({ onClose, open }) {
	const { accessToken, user } = useAuth();
	const [form] = Form.useForm();
	const [fileList, setFileList] = useState([]);
	const [sketchesList, setSketchesList] = useState([]);
	const [removeList, setRemoveList] = useState([]);
	const [removeListSketches, setRemoveListSketches] = useState([]);
	const [api, contextHolder] = notification.useNotification();
	const [language, setLanguage] = useState(languages.find(lang => lang.default).key);
	const { t } = useTranslation();
	
	const onSubmit = async (values) => {
		try {
			const data = new FormData();
			fileList.forEach((file, i) => {
				if (!file.existingImage) {
					data.append('images', file, file.name);
				}
			});
			sketchesList.forEach((file, i) => {
				if (!file.existingImage) {
					data.append('sketches', file, file.name);
				}
			});
			
			let fileToDeleteArray = [];
			removeList.forEach((file, i) => {
				fileToDeleteArray.push(file.fileName);
			});
			removeListSketches.forEach((file, i) => {
				fileToDeleteArray.push(file.fileName);
			});
			
			if (fileToDeleteArray.length > 0) {
				data.append('filesToDelete', fileToDeleteArray.join('|'));
			}
			
			Object.keys(values).forEach(function(key,index) {
				data.append(key, values[key]);
			});
			
			data.append('hotelId', user.hotelId);
			
			await axios.post(apiDomain() + 'rooms', data, {
				headers: {
					'x-access-token': accessToken,
				},
			});
			api['success']({
				message: t('general.saveSuccess'),
				description: t('general.saveSuccessText'),
				placement: 'bottomRight',
				duration: 3,
			});
			setRemoveList([]);
			setRemoveListSketches([]);
			onClose();
			form.resetFields();
		} catch (error) {
			console.error(error.message);
			api['error']({
				message: t('general.saveError'),
				description: t('general.saveErrorText'),
				placement: 'bottomRight',
				duration: 0,
			});
		}
	};
	
	const beforeUpload = (file) => {
		return false;
	};
	
	const onChangeHandle = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (tempFiles.length < 6) {
				if (file.hasOwnProperty('existingImage')) {
					tempFiles.push(file);
				} else {
					if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t)) {
						tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
					}
				}
			}
		});
		setFileList(tempFiles);
	};
	
	const onRemove = (file) => {
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
		setRemoveList([...removeList, file]);
	};
	
	const beforeUploadSketch = (file) => {
		return false;
	};
	
	const onChangeHandleSketch = (info) => {
		let tempFiles = [];
		info.fileList.forEach(file => {
			if (tempFiles.length < 1) {
				if (file.hasOwnProperty('existingImage')) {
					tempFiles.push(file);
				} else {
					if (isValidUploadFormat(file.hasOwnProperty('originFileObj') ? file.originFileObj : file, t)) {
						tempFiles.push(file.hasOwnProperty('originFileObj') ? file.originFileObj : file);
					}
				}
			}
		});
		setSketchesList(tempFiles);
	};
	
	const onRemoveSketch = (file) => {
		const index = sketchesList.indexOf(file);
		const newFileList = sketchesList.slice();
		newFileList.splice(index, 1);
		setSketchesList(newFileList);
		setRemoveListSketches([...removeListSketches, file]);
	};
	
	const onError = (error) => {
		if (error.errorFields.find(error => error.name[0] === 'nameDE') ||  error.errorFields.find(error => error.name[0] === 'nameIT') || error.errorFields.find(error => error.name[0] === 'nameEN')) {
			api['error']({
				message: t('rooms.createError'),
				description:
					t('rooms.createErrorText'),
				placement: 'bottomRight',
			});
		}
	};
	
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				{t('rooms.upload')}
			</div>
		</div>
	);
	
	const initialValues = () => {
		let values = {
			size: '',
			code: '',
		};
		languages.forEach(lang => {
			values['name' + lang.key] = '';
			values['headline' + lang.key] = '';
			values['subheadline' + lang.key] = '';
		});
		return values;
	};
	
	return (
		<div className="room-create">
			{contextHolder}
			<Drawer
				title={t('rooms.add')}
				width={720}
				onClose={onClose}
				open={open}
				className="room-create-drawer"
				extra={
					<Space>
						<Button type="primary" onClick={() => form.submit()}>{t('rooms.add')}</Button>
					</Space>
				}
			>
				<Form
					layout="vertical"
					onFinish={onSubmit}
					requiredMark={false}
					form={form}
					onFinishFailed={onError}
					initialValues={initialValues()}
				>
					<LanguageSwitcher
						currentLanguage={language}
						onSwitch={(lang) => setLanguage(lang)}
					/>
					<LanguageInput
						name="name"
						currentLanguage={language}
						size="large"
						rules={[
							{
								required: true,
								message: t('rooms.nameError'),
							},
						]}
					/>
					<Divider/>
					<Row gutter={24}>
						<Col span={24}>
							<div className="label">{t('rooms.images')}</div>
							<Upload
								listType="picture-card"
								fileList={fileList}
								beforeUpload={beforeUpload}
								onRemove={onRemove}
								onChange={onChangeHandle}
								multiple
							>
								{fileList.length >= 6 ? null : uploadButton}
							</Upload>
						</Col>
						<Divider/>
						<Col span={24}>
							<div className="label">{t('rooms.sketch')}</div>
							<Upload
								listType="picture-card"
								fileList={sketchesList}
								beforeUpload={beforeUploadSketch}
								onRemove={onRemoveSketch}
								onChange={onChangeHandleSketch}
								multiple
							>
								{sketchesList.length >= 1 ? null : uploadButton}
							</Upload>
						</Col>
						<Divider/>
						<Col span={24}>
							<LanguageInput
								label={t('rooms.headline')}
								name="headline"
								currentLanguage={language}
								size="large"
							/>
						</Col>
						<Col span={24}>
							<LanguageInput
								label={t('rooms.subheadline')}
								name="subheadline"
								currentLanguage={language}
								size="large"
							/>
						</Col>
						<Col span={24}>
							<div className="label">{t('rooms.description')}</div>
							<TextEditor
								formItemName="description"
								form={form}
								language={language}
							/>
						</Col>
						<Divider/>
						<Col span={12}>
							<Form.Item label={t('rooms.size')} name="size">
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={t('rooms.code')}
								name="code"
								rules={[
									{
										required: true,
										message: t('general.form.requiredField'),
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Drawer>
		</div>
	);
}

export default RoomCreate;