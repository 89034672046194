import './index.scss';

function Button(props) {
	return (
		<a className={ props.class } href={ props.href } onClick={ props.onClick } data-id={ props.dataId }>
			{ props.text }
		</a>
	);
}

Button.defaultProps = {
	class: 'button1',
	dataId: '',
	text: '',
	href: null,
	onClick: null,
};

export default Button;
