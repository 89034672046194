import { Tabs, Divider } from 'antd';
import Title from 'antd/es/typography/Title';
import SettingsStylesFonts from './fonts';
import SettingsStylesBackgrounds from './backgrounds';
import { useTranslation } from 'react-i18next';

function SettingsStyles () {
	const { t } = useTranslation();
	
	const tabs = [
		{
			key: '1',
			label: t('styles.tabs.fonts'),
			children: <SettingsStylesFonts />,
		},
		{
			key: '2',
			label: t('styles.tabs.backgrounds'),
			children: <SettingsStylesBackgrounds />,
		},
	];
	
	return (
		<div className="settings-styles">
			<Title level={3}>
				{t('menu.styles')}
			</Title>
			<Divider/>
			<Tabs defaultActiveKey="1" items={tabs} />
		</div>
	);
}

export default SettingsStyles;