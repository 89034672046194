import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect } from 'react';
import { findTranslationOrFallback, languages } from 'utils/constants';
import LanguageInput from '../language-input';
import 'tinymce/plugins/lists';
import './index.scss';

function TextEditor({ initialValue, onChange, formItemName, form, language, translations, disabled }) {
	useEffect(() => {
		if (formItemName) {
			if (translations) {
				languages.forEach(lang => {
					form.setFieldValue((formItemName + lang.key), findTranslationOrFallback(translations, formItemName, lang.key));
				});
			} else {
				languages.forEach(lang => {
					form.setFieldValue((formItemName + lang.key), '');
				});
			}
		}
	}, [formItemName, form, translations]);
	
	return (
		<div className="text-editor">
			{ formItemName ? (
				<div className="multi-lang-text-editor">
					{ languages.map((lang) => (
						<div className={language === lang.key ? 'active' : ''} key={lang.key}>
							<Editor
								initialValue={findTranslationOrFallback(translations, formItemName, lang.key)}
								disabled={disabled}
								onChange={(e) => {
									if (onChange) {
										onChange(e.target.getContent());
									}
									if (formItemName) {
										form.setFieldValue(formItemName + lang.key, e.target.getContent());
									}
								}}
								init={{
									height: 250,
									menubar: false,
									plugins: 'lists link',
									toolbar: 'bold italic strikethrough underline | alignleft aligncenter alignright | bullist numlist | link',
								}}
							/>
						</div>
					))}
					<LanguageInput
						name={formItemName}
						currentLanguage={language}
						type="hidden"
					/>
				</div>
			) : (
				<Editor
					initialValue={initialValue}
					onChange={(e) => {
						if (onChange) {
							onChange(e.target.getContent());
						}
					}}
					init={{
						height: 250,
						menubar: false,
						plugins: 'lists',
						toolbar: 'bold italic strikethrough underline | alignleft aligncenter alignright | bullist numlist',
					}}
				/>
			)}
		</div>
	);
}

export default TextEditor;
