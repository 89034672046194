import React, { useState, useEffect } from 'react';
import { apiDomain, findTranslationOrFallback } from 'utils/constants';
import Headline from 'pages/offer/components/core/headline';
import '@splidejs/react-splide/css';
import './index.scss';

function ModuleHeaderT1 ({ content, offer }) {
	const [images, setImages] = useState([]);
	const [headline, setHeadline] = useState([]);
	
	useEffect(() => {
		let lang = offer.language.toUpperCase();
		let headlineKey = 'footergalleryT1_headline';
		
		if (content.contentImages) {
			setImages(content.contentImages?.filter(image => image.moduleId === 'footergalleryt1').sort((a, b) => {
				if (a.order === null) {
					return 1;
				}
				if (b.order === null) {
					return -1;
				}
				if (a.order === b.order) {
					return 0;
				}
				return a.order < b.order ? -1 : 1;
			}));
		}
		
		setHeadline(findTranslationOrFallback(content.translations, headlineKey, lang));
	}, [content, offer]);
	
	return (
		<>
			{ images.length > 0 && headline &&
				<div className="module-footer-gallery-t1">
					<div className="centercontent1470">
						<div className="items df jc fw">
							{ images &&
								images.map((img, i) => {
									return (
										<div className="item" key={i}>
											<div className="image">
												<img src={apiDomain() + img.path} key={img.name} alt={img.name} />
											</div>
										</div>
									);
								})
							}
							{ headline &&
								<div className="item headline">
									<div className="inner df jc">
										<Headline
											class={ 'headline t3' }
											text={ headline }
										/>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			}
		</>
	);
};

export default ModuleHeaderT1;