import { createInstance } from 'services/api/client';
import { apiDomain } from 'utils/constants';

const ConfigWrapper = ({ children }) => {
	createInstance({
		baseURL: apiDomain(),
		withCredentials: true,
	});
	return children;
};

export default ConfigWrapper;
