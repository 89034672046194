import { languages } from 'utils/constants';
import LanguageFlag from '../language-flag';
import './index.scss';

function LanguageSwitcher ({ onSwitch, currentLanguage }) {
	return (
		<div className="language-switcher">
			{ languages.map((lang) => (
				<div className={'language' + (currentLanguage === lang.key ? ' active' : '')} onClick={() => onSwitch(lang.key)} key={lang.key}>
					<LanguageFlag language={lang.key.toLowerCase()} />
				</div>
			))}
		</div>
	);
}

export default LanguageSwitcher;
