export const getTranslation = (prefix, key, lang, placeholders = null) => {
	const translations = {
		general : {
			toOffer: {
				de: 'direkt zum Angebot',
				it: 'All\'offerta',
				en: 'To the offer',
			},
			bookingConfirmed: {
				de: 'Die Buchung ist abgeschlossen!',
				it: 'La prenotazione è stata completata!',
				en: 'The booking has been completed!',
			},
			bookingRequest: {
				de: 'Vielen Dank. Die Buchungsanfrage wird bearbeitet.',
				it: 'Grazie. La richiesta di prenotazione è in fase di elaborazione!',
				en: 'Thank you. Your booking request is being processed!',
			},
			credits: {
				de: 'Impressum',
				it: 'Credits',
				en: 'Credits',
			},
			privacy: {
				de: 'Datenschutz',
				it: 'Privacy',
				en: 'Privacy',
			},
			agbs: {
				de: 'AGB',
				it: 'Condizioni generali di contratto',
				en: 'Terms and conditions',
			},
		},
		header: {
			accommodation: {
				de: '{{overnight-stays}} Übernachtung vom {{from}} bis {{to}}',
				it: '{{overnight-stays}} pernottamento dal {{from}} al {{to}}',
				en: '{{overnight-stays}} overnight stay from {{from}} to {{to}}',
			},
			accommodations: {
				de: '{{overnight-stays}} Übernachtungen vom {{from}} bis {{to}}',
				it: '{{overnight-stays}} pernottamenti dal {{from}} al {{to}}',
				en: '{{overnight-stays}} overnights stays from {{from}} to {{to}}',
			},
			titleM: {
				de: 'Lieber Herr {{name}},',
				it: 'Gentile signore {{name}},',
				en: 'Dear Mr. {{name}},',
			},
			titleF: {
				de: 'Liebe Frau {{name}},',
				it: 'Gentile signora {{name}},',
				en: 'Dear Ms. / Mrs. {{name}},',
			},
		},
		variants: {
			variant: {
				de: 'Variante {{index}}',
				it: 'Variante {{index}}',
				en: 'Variant {{index}}',
			},
			adults: {
				de: 'Erwachsene',
				it: 'adulti',
				en: 'adults',
			},
			adultsSingle: {
				de: 'Erwachsener',
				it: 'adulti',
				en: 'adult',
			},
			children: {
				de: 'Kinder',
				it: 'bambini',
				en: 'children',
			},
			childrenSingle: {
				de: 'Kind',
				it: 'bambino',
				en: 'child',
			},
			roomHeadlineWithSize: {
				de: '{{name}} | {{size}}m\u00B2',
				it: '{{name}} | {{size}}m\u00B2',
				en: '{{name}} | {{size}}m\u00B2',
			},
			toOffer: {
				de: 'direkt zum Angebot',
				it: 'all\'offerta',
				en: 'to the offer',
			},
			toBooking: {
				de: 'Jetzt buchen',
				it: 'Prenota ora',
				en: 'Book now',
			},
			roomSketch: {
				de: 'Zimmerskizze',
				it: 'Piantina della camera',
				en: 'Room sketch',
			},
			validityPeriod: {
				de: 'Unverbindliches Angebot gültig nach Verfügbarkeit im angeführten Zeitraum.',
				it: 'Offerta non vincolante valida in base alla disponibilità nel periodo indicato.',
				en: 'Non-binding offer valid based on availability during the specified period.',
			},
		},
		teasert2: {
			details: {
				de: 'Details',
				it: 'Dettagli',
				en: 'Details',
			},
		},
		roomt1: {
			yourReservation: {
				de: 'getätigte Buchung',
				it: 'offerta scelta',
				en: 'your booking',
			},
			followingBookingWasMade: {
				de: 'Folgende Buchung wurde vorgenommen',
				it: 'Ecco la prenotazione effettuata',
				en: 'The following booking has been made',
			},
			personalInfoYouProvide: {
				de: 'Die von Ihnen übermittelten persönlichen Angaben',
				it: 'I dati personali da lei forniti',
				en: 'The personal information you provided',
			},
		},
		contactpersont1: {
			writeawhatsapp: {
				de: 'Schreib mir eine Whatsapp',
				it: 'Inviami un messaggio su Whatsapp',
				en: 'Send me a Whatsapp message',
			},
		},
		booking: {
			anzahlungText: {
				de: 'Bitte folgenden Betrag anzahlen:',
				it: 'Si prega cortesemente di versare la seguente cifra:',
				en: 'Please pay the following amount:',
			},
			price: {
				de: 'Preis',
				it: 'Prezzo',
				en: 'Price',
			},
			personalData: {
				de: 'Persönliche Daten',
				it: 'Dati personali',
				en: 'Personal details',
			},
			salutation: {
				de: 'Anrede',
				it: 'Titolo',
				en: 'Title',
			},
			firstName: {
				de: 'Name',
				it: 'Nome',
				en: 'First name',
			},
			lastName: {
				de: 'Nachname',
				it: 'Cognome',
				en: 'Last name',
			},
			email: {
				de: 'E-Mail Adresse',
				it: 'Indirizzo email',
				en: 'Email address',
			},
			phone: {
				de: 'Telefonnummer',
				it: 'Numero di telefono',
				en: 'Phone number',
			},
			street: {
				de: 'Straße',
				it: 'Indirizzo',
				en: 'Street',
			},
			cap: {
				de: 'PLZ',
				it: 'CAP',
				en: 'Postal code',
			},
			place: {
				de: 'Ort',
				it: 'Città',
				en: 'City',
			},
			country: {
				de: 'Land',
				it: 'Paese',
				en: 'Country',
			},
			requiredField: {
				de: 'Pflichtfeld',
				it: 'Campo obbligatorio',
				en: 'Required field',
			},
			toSummary: {
				de: 'Jetzt Buchungsanfrage senden',
				it: 'Invia richiesta di prenotazione ora',
				en: 'Send booking request now',
			},
			privacy: {
				de: 'Ich akzeptiere die Reservierungsbedingungen, die <a href="{{url}}" target="_blank">Datenschutzbestimmungen</a> und allgemeinen Geschäftsbedingungen.',
				it: 'Accetto i termini di prenotazione, la <a href="{{url}}" target="_blank">politica sulla privacy</a> e i termini e condizioni generali.',
				en: 'I accept the reservation terms, <a href="{{url}}" target="_blank">privacy policy</a>, and terms and conditions.',
			},
			company: {
				de: 'Ich bin ein Geschäftskunde und benötige eine Rechnung',
				it: 'Sono un cliente business e ho bisogno di una fattura',
				en: 'I am a business customer and require an invoice.',
			},
			companyName: {
				de: 'Name des Unternehmens',
				it: 'Nome dell\'azienda',
				en: 'Company name',
			},
			vat: {
				de: 'Mehrwertsteuernummer',
				it: 'Partita IVA',
				en: 'VAT number',
			},
			recipientCode: {
				de: 'Empfängerkodex',
				it: 'Codice destinatario',
				en: 'Recipient code',
			},
		},
		prices: {
			title: {
				de: 'Beschreibung',
				it: 'Descrizione',
				en: 'Description',
			},
			quantity: {
				de: 'Menge',
				it: 'Quantità',
				en: 'Quantity',
			},
			singlePrice: {
				de: 'Einzelpreis',
				it: 'Prezzo unitario',
				en: 'Unit price',
			},
			total: {
				de: 'Summe',
				it: 'Totale',
				en: 'Total',
			},
			totalPrice: {
				de: 'Gesamtpreis',
				it: 'Prezzo totale',
				en: 'Total price',
			},
		},
		privacyt1: {
			headline: {
				de: 'Datenschutz',
				it: 'Privacy',
				en: 'Privacy',
			},
		},
		creditst1: {
			headline: {
				de: 'Impressum',
				it: 'Credits',
				en: 'Credits',
			},
		},
		agbst1: {
			headline: {
				de: 'AGB',
				it: 'Condizioni generali di contratto',
				en: 'Terms and conditions',
			},
		},
	};
	
	var trans = translations[prefix][key][lang] ? translations[prefix][key][lang] : '';
	
	if (placeholders) {
		for (const [key, value] of Object.entries(placeholders)) {
			trans = trans.replace('{{' + key + '}}', value);
		}
	}
	
	return trans;
};