import axios from 'axios';

const defaultOptions = {
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true,
};

let initialized = false;

export let apiClient = null;

export const createInstance = (options) => {
	if (initialized) {
		return;
	}
	initialized = true;
	apiClient = axios.create({
		...defaultOptions,
		...options,
	});
};

export default apiClient;