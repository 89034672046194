import React, { useEffect, useState } from 'react';
import { apiDomain, findTranslationOrFallback } from 'utils/constants';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import { getTranslation } from 'pages/offer/translations/translations';
import Intro from 'pages/offer/components/modules/intro';
import Link from 'pages/offer/components/core/link';
import Headline from 'pages/offer/components/core/headline';
import Text from 'pages/offer/components/core/text';
import Price from 'pages/offer/components/core/price';
import Variant from 'pages/offer/components/core/variant';
import { useTranslation } from 'react-i18next';
import { getVariantDescription, getVariantTotalPrice } from '../../helpers/helpers';
import moment from 'moment';
import '@splidejs/react-splide/css';
import './index.scss';

function ModuleVariantsT1 ({ content, offer }) {
	const [headline, setHeadline] = useState([]);
	const [subHeadline, setSubHeadline] = useState([]);
	const [items, setItems] = useState([]);
	const { t } = useTranslation();
	
	const openVariantsT2Tab = (e) => {
		const tab = document.querySelectorAll('.module-variants-t2 .tabs .tab[data-id="' + e.target.getAttribute('data-id') + '"]');
		const item = document.querySelectorAll('.module-variants-t2 .elements .element[data-id="' + e.target.getAttribute('data-id') + '"]');
		
		if (tab) {
			document.getElementById('module-variants-t2').scrollIntoView({ block: 'start',  behavior: 'smooth' });
			
			tab.forEach((e) => {
				e.classList.add('active');
				[...e.parentNode.children].filter((child) => child !== e).forEach((a) => {
					a.classList.remove('active');
				});
			});
			
			item.forEach((e) => {
				e.classList.add('active');
				[...e.parentNode.children].filter((child) => child !== e).forEach((a) => {
					a.classList.remove('active');
				});
			});
		}
	};
	
	useEffect(() => {
		let lang = offer.language.toUpperCase();
		
		setHeadline(findTranslationOrFallback(content.translations, 'variantsT1_headline', lang));
		setSubHeadline(findTranslationOrFallback(content.translations, 'variantsT1_subheadline', lang));
		
		let variants = [];
		if (offer['offer_variants']) {
			variants = offer.offer_variants.map((element, i) => {
				let image = '';
				let headline = '';
				
				if (element.room?.images.length > 0) {
					image = (element.room.images[0]['path'] ?  (apiDomain() + element.room.images[0]['path']) : element.room.images[0]['externalPath']);
				}
				
				let name = findTranslationOrFallback(element.room?.roomTranslations, 'name', lang);
				let size = element.room?.size;
				
				let date = moment(element.arrivalDate).format('DD.MM.YYYY') + ' - ' + moment(element.departureDate).format('DD.MM.YYYY');
				
				if (name && size) {
					headline = getTranslation('variants', 'roomHeadlineWithSize', offer.language, {
						'name' : name,
						'size' : size,
					});
				} else {
					headline = name;
				}
				return {
					index: i + 1,
					id: element.id,
					headline: headline,
					description: getVariantDescription(element, offer.language, t),
					price: getVariantTotalPrice(element.offer_variants_prices),
					roomImage: image,
					date,
				};
			});
		}
		setItems(variants);
	}, [content, offer, t]);
	
	if (offer.offer_variants.length < 2) {
		return;
	}
	
	return (
		<div className="module-variants-t1" id="module-variants-t1">
			<div className="centercontentlargest">
				<div className="container bgteaser">
				
					{ (subHeadline || headline) &&
						<Intro
							preheadline={ subHeadline }
							headline={ headline }
						/>
					}
					
					<div className="variants">
						<div className="centercontent1470">
							<div className="gallery">
								<Splide
									hasTrack={ false }
									options={{
										perPage: 3,
										drag: 'free',
										snap: true,
										pagination: false,
										arrows: false,
										gap: '40px',
										breakpoints: {
											650: {
												perPage: 1,
												gap: '14px',
											},
										},
									}}
								>
									<SplideTrack>
										{ items && 
											items.map((elem, i) => {
												return (
													<SplideSlide key={ i }>
														<div className="element">
															<Variant text={ getTranslation('variants', 'variant', offer.language, { 'index' : elem.index }) } />
															<div className="image">
																<img src={elem.roomImage} alt="" />
															</div>
															<div className="content">
																<Headline
																	class={ 'headline t3' }
																	text={ elem.headline }
																/>
																<Text class="text date" text={ elem.date } />
																<Text text={ elem.description } />
																<Price text={ elem.price } />
																<Link
																	dataId={ elem.id }
																	onClick={ openVariantsT2Tab }
																	text={ getTranslation('variants', 'toOffer', offer.language) } />
															</div>
														</div>
													</SplideSlide>
												);
											})
										}
									</SplideTrack>
								</Splide>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModuleVariantsT1;